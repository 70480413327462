import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ typography, palette }) => ({
  root: {},
  avatar: {
    width: 43,
    height: 43,
    background: palette.white.off,
    border: '0.6px solid #DDDBE0',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  email: {
    fontWeight: typography.fontWeightMedium
  }
}))
