import React from 'react'
import {
  RadioGroup,
  FormControlLabel,
  Grid,
  getContrastRatio,
  lighten,
  darken,
  rgbToHex
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SquareRadio from './square-radio'
import { HelpOutline } from '@material-ui/icons'
import { Input as TextInput } from '@emerald-works-nova/components'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3, 0)
  },

  colorDiv: {
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(1)
  },
  showColorDiv: {
    gap: spacing(1),
    alignItems: ({ errors: { mainColor } }) =>
      mainColor ? 'flex-start' : 'center'
  },
  infoLabel: {
    paddingTop: spacing(2),
    paddingBottom: spacing(1),
    gap: spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: '#CCC9CF'
    }
  }
}))

const defaultColors = ['#0070BD', '#25A259', '#D87118', '#E64533']
const minContrast = 3.0

const makeAccessible = color => {
  let newColor = color
  while (getContrastRatio(lighten(newColor, 0.95), newColor) < minContrast) {
    newColor = darken(newColor, 0.01)
  }
  return rgbToHex(newColor)
}

const InputColor = props => {
  const {
    control,
    errors,
    setValue,
    name,
    watch,
    defaultValue = '#0070BD',
    trigger
  } = props

  const classes = useStyles(props)

  const logo = watch('logo')
  const mainColor = watch(name)
  const colors = watch('suggestedColors')

  React.useEffect(() => {
    const getColorSuggestions = () => {
      const img = new Image()
      img.onload = () => {
        try {
          // create canvas for pixel analysis
          const canvas = document.createElement('canvas')
          canvas.width = img?.width
          canvas.height = img?.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          const pixels = ctx.getImageData(0, 0, img.width, img.height).data

          const colors = {}

          for (let i = 0; i < pixels.length; i += 4) {
            const getRGBA = index => [
              pixels[index],
              pixels[index + 1],
              pixels[index + 2],
              pixels[index + 3]
            ]

            const curr = getRGBA(i)
            const prev = getRGBA(i - 4)
            const [r, g, b, a] = curr

            const isNearNeighbour = curr
              .map((v, i) => Math.abs(prev[i] - v))
              .every(v => v > 0 && v < 20)

            // ignore transparent,greyscale and similar pixels
            if (
              a === 255 &&
              !(r === g && r === b && g === b) &&
              !isNearNeighbour
            ) {
              const hex = rgbToHex(`rgb(${r},${g},${b})`)

              colors[hex] ? (colors[hex] = colors[hex] + 1) : (colors[hex] = 1)
            }
          }

          const hexValues = Object.keys(colors)
          // only most frequently occuring colours
          const maxFreq = Math.max(...Object.values(colors))
          const filtered =
            hexValues.length > 4
              ? hexValues.filter(c => colors[c] > Math.round(0.66 * maxFreq))
              : hexValues

          const suggested = filtered
            // darken colours that do not pass contrast test
            .map(c =>
              getContrastRatio(lighten(c, 0.95), c) < minContrast
                ? makeAccessible(c)
                : c
            )
            // fill with defaults if < 4 colours
            .concat(defaultColors)
            .slice(0, 4)

          setValue('suggestedColors', suggested)
        } catch (error) {
          setValue('suggestedColors', defaultColors)
        }
      }

      img.crossOrigin = 'anonymous'
      img.src = logo.imgUrl
    }

    if (logo?.imgUrl) {
      getColorSuggestions()
    } else {
      setValue(name, defaultValue)
      setValue('suggestedColors', defaultColors)
    }
  }, [logo, setValue, name, defaultValue])

  React.useEffect(() => {
    if (colors && !mainColor) setValue(name, colors[0])
  }, [mainColor, colors, setValue, name])

  const handleChange = evt => {
    setValue(name, evt.target.value)
    trigger(name)
  }

  const renderBoxes = colors?.map((color, i) => (
    <FormControlLabel
      style={{ margin: 0 }}
      value={color}
      key={`.$suggestion-${color}-${i}`}
      control={
        <SquareRadio
          className={classes.radio}
          color={color}
          name='colorSuggestion'
          actualColor={mainColor}
          data-test={`branding-color-button-${i}`}
        />
      }
    />
  ))

  return (
    <>
      <Grid container className={classes.showColorDiv}>
        <Grid item>
          <SquareRadio
            className={classes.radio}
            disabled
            color={mainColor || defaultValue}
          />
        </Grid>
        <Grid item>
          <TextInput
            rules={{
              required: {
                value: 'true',
                message: 'Colour is a required input'
              },
              pattern: {
                value: /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/i,
                message: 'Please write a valid HEX Colour'
              },
              validate: {
                contrast: v => {
                  const cr = getContrastRatio(lighten(v, 0.95), v)
                  return cr > minContrast
                    ? true
                    : 'Try a darker colour, or one of the suggestions.'
                }
              }
            }}
            control={control}
            render={{ value: mainColor }}
            value={mainColor}
            valid={!errors?.mainColor}
            maxLength={7}
            name={name}
            label='Hex Colour'
            disabled={!colors}
            error={errors?.mainColor?.message}
            showErrors
            data-test='branding-color-input'
          />
        </Grid>
      </Grid>

      {colors && (
        <>
          <span className={classes.infoLabel}>
            Colors Suggestions <HelpOutline />
          </span>

          <RadioGroup
            defaultValue=''
            name='colorSuggestion'
            onChange={handleChange}
          >
            <div className={classes.colorDiv}>{renderBoxes}</div>
          </RadioGroup>
        </>
      )}
    </>
  )
}
export default InputColor
