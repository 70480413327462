import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { Outlet } from 'react-router-dom'
import { AppToolbar, FooterToolbar } from '../../components'
import { useStyles } from './style'

const PrimaryTemplate = () => {
  const classes = useStyles()
  return (
    <>
      <Container className={classes.appBarContainer} maxWidth={false}>
        <AppToolbar />
      </Container>
      <Grid
        container
        direction='column'
        wrap='nowrap'
        className={classes.container}
      >
        <Container className={classes.mainContainer}>
          <main>
            <Outlet />
          </main>
        </Container>
        <Grid item className={classes.footerContainer}>
          <footer>
            <FooterToolbar />
          </footer>
        </Grid>
      </Grid>
    </>
  )
}

export default PrimaryTemplate
