import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './style'
import React from 'react'
import ToggleUser from '../toggle-user'

const UserItem = ({ user, index, onClick, userPoolId, onKeyPress }) => {
  const classes = useStyles({ enabled: user.enabled })

  return (
    <Grid
      role='row button'
      data-test={`user-list-item-${index}`}
      container
      tabIndex={0}
      direction='row'
      alignItems='center'
      classes={{ root: classes.root }}
      onClick={() => onClick(user)}
      onKeyPress={(e) => onKeyPress(e, user)}
    >
      <Grid item md={10} className={classes.userColumn}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='body2' data-test={`user-name-${index}`}>
              {user.givenName} {user.familyName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={2} container alignItems='center' data-test='status'>
        <ToggleUser
          user={user}
          userPoolId={userPoolId}
        />
      </Grid>
    </Grid>
  )
}

export default UserItem
