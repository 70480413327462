import React from 'react'
import { useStyles } from './style'

const FormSectionTitle = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

export default FormSectionTitle
