import * as actions from './slice'

const getCatalogContent = {
  eventName: 'catalogSearch',
  onSuccess: {
    action: actions.getCatalogContent,
    redux: true
  }
}

const fetchCatalogContent = {
  eventName: 'catalogSearch',
  onSuccess: {
    action: actions.fetchCatalogContent,
    redux: true
  }
}

export {
  getCatalogContent,
  fetchCatalogContent
}
