import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Button, CircularProgress, Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { ArrowUpward } from '@material-ui/icons'
import { debounce } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import ContentSlice from '../../reducers/content'
import { routes } from '../../routes'
import CatalogItem from './catalog-item'
import Filters from './filters/index'
import { useStyles } from './style'

let firstLoad = true

const pageSize = 10
const initialFilters = { search: '', type: [], migrationStatus: '', reviewStatus: '', dateStart: null, dateEnd: null }

const Content = () => {
  const classes = useStyles()
  const { spacing } = useTheme()
  const [filters, setFilters] = React.useState(initialFilters)
  const navigate = useNavigate()
  const queryMatchLg = useMediaQuery('(min-width:1150px)')
  const contentList = useSelector(ContentSlice.selectors.selectContentList)
  const contentTotal = useSelector(ContentSlice.selectors.selectTotal)

  const hasMoreContent = React.useMemo(() => {
    return contentTotal !== contentList.length
  }, [contentList, contentTotal])

  const [getContentList, fetchMoreContent] = useEvent([
    ContentSlice.eventBus.getContentList(() => { firstLoad = false }),
    ContentSlice.eventBus.fetchMoreContent
  ])

  useEventsOnViewLoad(() => {
    getContentList.trigger({ size: pageSize, filters })
  }, [getContentList])

  const onFetchMoreContent = React.useCallback(() => {
    fetchMoreContent.trigger({
      from: contentList.length,
      size: pageSize,
      filters
    })
  }, [fetchMoreContent, contentList, filters])

  const debouncedSearch = React.useMemo(() => debounce((filters) => {
    getContentList.trigger({
      size: pageSize,
      filters
    })
  }, 1000), [getContentList])

  const doSearch = React.useCallback((filters) => {
    debouncedSearch(filters)
  }, [debouncedSearch])

  React.useEffect(() => {
    if (firstLoad) return
    doSearch(filters)
  }, [doSearch, filters])

  // eslint-disable-next-line
  React.useEffect(() => { firstLoad = true }, [])

  const handleClearFilters = React.useCallback(() => setFilters(initialFilters), [setFilters])

  const handleContentClick = React.useCallback((content) => {
    navigate(generatePath(routes.contentEdit.path, { locationId: content.locationId }))
  }, [navigate])

  const handleContentKeyPress = React.useCallback((e, sk) => e.which === 13 && handleContentClick(sk), [handleContentClick])

  return (
    <>
      <Grid container classes={{ root: classes.header }}>
        <Grid container direction='row' alignItems='center'>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography id='catalog-list-title' tabIndex={0} component='div' variant='h5'>
            Content
              {!!contentTotal && <Typography component='span' variant='subtitle1' className={classes.contentTotal}> {`(${contentTotal})`} </Typography>}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              id='create-content-button'
              data-test='create-content-button'
              variant='contained'
              color='primary'
              name='create-content'
              aria-label='create new content'
              onClick={() => navigate(routes.contentNew.path)}
            >
            New Content
            </Button>
          </Grid>
        </Grid>
        <Grid container direction='row' alignItems='center' style={{ marginTop: spacing(3) }}>
          {!queryMatchLg && (
            <Grid container style={{ marginTop: spacing(2) }}>
              <Filters filters={filters} onChange={setFilters} onClear={handleClearFilters} clearBtnEnd />
            </Grid>
          )}
          <Grid item style={{ flexGrow: 1 }}>
            {queryMatchLg && <Filters filters={filters} onChange={setFilters} onClear={handleClearFilters} />}
          </Grid>
        </Grid>
      </Grid>

      <Paper
        id='catalog-list'
        classes={{ root: classes.tablePaper }}
        tabIndex={0}
        aria-label='Content List'
        elevation={0}
      >
        <Grid
          id='catalog-list-headers'
          container
          tabIndex={0}
          direction='row'
          alignItems='center'
          className={classes.gridHeader}
          classes={{ root: classes.tableHead }}
          aria-label='List headers, Title, Type, Last update'
        >
          <Grid item md={8}>Title</Grid>
          <Grid item md={2}>Type</Grid>
          <Grid item md={2} className={classes.dateUpdatedHeader}>
            <ArrowUpward fontSize='small' />
            <span>Last update</span>
          </Grid>
        </Grid>
        {!getContentList.isWorking && contentList.map((row, key) => (
          <CatalogItem
            key={key}
            index={key}
            article={row}
            lastOne={key === contentList.length - 1}
            onClick={handleContentClick}
            onKeyPress={handleContentKeyPress}
          />
        ))}
        {(getContentList.isWorking || fetchMoreContent.isWorking) && (
          <Grid
            id='catalog-list-loading'
            container
            direction='row'
            justifyContent='center'
            align='center'
            classes={{ root: classes.loadingAnimation }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
        {!firstLoad && !getContentList.isWorking && !fetchMoreContent.isWorking && !contentList.length && (
          <Grid
            id='catalog-list-empty'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.emptyList }}
          >
            <Typography variant='h5'> No results found! </Typography>
          </Grid>
        )}
      </Paper>
      {!getContentList.isWorking && !fetchMoreContent.isWorking && hasMoreContent && (
        <Grid
          id='catalog-list-load-more'
          container
          direction='row'
          justifyContent='center'
          align='center'
          classes={{ root: classes.loadMoreBtn }}
        >
          <Grid item xs={12}>
            <Button variant='contained' color='primary' name='' onClick={onFetchMoreContent}>
              Load More
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Content
