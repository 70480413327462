import React from 'react'

// see src/pages/content-edit/index.js for an example
const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && (<>{children}</>)}
  </div>
)

export default TabPanel
