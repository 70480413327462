import { makeStyles } from '@material-ui/core/styles'

const checked = {
  backgroundColor: '#3D84CC',
  color: '#fff'
}

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {},
  departmentList: {
    '& > h6': {
      cursor: 'pointer',
      marginBottom: spacing(2),
      height: 28,
      padding: spacing(0.5, 1),
      borderRadius: '4px 100px 100px 4px',
      '&:hover': { ...checked }
    }
  },
  checked
}))
