import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(3)
  },
  neutral: {
    color: palette.primary.dark
  },
  saveBtn: {
    marginTop: spacing(4)
  }
}))
