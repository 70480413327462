import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    height: 60,
    borderBottom: '1px solid #E0E0E0',
    '&:hover': {
      backgroundColor: palette.background.selected,
      cursor: 'pointer'
    },
    '&:focus': {
      backgroundColor: palette.background.selected
    },
    '&::before': {
      content: 'close-quote',
      position: 'absolute',
      borderRadius: spacing(0, 2, 2, 0),
      width: spacing(0.75),
      height: spacing(3.75),
      backgroundColor: ({ isActive }) => isActive ? palette.primary.light : palette.grey.dark
    },
    pointerEvents: ({ isLoading }) => isLoading ? 'none' : 'initial'
  },
  tenantColumn: {
    padding: spacing(0, 2.5, 0, 3)
  },
  status: {
    color: ({ isActive }) => isActive ? palette.success.main : palette.grey.main
  }
}))
