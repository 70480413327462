import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Switch, Typography } from '@material-ui/core'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { BannerStatic } from '..'
import { useStyles } from './style'

const ToggleUser = React.memo(({ user, userPoolId, onToggle }) => {
  const { username } = user
  const [enabled, setEnabled] = useState(user.enabled)

  useEffect(() => setEnabled(user.enabled), [user.enabled])

  const status = React.useMemo(() => enabled ? 'Active' : 'Inactive', [enabled])
  const endOfEvent = {
    onSuccess: (result) => {
      setEnabled(result.enabled)
      onToggle && onToggle(result.enabled)
      BannerStatic.show({ label: 'User status changed successfully' })
    },
    onError: (e) => {
      console.log('error toggling user status', e)
      BannerStatic.show({ label: 'Error while attempting to change the user status', isError: true })
    }
  }

  const [adminDisableUser, adminEnableUser] = useEvent([{
    eventName: 'adminDisableUser',
    ...endOfEvent
  }, {
    eventName: 'adminEnableUser',
    ...endOfEvent
  }])

  const classes = useStyles({ enabled, isLoading: adminDisableUser.isWorking || adminEnableUser.isWorking })

  const handleClick = React.useCallback(ev => {
    ev.stopPropagation()
    !ev.target.checked
      ? adminDisableUser.trigger({ userPoolId, username })
      : adminEnableUser.trigger({ userPoolId, username })
  }, [adminDisableUser, adminEnableUser, userPoolId, username])

  return (
    <Grid item container alignItems='center' data-test='user-toggle' spacing={1}>
      <Grid item md={3}>
        <Typography className={classes.status} variant='body2' data-test='user-status'>
          {status}
        </Typography>
      </Grid>
      <Grid item md container alignItems='center'>
        <Switch
          checked={enabled || false}
          onClick={(ev) => handleClick(ev)}
          color='primary'
          inputProps={{ 'aria-label': 'user active switch' }}
          disabled={adminDisableUser.isWorking || adminEnableUser.isWorking}
        />
        {(adminDisableUser.isWorking || adminEnableUser.isWorking) && <CircularProgress size={20} color='primary' />}
      </Grid>
    </Grid>
  )
})

export default ToggleUser
