import React from 'react'
import { defaults } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTheme } from '@material-ui/core'
import options from './options'

const loadingValues = new Array(10).fill(null).map((_item, idx) => 100 / (idx + 1))
const whitespace = new Array(20).fill('\xa0').join('')
const loadingLabels = new Array(10).fill(whitespace)
const loadingColor = 'rgba(33, 33, 33, 0.08)'

const BarChart = ({ data, barColor, loading }) => {
  const { palette } = useTheme()

  const labels = !data || loading ? loadingLabels : data.map(({ name }) => name)
  const values =
    !data || loading ? loadingValues : data.map(({ count }) => count)
  const color =
    !data || loading ? loadingColor : barColor ?? palette.primary.main

  const chartData = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: color,
        maxBarThickness: 20
      }
    ]
  }

  return (
    <Bar
      options={{ ...options, events: loading ? [] : defaults.events }}
      data={chartData}
    />
  )
}

export default BarChart
