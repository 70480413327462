import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const content = createSlice({
  name,
  initialState: {
    content: {
      topContent: undefined,
      topCategories: undefined,
      topSubcategories: undefined,
      topPlaylists: undefined,
      contentByType: []
    },
    usage: {
      activeUsers: undefined,
      totalViewTime: undefined,
      activeUsersOverTime: undefined,
      resourcesViewed: undefined,
      resourcesByUser: undefined
    },
    filters: {
      dateBy: '',
      date: Date()
    }
  },
  reducers: {
    setContentTab: (state, { payload }) => {
      state.content = payload
    },
    setUsageTab: (state, { payload }) => {
      state.usage = payload
    },
    setFilters: (state, { payload }) => {
      const { date } = payload
      const d = new Date(date)
      d.setMonth(d.getMonth() - 3)
      state.filters.date = date
      state.filters.startDate = d.toString()
    },
    reset: state => {
      state.content = {}
      state.usage = {}
    }
  }
})

export const { setContentTab, setUsageTab, setFilters, reset } = content.actions

export default content.reducer
