import React from 'react'
import { FixedSizeList } from 'react-window'

const LISTBOX_PADDING = 8 // px
const itemSize = 48
const height = 10 * itemSize

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const KeywordRow = (props) => {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING
    }
  })
}

const KeywordList = React.forwardRef((props, ref) => {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          height={height}
          itemCount={itemData.length}
          itemData={itemData}
          itemSize={itemSize}
          overscanCount={10}
          outerElementType={OuterElementType}
        >
          {KeywordRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

export default KeywordList
