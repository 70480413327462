import { IconButton, InputAdornment, TextField, useTheme } from '@material-ui/core'
import { Clear, Search } from '@material-ui/icons'
import React from 'react'
import { useStyles } from './style'

const SearchInput = ({ isLoading, value, onChange, ...props }) => {
  const classes = useStyles()
  const { palette } = useTheme()

  return (
    <TextField
      id='tenant-filter-search'
      label='Search'
      variant='outlined'
      size='small'
      value={value}
      autoComplete='off'
      disabled={isLoading}
      onChange={event => { onChange(event.target.value) }}
      {...props}
      InputLabelProps={{ style: { color: palette.text.disabled } }}
      InputProps={{
        classes: { inputMarginDense: classes.searchInput },
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='clear search term'
              onClick={() => onChange('')}
              edge='start'
              style={{ color: palette.text.disabled, visibility: value.length ? 'visible' : 'hidden' }}
              tabIndex={value.length ? 0 : -1}
            >
              <Clear />
            </IconButton>
            <Search style={{ color: palette.text.disabled }} />
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchInput
