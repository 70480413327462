import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  dialogPaper: {
    padding: spacing(2, 3),
    maxHeight: '95%',
    maxWidth: 1440
  },
  dialogTitle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: spacing(3)
  },
  modalTitle: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '500',
    width: '300px',
    color: palette.text.primary
  },
  tabs: {
    marginBottom: spacing(1)
  },
  actionBtnCtn: {
    paddingBottom: spacing(1)
  },
  doneBtn: {
    marginRight: 16,
    minWidth: 100
  },
  saveBtn: { minWidth: 100 },
  selectedContentRoot: {
    marginBottom: spacing(3)
  },
  dashedDivider: {
    marginBottom: spacing(3),
    borderTop: '2px dashed rgba(0, 0, 0, 0.12)',
    background: 'transparent'
  },
  // Content list styles
  root: {},
  gridHeader: {
    height: 60,
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: spacing(3)
  },
  tablePaper: {
    width: '100%'
  },
  tableHead: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.25
  },
  dateUpdatedHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginRight: spacing(1)
    }
  },
  loadMoreBtn: {
    margin: spacing(5, 0)
  },
  loadingAnimation: {
    margin: spacing(5, 0),
    height: '50vh'
  },
  emptyList: {
    minHeight: spacing(30),
    color: palette.text.secondary
  },
  filtersCtn: {
    marginTop: spacing(2)
  },
  filtersGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
    // marginRight: spacing(3)
  },
  mainPromotedIcon: {
    color: '#FF9900',
    marginLeft: spacing(0.5)
  },
  mainPromotedText: {
    color: '#666666',
    marginLeft: spacing(0.5)
  },
  point: {
    width: 6,
    height: 6,
    borderRadius: 3
  },
  status: {
    fontSize: '14px'
  }
}))
