import { useSession } from '@emerald-works/react-auth'
import { Box, CircularProgress, Grid } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AllRolesStr } from '../../helpers/roles/department-data'
import { userHasAnyOfGroups } from '../../helpers/roles/user'
import ArkLogo from '../../layouts/ark-logo.png'
import { UserProfileSlice } from '../../reducers'
import { routes } from '../../routes'
import { ConfirmationScreen } from './components/confirmation-screen'
import { OnboardingScreen } from './components/onboarding-screen'
import ApostrofeImg from './images/apostrofe.svg'
import ShipImg from './images/ship.jpeg'
import { useStyles } from './style'

const Onboarding = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { session } = useSession()
  const userProfile = useSelector(UserProfileSlice.selectors.selectUser)
  const onboarded = userHasAnyOfGroups(session, AllRolesStr)

  React.useEffect(() => { onboarded && navigate(routes.home.path) }, [onboarded, navigate])

  const Body = () => {
    if (!userProfile) {
      return (
        <Box display='flex' height='100%' justifyContent='center' alignItems='center'>
          <CircularProgress size={100} />
        </Box>
      )
    }

    return userProfile.preferredRole ? <ConfirmationScreen /> : <OnboardingScreen />
  }

  return (
    <Grid className={classes.root} container>
      <Grid item xs={4} className={classes.quote}>
        <img src={ShipImg} className={classes.shipImg} />
        <Box display='flex' alignItems='flex-end' color='#FFF'>
          <img className={classes.arkLogo} src={ArkLogo} />
          <Box fontSize={24} fontWeight={600}>The Ark.</Box>
        </Box>
        <Box mt='50%'>
          <img src={ApostrofeImg} className={classes.apostrofeImg} />
          <Box color='#FFF' px={3} fontSize={20} mb={2}>
            We're restless.
            But in a good way. It's not enough to be great at what we do; we want to be the best! We use our research, talent and technology to create outstanding products and service.
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Box className={classes.vector} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={8} className={classes.content}>
        <Body />
      </Grid>
    </Grid>
  )
}

export default Onboarding
