import {
  CircularProgress,
  InputAdornment,
  MenuItem
} from '@material-ui/core'
import { capitalize } from 'lodash'
import React from 'react'
import Input from './input'

const defaultRenderOption = option => capitalize(option)

const Select = ({
  options,
  renderOption = defaultRenderOption,
  loading,
  ...props
}) => {
  return (
    <Input
      {...props}
      select
      disabled={loading}
      InputProps={{
        endAdornment: loading ? (
          <InputAdornment position='end' style={{ marginRight: 16 }}>
            <CircularProgress size={14} />
          </InputAdornment>
        ) : null
      }}
      SelectProps={{
        MenuProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          getContentAnchorEl: null
        },
        style: { maxHeight: 32, font: 'inherit' }
      }}
    >
      {options.map(option => (
        <MenuItem key={option} value={option} dense>
          {renderOption(option)}
        </MenuItem>
      ))}
    </Input>
  )
}

export default Select
