import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    width: '100%',
    padding: spacing(0, 3, 3)
  },
  greyBackground: {
    '& .MuiInputBase-input': {
      backgroundColor: palette.grey.light
    }
  },
  nextBtn: {
    minWidth: 100
  },
  ctnTitle: {
    paddingTop: spacing(4)
  },
  loading: {
    '& .MuiFormHelperText-root': {
      color: palette.primary.main
    }
  },
  disableTouch: {
    pointerEvents: 'none'
  },
  detailsDivider: {
    width: '100%',
    marginTop: spacing(2),
    marginBottom: spacing(2)
  }
}))
