import React from 'react'
import { Button, CircularProgress, Grid, Paper, Typography, useTheme } from '@material-ui/core'
import { ArrowUpward } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { SearchFilter, DateFilter } from '../../content-filters'
import CatalogItem from '../../manage-category/catalog-item'
import { useStyles } from './style'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { PromotedContentSlice } from '../../../reducers'
import { BannerStatic } from '../..'
import { debounce } from 'lodash'
import { ContentTypeFilter } from '@emerald-works-nova/components'

const Filters = React.memo(({ filters, onChange, onClear, clearBtnEnd }) => {
  const classes = useStyles()
  const { spacing } = useTheme()

  const hasFilters = React.useMemo(() => Object.values(filters).some(v => !!v), [filters])

  const handleChangeFilter = (filter, value) => onChange({ ...filters, [filter]: value })

  const ClearBtn = () => (
    <Button
      id='catalog-clear-filters-button'
      color='primary'
      aria-label='clear filters'
      onClick={onClear}
      style={{
        margin: spacing(
          0,
          clearBtnEnd ? 0 : 2,
          0,
          clearBtnEnd ? 2 : 0
        )
      }}
    >
      Clear filters
    </Button>
  )

  return (
    <Grid item aria-label='List filters' tabIndex={0} classes={{ root: classes.filtersGrid }}>
      {hasFilters && !clearBtnEnd && <ClearBtn />}
      <SearchFilter value={filters.search} onChange={value => handleChangeFilter('search', value)} />
      <ContentTypeFilter onChange={value => handleChangeFilter('type', value)} />
      <DateFilter value={filters.dateStart} onChange={value => handleChangeFilter('dateStart', value)} />
      {hasFilters && clearBtnEnd && <ClearBtn />}
    </Grid>
  )
})

const PromotedContentContentList = ({ onSelectChange, selected }) => {
  const classes = useStyles()
  const pageSize = 10
  const initialFilters = React.useMemo(() => ({ search: '', type: '', dateStart: null, dateEnd: null }), [])
  const [filters, setFilters] = React.useState(initialFilters)
  const selectedContent = selected.map(i => i.sk)

  const contentList = useSelector(PromotedContentSlice.selectors.selectCatalogList)
  const contentTotal = useSelector(PromotedContentSlice.selectors.selectCatalogListTotal)

  const [getContentList, fetchMoreContent] = useEvent([
    PromotedContentSlice.eventBus.getCatalogContent,
    PromotedContentSlice.eventBus.fetchCatalogContent
  ])

  useEventsOnViewLoad(() => {
    getContentList.registerAdhocOnErrorListener(e => {
      console.log('getContentList.registerAdhocOnErrorListener', e)
      BannerStatic.show({ label: 'Error retriving content', isError: true })
    })
    fetchMoreContent.registerAdhocOnErrorListener(e => {
      console.log('fetchMoreContent.registerAdhocOnErrorListener', e)
      BannerStatic.show({ label: 'Error fetching more content', isError: true })
    })
  }, [getContentList, fetchMoreContent])

  React.useEffect(() => {
    if (contentList?.length) return
    getContentList.trigger({ size: pageSize, filters: {} })
  }, [getContentList, contentList])

  const hasMoreContent = React.useMemo(() => contentTotal !== contentList.length, [contentList, contentTotal])

  const onFetchMoreContent = React.useCallback(() => {
    fetchMoreContent.trigger({
      from: contentList.length,
      size: pageSize,
      filters
    })
  }, [fetchMoreContent, contentList, filters, pageSize])

  const debouncedSearch = React.useMemo(() => debounce(filters => {
    getContentList.trigger({ size: pageSize, filters })
  }, 1000), [getContentList])

  const handleChangeFilter = React.useCallback(filters => {
    setFilters(filters)
    debouncedSearch(filters)
  }, [setFilters, debouncedSearch])

  const handleClearFilters = React.useCallback(() => {
    setFilters(initialFilters)
    getContentList.trigger({ size: pageSize, filters: initialFilters })
  }, [setFilters, getContentList, initialFilters])

  return (
    <Grid container className={classes.root}>
      <Paper
        id='catalog-list'
        classes={{ root: classes.tablePaper }}
        tabIndex={0}
        aria-label='Content List'
        elevation={0}
      >
        <Typography variant='subtitle1'>Contents</Typography>
        <Grid container className={classes.filtersCtn}>
          <Filters filters={filters} onChange={handleChangeFilter} onClear={handleClearFilters} clearBtnEnd />
        </Grid>
        {!getContentList.isWorking && !!contentList.length && (
          <Grid
            id='catalog-list-headers'
            container
            tabIndex={0}
            direction='row'
            alignItems='center'
            className={classes.gridHeader}
            classes={{ root: classes.tableHead }}
            aria-label='List headers, Title, Type, Last update'
          >
            <Grid item md={8}>Title</Grid>
            <Grid item md={2}>Type</Grid>
            <Grid item md={2} className={classes.dateUpdatedHeader}>
              <ArrowUpward fontSize='small' />
              <span>Last update</span>
            </Grid>
          </Grid>
        )}
        {!getContentList.isWorking && contentList.map((row, key) => {
          const isSelected = selectedContent.includes(row.sk)
          const isDisabled = !isSelected && selectedContent.length === 5
          return (
            <CatalogItem
              key={key}
              index={key}
              article={row}
              classes={classes}
              lastOne={key === contentList.length - 1}
              checked={isSelected}
              onChange={ev => onSelectChange(row)}
              disabled={isDisabled}
              name={row.sk}
            />
          )
        })}
        {getContentList.isWorking && (
          <Grid
            id='catalog-list-loading'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.loadingAnimation }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
        {fetchMoreContent.isWorking && (
          <Grid
            id='catalog-list-loading'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.loadingAnimation }}
            style={{ height: 150 }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
        {getContentList.hasBeenTriggered && !getContentList.isWorking && !fetchMoreContent.isWorking && !contentList.length && (
          <Grid
            id='catalog-list-empty'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.emptyList }}
          >
            <Typography variant='h5'> No results found! </Typography>
          </Grid>
        )}
      </Paper>
      {!getContentList.isWorking && !fetchMoreContent.isWorking && hasMoreContent && (
        <Grid
          id='catalog-list-load-more'
          container
          direction='row'
          justifyContent='center'
          align='center'
          classes={{ root: classes.loadMoreBtn }}
        >
          <Grid item xs={12}>
            <Button variant='contained' color='primary' name='' onClick={onFetchMoreContent}>
              Load More
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default PromotedContentContentList
