import { ActionButton } from '@emerald-works-nova/components'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { Box, FormControlLabel, Radio, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BannerStatic } from '../../../../components'
import { ArkUserSlice } from '../../../../reducers'

const UserRolesRadio = () => {
  const dispatch = useDispatch()
  const [value, setValue] = React.useState()

  const user = useSelector(ArkUserSlice.selectors.selectUser)
  const { username, roles, profile } = user ?? {}
  React.useEffect(() => { setValue(profile?.preferredRole || roles[0]) }, [roles, profile?.preferredRole])

  const [updateProfile] = useEvent([ArkUserSlice.eventBus.adminUpdateUserProfile])
  const [updateRoleUser] = useEvent([
    ArkUserSlice.eventBus.adminUpdateUserRole({
      onSuccess: payload => {
        BannerStatic.show({ label: 'User role updated' })
        dispatch(ArkUserSlice.actions.setUserRole(payload))
      }
    })
  ])

  const handleSave = () => {
    profile.preferredRole && updateProfile.trigger({ username, tenantId: profile.pk, partial: { preferredRole: 'none' } })
    updateRoleUser.trigger({ userPoolId: profile.cognitoSettings.userPoolId, username, role: value })
  }

  const handleChange = ({ target: { value } }) => setValue(value)

  return (
    <>
      <Typography variant='h5' style={{ fontSize: 20, fontWeight: 400 }}>User Role</Typography>
      <Box display='flex' mt={2}>
        <FormControlLabel name='user-role-radio' value='admin' checked={value === 'admin'} onChange={handleChange} control={<Radio color='primary' />} label='Admin' />
        <FormControlLabel name='user-role-radio' value='editor' checked={value === 'editor'} onChange={handleChange} control={<Radio color='primary' />} label='Editor' />
        <FormControlLabel name='user-role-radio' value='marketing' checked={value === 'marketing'} onChange={handleChange} control={<Radio color='primary' />} label='Consumer Mkt' />
        <FormControlLabel name='user-role-radio' value='cx' checked={value === 'cx'} onChange={handleChange} control={<Radio color='primary' />} label='CX' />
        <FormControlLabel name='user-role-radio' value='internalReader' checked={value === 'internalReader'} onChange={handleChange} control={<Radio color='primary' />} label='Internal Reader' />
      </Box>
      <Box mt={3}>
        <ActionButton
          variant='secondary'
          dataTest='backtolist-button'
          isWorking={updateProfile.isWorking || updateRoleUser.isWorking}
          onClick={handleSave}
        >
          {profile?.preferredRole ? 'Confirm role' : 'Save Changes'}
        </ActionButton>
      </Box>
    </>
  )
}

export default React.memo(UserRolesRadio)
