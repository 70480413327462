import { useTenant } from '@emerald-works-nova/auth'
import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { routes } from '../../../../routes'
import { useArkUserList } from './useArkUserList'
import ArkUserGenericList from '../ark-user-generic-list'

const ArkUserList = ({ filter }) => {
  const navigate = useNavigate()
  const { userPoolId } = useTenant()

  const handleClick = user => { navigate(generatePath(routes.arkUserEdit.path, { id: user.username })) }
  const useList = useArkUserList({ userPoolId, filter })

  return <ArkUserGenericList {...useList} onClick={handleClick} />
}

export default React.memo(ArkUserList)
