import React from 'react'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'

const Input = ({ name, control, loading, children, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      style={{ font: 'inherit' }}
      data-test={`${name}-input`}
      render={({ field }) => (
        <TextField
          {...field}
          variant='outlined'
          disabled={loading}
          size='small'
          margin='none'
          {...props}
          InputProps={{
            style: { font: 'inherit', padding: 0 },
            ...props?.InputProps
          }}
          inputProps={{
            style: { padding: 8 }
          }}
          style={{ minWidth: '80%' }}
        >
          {children}
        </TextField>
      )}
    />
  )
}

export default Input
