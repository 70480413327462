import { Box, FormControlLabel, Radio, Typography } from '@material-ui/core'
import { useStyles } from './style'
import React from 'react'

export const DepartmentRadio = React.memo(({
  label,
  description,
  value,
  icon,
  iconActive,
  name = 'department-checkbox',
  onChange = () => { },
  selectedValue
}) => {
  const checked = React.useMemo(() => value === selectedValue, [selectedValue, value])
  const classes = useStyles({ checked })

  const labelComp = (
    <Box display='flex'>
      <Box width={52} height={52} mr={3.5}>{checked ? iconActive : icon}</Box>
      <Box display='flex' flexDirection='column' flex={1}>
        <Box fontSize={16} fontWeight={500}>{label}</Box>
        <Typography variant='body2'>{description}</Typography>
      </Box>
    </Box>
  )

  return (
    <Box className={classes.root}>
      <FormControlLabel
        label={labelComp}
        labelPlacement='start'
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        control={<Radio color='primary' />}
      />
    </Box>
  )
})
