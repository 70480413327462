import React from 'react'
import { defaults } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { Grid } from '@material-ui/core'
import { ContentFormats } from '@emerald-works-nova/components'
import Legend from '../legend'
import options from './options'

const loadingData = [300, 250, 200, 150, 200]
const loadingLabels = new Array(6).fill(null)
const colors = [
  '#0070BD',
  '#796DC9',
  '#BE63BF',
  '#F25AA1',
  '#FF6277',
  '#FF7F47',
  '#FFA600'
]

const PieChart = ({ data, loading }) => {
  const topTypes = data.slice(0, 6)
  const other = data.slice(6).reduce((acc, curr) => acc + curr, 0)

  let values = topTypes.map(({ count }) => count)
  let labels = topTypes.map(({ sk }) => (ContentFormats.find(f => f.name === sk)?.label))

  if (other > 0) {
    values = [...values, other]
    labels = [...labels, other]
  }

  const chartData = {
    labels: !loading ? labels : loadingLabels,
    datasets: [
      {
        data: !loading ? values : loadingData,
        backgroundColor: ctx =>
          loading ? 'rgba(33, 33, 33, 0.08)' : colors[ctx.dataIndex]
      }
    ]
  }

  return (
    <Grid
      container
      direction='row'
      wrap='nowrap'
      justifyContent='space-around'
      alignItems='center'
    >
      <Grid item xs={6}>
        <Pie
          options={{ ...options, events: loading ? [] : defaults.events }}
          data={chartData}
          height='100%'
        />
      </Grid>
      <Grid item xs={4}>
        <Legend
          fullWidth
          labels={loading ? loadingLabels : labels}
          colors={colors}
        />
      </Grid>
    </Grid>
  )
}

export default PieChart
