import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  root: {
    height: '100%',
    paddingBlock: spacing(3),
    paddingInline: spacing(3)
  },
  header: {
    alignItems: 'center',
    flexFlow: 'row nowrap',
    marginBottom: spacing(3)
  },
  title: {
    ...typography.h6,
    margin: ({ centerTitle }) => centerTitle && 'auto',
    fontFamily: 'Libre Franklin',
    color: palette.text.hint
  },
  tooltipIcon: {
    marginLeft: ({ centerTitle }) => !centerTitle && 'auto',
    marginRight: 0,
    color: palette.text.hint
  },
  small: {
    ...typography.h5,
    fontWeight: typography.fontWeightRegular
  },
  content: {
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center'
  }
}))
