import React from 'react'
import { ContentViewer } from '@emerald-works-nova/editor'
import { NovaEditorConverter } from '@emerald-works-nova/editor-converter'
import { useTenant } from '@emerald-works-nova/auth'
import pretty from 'pretty'
import { CodeBlock, dracula } from 'react-code-blocks'
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Paper, Tabs, Tab, Button } from '@material-ui/core'
import { useEventContext } from '../../hooks/event'
import { TabPanel } from '..'
import { useStyles } from './style'
import { useExternalApp } from '../../hooks'

const shouldDebugReConverted = false

const PreviewDialog = ({ body, open, onClose }) => {
  const [tab, setTab] = React.useState('endUserView')
  const [html, setHtml] = React.useState('')
  const { getEnvInfo } = useEventContext()
  const { tenantKey } = useTenant()

  const classes = useStyles()
  const contentViewerRef = React.useRef()

  const { baseToolkitUrl } = useExternalApp({ tenantKey })

  React.useEffect(() => {
    if (open) {
      const html = NovaEditorConverter.convertFromRawToHtml(body.data)
      setHtml(pretty(html))
    }
  }, [body, open])

  React.useEffect(() => {
    if (tab === 're-converted' && body) {
      const html = NovaEditorConverter.convertFromRawToHtml(body.data)
      contentViewerRef.current && contentViewerRef.current.setContentFromHtml(html)
    }
  }, [tab, body])

  const handleClose = () => {
    setTab('endUserView')
    onClose()
  }

  if (getEnvInfo.isWorking) return null
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='lg'
      fullWidth
      aria-labelledby='preview-dialog-title'
    >
      <DialogTitle id='preview-dialog-title'>Preview</DialogTitle>
      <DialogContent className={classes.dialogWrapper}>
        <Tabs value={tab} indicatorColor='primary' textColor='primary' onChange={(_, newValue) => setTab(newValue)}>
          <Tab label='End user view' value='endUserView' />
          <Tab label='Rendered Html' value='rendered-html' />
          <Tab label='Html Code' value='html' />
          {shouldDebugReConverted && <Tab label='Re-converted' value='re-converted' />}
        </Tabs>
        <TabPanel value={tab} index='endUserView'>
          <Box padding={2} bgcolor='#EEE'>
            <Paper bgcolor='#FFF' style={{ padding: 20 }}>
              <ContentViewer
                body={body}
                baseToolkitUrl={baseToolkitUrl}
              />
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value={tab} index='html' className={classes.codeWrapper}>
          <CodeBlock
            text={html}
            language='html'
            showLineNumbers
            theme={dracula}
          />
        </TabPanel>
        <TabPanel value={tab} index='rendered-html'>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </TabPanel>
        {shouldDebugReConverted &&
          <TabPanel value={tab} index='re-converted'>
            <Box padding={2} bgcolor='#EEE'>
              <Paper bgcolor='#FFF' style={{ padding: 20 }}>
                <ContentViewer
                  ref={contentViewerRef}
                />
              </Paper>
            </Box>
          </TabPanel>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewDialog
