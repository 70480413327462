import { EntryPage, SignInForm, SignInSSO, useTenant } from '@emerald-works-nova/auth'
import { Grid } from '@material-ui/core'
import React from 'react'
import { routes } from '../../routes'
import { useStyles } from './style'

const Login = () => {
  const classes = useStyles()
  const tenant = useTenant()
  return (
    <Grid
      container
      alignItems='stretch'
      justifyContent='center'
      className={classes.container}
    >
      <Grid item>
        <EntryPage
          title='Sign in to The Ark'
          subtitle='Enter your e-mail and password'
          showMtLogo
          loginProviders={tenant?.loginProviders}
          content={
            <SignInForm
              redirectRoute={routes.home.path}
              submitBtnText='Sign in'
            />
          }
          sso={
            <SignInSSO
              className={classes.sso}
              loginProviders={tenant.loginProviders}
            />
          }
        />
      </Grid>
    </Grid>
  )
}

export default Login
