import { Button, Grid, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { useStyles } from './style'

const InputTupple = React.memo(({ attribute, onChange, onRemove, attributeList }) => {
  const classes = useStyles()

  const onChangeAutoComplete = React.useCallback((_, option) => {
    if (!option) return
    onChange({ name: 'name', value: option.name, oldName: attribute.name })
  }, [onChange, attribute])

  const onChangeSamlName = React.useCallback(({ target: { name, value } }) => {
    onChange({ name, value })
  }, [onChange])

  return (
    <Grid container direction='row' className={classes.inputGroup}>
      <Grid item xs={5} className={classes.inputContainer}>
        <Autocomplete
          options={attributeList}
          getOptionLabel={(option) => option.label || attributeList.find(attr => attr.name === option)?.label || option}
          getOptionSelected={(option, value) => option.name === (value.name || value)}
          getOptionDisabled={(option) => option.disabled}
          onChange={onChangeAutoComplete}
          renderOption={(option) => (
            <Grid container direction='column'>
              <Typography variant='body2'>{option.label}</Typography>
              <Typography gutterBottom variant='body2'>{option.description}</Typography>
            </Grid>
          )}
          autoHighlight
          autoSelect
          value={attribute.name || ''}
          renderInput={(params) =>
            <TextField
              {...params}
              fullWidth
              label='Select user pool attribute'
              variant='outlined'
              name='name'
              className={classes.input}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off' // disable autocomplete and autofill
              }}

            />}
        />
      </Grid>
      <Grid item xs={5} className={classes.inputContainer}>
        <TextField
          fullWidth
          label='Enter SAML attribute name'
          variant='outlined'
          name='value'
          className={classes.input}
          value={attribute.value || ''}
          onChange={onChangeSamlName}
        />
      </Grid>
      <Grid item xs={2} className={classes.inputContainer}>
        <Button
          variant='outlined'
          data-test='remove-attribute-button'
          onClick={onRemove}
          className={classes.removeBtn}
        >
          Remove
        </Button>
      </Grid>
    </Grid>
  )
})

export { InputTupple }
