import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'

const Info = ({ title, children, xs = 4 }) => {
  return (
    <Grid item xs={xs}>
      <Typography variant='h5' style={{ fontSize: 20, fontWeight: 400 }}>{title}</Typography>
      <Box mt={2}>
        <Typography component='div' variant='body1' color='textSecondary'>
          {children}
        </Typography>
      </Box>
    </Grid>
  )
}

export default Info
