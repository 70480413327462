import * as actions from './slice'

const getUserList = {
  eventName: 'adminListUsers',
  onSuccess: [{ action: actions.getUserList, redux: true }]
}

const addUserList = {
  eventName: 'adminListUsers',
  onSuccess: [{ action: actions.addUserList, redux: true }]
}

const getUsersByRole = {
  eventName: 'adminListUsersByRole',
  onSuccess: [{ action: actions.getUserList, redux: true }]
}

const addUserByRole = {
  eventName: 'adminListUsersByRole',
  onSuccess: [{ action: actions.addUserList, redux: true }]
}

const getUsersPendingRole = {
  eventName: 'adminListUsersPend',
  onSuccess: [{ action: actions.getUserList, redux: true }]
}

const countUsersPendingRole = {
  eventName: 'adminCountUsersPend',
  onSuccess: [{ action: actions.getUserPendingCount, redux: true }]
}

const addUserPendingRole = {
  eventName: 'adminListUsersPend',
  onSuccess: [{ action: actions.addUserList, redux: true }]
}

const getUser = {
  eventName: 'adminGetUserCognito',
  onSuccess: [{ action: actions.setUserDetail, redux: true }]
}

export {
  getUserList,
  addUserList,
  getUser,
  getUsersByRole,
  addUserByRole,
  getUsersPendingRole,
  addUserPendingRole,
  countUsersPendingRole
}
