import { ActionButton, ConfirmationAlert } from '@emerald-works-nova/components'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { CircularProgress, Divider, Grid, Tab, Tabs, useTheme } from '@material-ui/core'
import { Assignment, Image, RateReviewRounded } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'
import { isEqual } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Prompt } from 'react-router-dom'
import { AutoSaveStatus, Panel, PreviewDialog, SectionHeader, TabPanel } from '../../components'
import { FileDocumentEditIcon } from '../../components/icons'
import { useBeforeUnload } from '../../hooks'
import ContentSlice from '../../reducers/content'
import { SectionMap } from '../../reducers/content-edit/slice'
import { routes } from '../../routes'
import Body from './components/body'
import Details from './components/details'
import FillingInformationPanel from './components/filling-information-panel'
import Status from './components/status'
import Thumbnail from './components/thumbnail'
import { ContentFormContext } from './content-form-context'
import { useStyles } from './style'

const sectionCompMap = {
  details: <Details />,
  thumbnail: <Thumbnail />,
  body: <Body />,
  status: <Status />
}

export const IconMap = {
  details: <Assignment />,
  thumbnail: <Image />,
  body: <FileDocumentEditIcon />,
  status: <RateReviewRounded />
}

const ContentEdit = () => {
  const classes = useStyles()
  const { spacing } = useTheme()
  const { locationId } = useParams()
  const navigate = useNavigate()
  const headerRef = React.useRef()
  const dispatch = useDispatch()
  const form = useForm({ mode: 'onChange' })
  const { setValue, trigger, formState: { isValid } } = form

  const [isSaving, setIsSaving] = React.useState(false)
  const [enableExitWarning, setEnableExitWarning] = React.useState(true)
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false)
  const [contentSaveError, setContentSaveError] = React.useState(false)

  const menuAnchorRef = React.useRef(null)
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false)

  const [tabValue, setTabValue] = React.useState(Object.entries(SectionMap)[0][0])
  const handleTabChange = React.useCallback((_, newValue) => setTabValue(newValue), [setTabValue])

  const content = useSelector(ContentSlice.selectors.selectContent)
  const isLoading = React.useMemo(() => !content?.body, [content])
  const isReadOnly = React.useMemo(() => content.migrationStatus === 'not_ready', [content.migrationStatus])

  const [showSkeletonLoading, setShowSkeletonLoading] = React.useState(true)

  const [getContent, saveContent, removeContent] = useEvent([
    ContentSlice.eventBus.getContent,
    ContentSlice.eventBus.saveContent,
    ContentSlice.eventBus.removeContent
  ])

  useEventsOnViewLoad(() => {
    getContent.trigger({ locationId })

    saveContent.registerAdhocOnErrorListener(() => {
      setContentSaveError(true)
    })
    saveContent.registerAdhocOnSuccessListener(({ payload }) => {
      dispatch(ContentSlice.actions.updateContentInList(payload.content))
    })
    saveContent.registerAdhocOnStartListener(() => {
      setContentSaveError(false)
    })
    removeContent.registerAdhocOnSuccessListener(() => {
      setShowDeleteAlert(false)
      setEnableExitWarning(false)
      navigate(routes.contentList.path)
    })
  }, [getContent, saveContent, removeContent])

  // Update form state with content
  React.useEffect(() => {
    // Only set the form state if the content has changed
    if (content.locationId) {
      Object.entries(content).forEach(([name, value]) => {
        if (name === 'subtitle') {
          setValue('subtitle.html', value.html)
        } else {
          setValue(name, value)
        }
      })
      trigger()
      setShowSkeletonLoading(false)
    }
  }, [content, setValue, trigger])

  // watch form changes
  const formWatch = form.watch()
  const hasChanges = React.useMemo(() => {
    // removing attrs that shouldn't impact the isEqual fn
    const contentComp = {
      ...content,
      updatedAt: undefined,
      createdAt: undefined,
      migrationStatus: undefined,
      body: {
        ...content.body,
        html: undefined
      }
    }
    const formComp = {
      ...formWatch,
      updatedAt: undefined,
      createdAt: undefined,
      migrationStatus: undefined,
      body: {
        ...formWatch.body,
        html: undefined
      }
    }
    const diff = Object.keys(contentComp).find(key => !isEqual(contentComp[key], formComp[key]))
    return !!diff
  }, [formWatch, content])

  const handleContentSave = React.useCallback(() => {
    if (formWatch.locationId && isValid) {
      saveContent.trigger(formWatch)
    }
  }, [formWatch, saveContent, isValid])

  const onSubmit = React.useCallback(() => navigate(routes.contentList.path), [navigate])

  React.useEffect(() => { setIsSaving(saveContent.isWorking) }, [saveContent.isWorking])

  React.useEffect(() => {
    // Accessibility: focus on title of the page when navigating
    // eslint-disable-next-line
    headerRef.current?.focus()

    // Clear content from state on navigation
    return () => dispatch(ContentSlice.actions.setEmptyContent())
  }, [dispatch, headerRef])

  const handleContentDelete = () => {
    removeContent.trigger({
      locationId
    })
    setEnableExitWarning(false)
  }

  // warns the user if they try to leave without saving the changes they made
  useBeforeUnload(() => enableExitWarning && !hasChanges)

  return (
    <ContentFormContext.Provider value={{ form, saveContent, setIsSaving, setContentSaveError, isReadOnly }}>
      {/* warns the user if they try to leave without saving the changes they made */}
      <Prompt when={enableExitWarning && hasChanges} message="Are you sure? If you proceed all changes will be lost and this action can't be undone." />
      <form noValidate autoComplete='off'>
        {/* Top navigation */}
        <Grid container spacing={2} style={{ marginBottom: spacing(3) }}>
          <Grid item xs={7} data-test='title-edited'>
            {showSkeletonLoading ? (
              <Skeleton className={classes.skeleton} variant='rect' height={40} />
            ) : (
              <SectionHeader
                ref={headerRef}
                title={content.title}
                statusDetail={<AutoSaveStatus isSaving={isSaving} isLoading={isLoading} error={contentSaveError} hasChanges={hasChanges} />}
                location='Content /'
              />
            )}
          </Grid>
          <Grid item xs={5} className={classes.controlBtnContainer}>
            {showSkeletonLoading ? (
              <Skeleton className={classes.skeleton} variant='rect' height={40} />
            ) : (
              <>
                <ActionButton
                  forwardRef={menuAnchorRef}
                  ariaLabel='more options'
                  variant='secondary'
                  className={classes.deleteBtn}
                  dataTest='delete-article-button'
                  onClick={() => setShowDeleteAlert(true)}
                > Delete
                </ActionButton>
                <ActionButton
                  variant='secondary'
                  dataTest='create-content-preview-button'
                  onClick={() => setIsPreviewOpen(true)}
                > Preview
                </ActionButton>
                <Divider orientation='vertical' variant='middle' flexItem className={classes.btnDivider} />
                <ActionButton
                  variant='secondary'
                  dataTest='create-content-done-button'
                  disabled={isSaving || !isValid}
                  onClick={onSubmit}
                  className={classes.backBtn}
                > Cancel
                </ActionButton>
                <ActionButton
                  variant='primary'
                  dataTest='save-content'
                  onClick={handleContentSave}
                  disabled={!hasChanges || isSaving}
                > Save
                  {isSaving && <CircularProgress className={classes.loadingSaveBtn} size={20} />}
                </ActionButton>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* Editor tabs */}
          <Grid item xs={9}>
            {showSkeletonLoading ? (
              <Skeleton className={classes.skeleton} variant='rect' height={400} />
            ) : (
              <Panel padding={0}>
                <Tabs
                  indicatorColor='primary'
                  textColor='primary'
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label='Editor Tabs'
                  classes={{
                    root: classes.tabs
                  }}
                >
                  {Object.entries(SectionMap).map(([key, section]) => (
                    <Tab
                      id={`tab-${key}`}
                      aria-controls={`tabpanel-${key}`}
                      key={key}
                      value={key}
                      label={section.title}
                      icon={IconMap[key]}
                      classes={{ wrapper: classes.tabWrapper }}
                      disabled={!content?.body}
                    />
                  ))}
                </Tabs>
                {/* Tabs content */}
                {Object.entries(SectionMap).map(([key]) => (
                  <TabPanel key={key} value={tabValue} index={key}>
                    {sectionCompMap[key]}
                  </TabPanel>
                ))}
              </Panel>
            )}
          </Grid>
          {/* Right side information panel */}
          <Grid item xs={3}>
            {showSkeletonLoading ? (
              <Skeleton className={classes.skeleton} variant='rect' height={600} />
            ) : (
              <FillingInformationPanel selectedTab={tabValue} />
            )}
          </Grid>
        </Grid>
        {/* Delete modal */}
        <ConfirmationAlert
          title='Deleting this content?'
          description='Deleting this content will remove it from the content catalogue.'
          agreeText='Delete'
          showModal={showDeleteAlert}
          onConfirmation={handleContentDelete}
          onCancel={() => setShowDeleteAlert(false)}
          isLoading={removeContent.isWorking}
          disableBackdropClick
        />
      </form>
      <PreviewDialog
        open={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        body={content.body}
      />
    </ContentFormContext.Provider>
  )
}

export default ContentEdit
