import { Button, Grid, useTheme } from '@material-ui/core'
import React from 'react'
import { useStyles } from './style'

const FiltersContainer = React.memo(
  ({ filters, onChange, onClear, clearBtnEnd, children }) => {
    const classes = useStyles()
    const { spacing } = useTheme()

    const childrenArray = React.Children.toArray(children)

    const hasFilters = React.useMemo(
      () => Object.values(filters).some(v => !!v),
      [filters]
    )

    const handleChangeFilter = (filter, value) =>
      onChange({ ...filters, [filter]: value })

    const ClearBtn = () => (
      <Button
        id='catalog-clear-filters-button'
        color='primary'
        aria-label='clear filters'
        onClick={onClear}
        style={{ marginRight: spacing(2) }}
      >
        Clear filters
      </Button>
    )

    return (
      <Grid
        item
        aria-label='List filters'
        tabIndex={0}
        classes={{ root: classes.filtersGrid }}
      >
        {hasFilters && !clearBtnEnd && <ClearBtn />}
        {React.Children.map(childrenArray, child =>
          React.cloneElement(child, {
            value: filters[child.props.name],
            onChange: value => handleChangeFilter(child.props.name, value)
          })
        )}
        {hasFilters && clearBtnEnd && <ClearBtn />}
      </Grid>
    )
  }
)

export default FiltersContainer
