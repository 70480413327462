import { FormInputRow } from '@emerald-works-nova/components'
import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Switch,
  Typography
} from '@material-ui/core'
import {
  ContentBasicInformationForm,
  KeywordSelect
} from '../../../../components'
import {
  useContentForm
} from '../../content-form-context'
import { Controller } from 'react-hook-form'
import { ExpandMore } from '@material-ui/icons'
import classNames from 'classnames'
import { useStyles } from './style'

const Details = () => {
  const classes = useStyles()
  const { form, isReadOnly } = useContentForm()
  const { formState: { errors } } = form
  const [expanded, setExpanded] = React.useState('keywords')

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={
      classNames(classes.root, { [classes.rootCollapsed]: expanded === 'keywords' })
    }
    >
      <Accordion
        elevation={0}
        classes={{
          root: classes.accordion,
          expanded: classes.accordionExapanded
        }}
        expanded={expanded === 'basic-info'}
        onChange={handleChange('basic-info')}
      >
        <AccordionSummary
          classes={{
            root: classes.summary
          }}
          expandIcon={<ExpandMore />}
          aria-controls='panel-basic'
          id='panel-basic-header'
        >
          <Typography
            className={
              classNames(
                classes.heading,
                { [classes.headingActive]: expanded === 'basic-info' })
            }
            data-test='basic-info'
          >
            Basic Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.detail
          }}
        >
          <ContentBasicInformationForm
            {...form}
            errors={errors}
            smallFormTitles
            blockTypeOptions
            isReadOnly={isReadOnly}
            isEdit
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        classes={{
          root: classes.accordion,
          expanded: classes.accordionExapanded
        }}
        expanded={expanded === 'keywords'}
        onChange={handleChange('keywords')}
      >
        <AccordionSummary
          classes={{
            root: classNames(
              classes.summary
            )
          }}
          expandIcon={<ExpandMore />}
          aria-controls='panel-keywords'
          id='panel-keywords-header'
        >
          <Typography
            className={
              classNames(
                classes.heading,
                { [classes.headingActive]: expanded === 'keywords' })
            }
          >
            Keywords
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.detail
          }}
        >
          <FormInputRow
            DescriptionComponent={(() => (
              <>
                Use the keywords to describe the content which will be used to present content in groups.
                <br />
                <br />
                - Add at least one keyword for the content.
              </>
            ))()}
            hideTitle
            InputComponent={
              <Controller
                name='keywords'
                control={form.control}
                defaultValue={[]}
                render={(
                  { field: { onChange, value } }
                ) => (
                  <KeywordSelect
                    onChange={onChange}
                    value={value}
                    isReadOnly={isReadOnly}
                  />
                )}
              />
            }
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        classes={{
          root: classes.accordion,
          expanded: classes.accordionExapanded
        }}
        expanded={expanded === 'exclusive'}
        onChange={handleChange('exclusive')}
      >
        <AccordionSummary
          classes={{
            root: classNames(
              classes.summary,
              {
                [classes.summaryNoBorder]: expanded !== 'exclusive'
              }
            )
          }}
          expandIcon={<ExpandMore />}
          aria-controls='panel-keywords'
          id='panel-keywords-header'
        >
          <Typography
            className={
              classNames(
                classes.heading,
                { [classes.headingActive]: expanded === 'exclusive' })
            }
          >
            Exclusive Content Settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.detail
          }}
        >
          <FormInputRow
            DescriptionComponent={(
              <div className={classes.exclusiveDescription}>
                Exclusive content are stubbed for non-subscribed users.
              </div>
            )}
            hideTitle
            InputComponent={
              <Controller
                name='exclusive'
                control={form.control}
                defaultValue={false}
                render={(
                  { field: { onChange, value } }
                ) => (
                  <FormControlLabel
                    label='Exclusive Content'
                    labelPlacement='start'
                    classes={{ label: classes.exclusiveLabel }}
                    control={
                      <Switch
                        checked={value}
                        color='primary'
                        readOnly={isReadOnly}
                        onChange={(ev) => onChange(ev.target.checked)}
                      />
                    }
                  />
                )}
              />
            }
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default Details
