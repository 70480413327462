import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  catalogList: [],
  catalogListTotal: undefined
}

// Store
export const promotedContent = createSlice({
  name,
  initialState,
  reducers: {
    getCatalogContent: (state, { payload: { content, total } }) => {
      state.catalogList = content
      state.catalogListTotal = total
    },
    fetchCatalogContent: (state, { payload: { content, total } }) => {
      state.catalogList = state.catalogList.concat(content)
      state.catalogListTotal = total
    },
    reset: () => initialState
  }
})

export const {
  getCatalogContent,
  fetchCatalogContent,
  reset
} = promotedContent.actions

export default promotedContent.reducer
