import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(3)
  },
  titleDivider: {
    marginTop: spacing(3),
    width: '100%'
  },
  loadingAnimation: {
    marginTop: spacing(4.5)
  }
}))
