import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  header: {
    marginBottom: 24
  },
  formHeader: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingBottom: 18
  }
}))
