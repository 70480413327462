import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import {
  ImageEdit,
  ImageUploader,
  ImageUtil
} from '@emerald-works-nova/components'
import LogoEditMenu from './logo-edit'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {},
  logoBackground: {
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02))',
    border: '1px solid rgba(0, 0, 0, 0.38)',
    borderRadius: 4,
    paddingInline: 26,
    paddingBlock: 24
  },
  logo: {
    maxHeight: 116,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

const InputLogo = props => {
  const { setValue, name, watch, minWidth = 40, minHeight = 40, maxSize = 5242880 } = props

  const parseImgixUrl = React.useCallback((s3Url, imgixParams = {}) => {
    return ImageUtil.ImgixGenerateImageUrl(s3Url, { ...imgixParams })
  }, [])

  const imgInfo = watch(name)

  const isSvg = React.useMemo(() => imgInfo?.type?.includes('svg'), [imgInfo?.type])

  const [editMenuAnchor, setEditMenuAnchor] = React.useState(null)
  const [logoCropOpen, setLogoCropOpen] = React.useState(false)
  const [imgPreviewUrl, setImgPreviewUrl] = React.useState(
    parseImgixUrl(imgInfo?.s3Url, imgInfo?.imgixCropParams)
  )
  const classes = useStyles({ hasImage: !!imgPreviewUrl })

  const handleOnDropAccepted = imgInfo => {
    const isSvg = imgInfo.type.includes('svg')
    setValue(name, imgInfo)
    setImgPreviewUrl(parseImgixUrl(imgInfo.s3Url))
    if (!isSvg) setLogoCropOpen(true)
  }

  const handleCropDone = cropInfo => {
    Object.entries(cropInfo).forEach(([attr, value]) =>
      setValue(`${name}.${attr}`, value)
    )
    const newImgPreviewUrl = parseImgixUrl(
      imgInfo.s3Url,
      cropInfo.imgixCropParams
    )
    setImgPreviewUrl(newImgPreviewUrl)
  }

  const handleOpenCropModal = () => setLogoCropOpen(true)
  const handleCloseCropModal = () => setLogoCropOpen(false)

  const handleOpenEditMenu = (event) => setEditMenuAnchor(event.currentTarget)
  const handleCloseEditMenu = () => setEditMenuAnchor(null)

  const handleDeleteLogo = () => setValue(name, {})

  return (
    <>
      {imgInfo?.imgUrl && (
        <ImageEdit
          title='Logo Adjustments'
          open={logoCropOpen}
          image={imgInfo}
          aspect={imgInfo?.width / imgInfo?.height}
          helpText='For better visualization, crop the spare white around the logo graphic.'
          onClose={handleCloseCropModal}
          onCropDone={handleCropDone}
        />
      )}
      <Grid container justifyContent='center' className={classes.root}>
        {!imgInfo?.imgUrl ? (
          <ImageUploader
            onDropAccepted={handleOnDropAccepted}
            label='Drop Logo File here to upload or'
            imageNamePrefix='tenant-logo'
            validation={{ minWidth, minHeight, maxSize, accept: '.svg,.png' }}
          />
        ) : (
          <Grid container justifyContent='center' className={classes.logoBackground}>
            <LogoEditMenu
              anchorEl={editMenuAnchor}
              onEdit={isSvg ? null : handleOpenCropModal}
              onDelete={handleDeleteLogo}
              onClose={handleCloseEditMenu}
            />
            <img
              src={imgPreviewUrl}
              alt={imgInfo.altText}
              onClick={handleOpenEditMenu}
              className={classes.logo}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
export default InputLogo
