import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(3)
  },
  stockPhotosItem: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 1.75)
  }
}))
