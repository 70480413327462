import { ActionButton } from '@emerald-works-nova/components'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { CircularProgress, Dialog, DialogTitle, Grid, Tab, Tabs } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BannerStatic, TabPanel } from '..'
import CatalogTab from './catalog-tab'
import ContentTab from './content-tab'
import { ManageCategoryContext } from './context'
import { useStyles } from './style'
import ManageCategorySlice from '../../reducers/manage-category'
import { differenceWith, isEqual } from 'lodash'

const SectionNames = { catalog: 'catalog', content: 'content' }
const SectionMap = {
  [SectionNames.catalog]: {
    component: <CatalogTab />,
    title: 'Catalog'
  },
  [SectionNames.content]: {
    component: <ContentTab />,
    title: 'Content Present Here'
  }
}

const ManageCategory = ({
  open,
  template,
  category,
  onClose = () => {}
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = React.useState(Object.entries(SectionMap)[0][0])
  const categoryContent = useSelector(ManageCategorySlice.selectors.selectCategoryContent)
  const categoryContentOriginal = useSelector(ManageCategorySlice.selectors.selectCategoryContentOriginal)
  const [selectedContent, setSelectedContent] = React.useState({})
  const handleTabChange = React.useCallback((_, newValue) => setTabValue(newValue), [setTabValue])

  const [getCategoryContent, saveChanges] = useEvent([
    ManageCategorySlice.eventBus.getCategoryContent,
    {
      eventName: 'contentManageContentCategory',
      onSuccess: () => {
        BannerStatic.show({ label: 'Changes saved successfully' })
        onClose()
      }
    }
  ])

  useEventsOnViewLoad(() => {
    getCategoryContent.registerAdhocOnErrorListener(e => {
      console.log('getCategoryContent.registerAdhocOnErrorListener', e)
      BannerStatic.show({ label: 'Error retriving content', isError: true })
    })
    saveChanges.registerAdhocOnErrorListener(e => {
      console.log('saveChanges.registerAdhocOnErrorListener', e)
      BannerStatic.show({ label: 'Error saving changes', isError: true })
    })
  }, [getCategoryContent, saveChanges])

  React.useEffect(() => {
    if (!category || !open) return
    getCategoryContent.trigger({
      filters: { categories: [category.sk] },
      size: 500,
      from: 0,
      templateSK: template.sk
    })
  }, [category, getCategoryContent, template.sk, open])

  React.useEffect(() => {
    if (getCategoryContent.hasBeenTriggered && categoryContent) {
      const result = categoryContent.reduce((result, item) => {
        result[item.sk] = true
        return result
      }, {})
      setSelectedContent(result)
    }
  }, [getCategoryContent, categoryContent, setSelectedContent])

  React.useEffect(() => {
    if (open) return
    dispatch(ManageCategorySlice.actions.reset())
    setTabValue(Object.entries(SectionMap)[0][0])
  }, [open, dispatch, setTabValue])

  const handleSelect = React.useCallback(({ checked }, content) => {
    if (checked) dispatch(ManageCategorySlice.actions.addCategoryContent(content))
    else dispatch(ManageCategorySlice.actions.rmCategoryContent(content))
  }, [dispatch])

  const handleSave = React.useCallback(() => {
    const contentToAdd = differenceWith(categoryContent, categoryContentOriginal, isEqual)
    const contentToRm = differenceWith(categoryContentOriginal, categoryContent, isEqual)
    saveChanges.trigger({ template, category, contentToAdd, contentToRm })
  }, [categoryContent, categoryContentOriginal, saveChanges, template, category])

  const contextValue = {
    categoryContent,
    selectedContent,
    handleSelect,
    loadingCategoryContent: getCategoryContent.isWorking
  }

  return (
    <Dialog
      open={open}
      fullScreen
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby='manage-category-title'
    >
      <DialogTitle id='manage-category-title' className={classes.dialogTitle}>
        <Grid container className={classes.actionBtnCtn}>
          <Grid item xs={8}>{category?.name || 'Category Name'}</Grid>
          <Grid container item xs={4} justifyContent='flex-end'>
            <ActionButton
              variant='secundary'
              dataTest='create-tenant-done-button'
              className={classes.doneBtn}
              onClick={onClose}
              disabled={saveChanges.isWorking}
            > Cancel
            </ActionButton>
            <ActionButton
              variant='primary'
              dataTest='create-tenant-save-button'
              onClick={handleSave}
              className={classes.saveBtn}
              disabled={saveChanges.isWorking}
            >
              {saveChanges.isWorking
                ? <CircularProgress size={25} color='primary' />
                : 'Save'}
            </ActionButton>
          </Grid>
        </Grid>
      </DialogTitle>

      {/* Tabs */}
      <Grid>
        <Grid>
          <Tabs
            indicatorColor='primary'
            textColor='primary'
            value={tabValue}
            onChange={handleTabChange}
            aria-label='Tabs'
            classes={{
              root: classes.tabs
            }}
          >
            {Object.entries(SectionMap)
              .map(([key, section]) => (
                <Tab
                  id={`tab-${key}`}
                  aria-controls={`tabpanel-${key}`}
                  key={key}
                  value={key}
                  label={section.title}
                  icon={section.icon}
                  classes={{ wrapper: classes.tabWrapper }}
                />
              ))}
          </Tabs>
        </Grid>
        {/* Tabs outlet */}
        <ManageCategoryContext.Provider value={contextValue}>
          {Object.entries(SectionMap).map(([key]) => (
            <TabPanel key={key} value={tabValue} index={key}>
              {SectionMap[key].component}
            </TabPanel>
          ))}
        </ManageCategoryContext.Provider>
      </Grid>
    </Dialog>
  )
}

ManageCategory.propTypes = {
  open: PropTypes.bool.isRequired,
  template: PropTypes.object.isRequired
}

export default ManageCategory
