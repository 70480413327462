import name from './name'

const selectSliceData = state => state[name]
const selectUserList = state => selectSliceData(state).list
const selectLastKey = state => selectSliceData(state).lastKey
const selectTotal = state => selectSliceData(state).total
const selectTotalPending = state => selectSliceData(state).totalPending

const selectUser = state => selectSliceData(state).user

const selectUserPoolId = state => selectSliceData(state).userPoolId

const selectUserById = (state, userId) =>
  selectUserList(state).find(user => user.username === userId)

export {
  selectUserList,
  selectLastKey,
  selectTotal,
  selectTotalPending,
  selectUserById,
  selectUser,
  selectUserPoolId
}
