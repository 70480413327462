import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    marginBottom: spacing(3)
  },
  userTotal: {
    color: palette.text.secondary,
    marginLeft: spacing(1)
  },
  dateUpdatedHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginRight: spacing(1)
    }
  }
}))
