import { ActionButton, FormInputRow, Input } from '@emerald-works-nova/components'
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import TinyEditorComponent from '../../../components/tiny-editor'
import { InputLogo, InputColor } from '../components'
import { useTenantFormContext } from '../tenant-form-context'
import { useStyles } from './style'

const Branding = () => {
  const classes = useStyles()
  const { form, onUpdateTenant, isEdit, handleNextClick, handlePreviousClick, mainClasses } = useTenantFormContext()
  const { watch, formState: { errors } } = form

  const tenantName = watch('name')

  const onNext = () => {
    handleNextClick()
  }

  return (
    <Grid container className={classes.root}>
      <Grid container alignItems='center' className={mainClasses.ctnTitle}>
        <Grid item xs={9}>
          <Typography variant='h6'>Branding</Typography>
        </Grid>
        <Grid container item justifyContent='flex-end' xs={3}>
          {!isEdit && (
            <>
              <ActionButton
                variant='primary'
                dataTest='create-tenant-save-button'
                className={mainClasses.navBtn}
                onClick={handlePreviousClick}
              >Previous
              </ActionButton>
              <ActionButton
                variant='primary'
                dataTest='create-tenant-save-button'
                onClick={onNext}
                className={mainClasses.navBtn}
              >Next
              </ActionButton>
            </>
          )}
          {isEdit && (
            <ActionButton
              variant='primary'
              dataTest='button-save-tenant-sso'
              onClick={onUpdateTenant}
              className={classes.saveBtn}
            > Save Changes
            </ActionButton>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FormInputRow
          title='Logo Upload'
          description='The branding logo to be uploaded and displayed on website template.'
          smallFormTitles
          InputComponent={<InputLogo name='logo' {...form} />}
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputRow
          title='Logo Alt Text'
          description='Describe the logo to visitors who are unable to see it. The name of the business or the toolkit is sufficient. For example, for our own version of the toolkit, Discovery, you could just say "Discovery from Mindtools for Business".'
          smallFormTitles
          InputComponent={
            <Input
              rules={{ required: true }}
              label='Logo Alt Text'
              name='logo.altText'
              defaultValue={tenantName}
              control={form.control}
              valid={!errors?.logo?.altText}
              error='Logo alt text is required.'
              showErrors
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputRow
          title='Banner config'
          description='The branding banner to be displayed on website template.'
          smallFormTitles
          InputComponent={
            <TinyEditorComponent
              {...form}
              name='banner'
              value={watch('banner')}
              control={form.control}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputRow
          title='Main Colour'
          smallFormTitles
          description={
            <>
              <p>
                The main colour on the website must have good contrast, match
                with the client logo, and will be the reference for all design
                branding.
              </p>
              <p>
                Colour Suggestions are based on the logo upload and we present
                what we believe are the best matches for the website.
              </p>
              <p>
                The main colour will be applied on icons, design elements and
                across the client website to gain the branding style.
              </p>
            </>
          }
          InputComponent={
            <InputColor
              {...form}
              errors={errors}
              name='mainColor'
            />
          }
        />
      </Grid>
    </Grid>
  )
}

export default Branding
