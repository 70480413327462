import { useSession } from '@emerald-works/react-auth'

const useGroup = () => {
  const { session } = useSession()

  const groups = session?.accessToken?.payload['cognito:groups']

  /**
   * allowed groups can be single group or array of groups
   */
  const isInGroup = (allowedGroups) => {
    if (!Array.isArray(allowedGroups)) {
      allowedGroups = [allowedGroups]
    }
    return groups && groups.some(group => {
      return allowedGroups.includes(group)
    })
  }

  return { groups, isInGroup }
}

export default useGroup
