import { Grid, Paper, Typography } from '@material-ui/core'
import { ArrowUpward, Star } from '@material-ui/icons'
import React from 'react'
import CatalogItem from '../../manage-category/catalog-item'
import { useStyles } from './style'

const PromotedContentSelectedContentList = ({ onSelectChange, selected }) => {
  const classes = useStyles()
  const selectedContent = selected.map(i => i.sk)

  return (
    <Grid container className={classes.selectedContentRoot}>
      <Paper
        id='catalog-list'
        classes={{ root: classes.tablePaper }}
        tabIndex={0}
        aria-label='Content List'
        elevation={0}
      >
        <Typography variant='subtitle1'>Promoted Content</Typography>
        <Grid
          id='catalog-list-headers'
          container
          tabIndex={0}
          direction='row'
          alignItems='center'
          className={classes.gridHeader}
          classes={{ root: classes.tableHead }}
          aria-label='List headers, Title, Type, Last update'
        >
          <Grid item md={8}>Title</Grid>
          <Grid item md={2}>Type</Grid>
          <Grid item md={2} className={classes.dateUpdatedHeader}>
            <ArrowUpward fontSize='small' />
            <span>Last update</span>
          </Grid>
        </Grid>
        {selected.map((row, key) => {
          const isSelected = selectedContent.includes(row.sk)
          const nameAdornment = key === 0 && (
            <>
              <Star fontSize='small' className={classes.mainPromotedIcon} />
              <span className={classes.mainPromotedText}>Main Promoted Card</span>
            </>
          )
          return (
            <CatalogItem
              key={key}
              index={key}
              article={row}
              classes={classes}
              nameAdornment={nameAdornment}
              lastOne={key === selected.length - 1}
              checked={isSelected}
              onChange={ev => onSelectChange(row)}
              name={row.sk}
            />
          )
        })}
      </Paper>
    </Grid>
  )
}

export default PromotedContentSelectedContentList
