import { Box, Checkbox, FormControlLabel, Grid, Typography, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { NoThumbnailImage } from '../../../components/icons'
import { useStyles } from './style'

const CatalogItem = React.memo(({
  article,
  index,
  lastOne,
  checked = false,
  onChange,
  disabled = false,
  nameAdornment = null,
  name
}) => {
  const classes = useStyles()
  const { palette } = useTheme()

  const rootClass = classNames(
    classes.gridRow,
    { [classes.gridRowLast]: lastOne }
  )

  const getThumbnailImage = () => {
    if (article.image?.imgUrl) return <img alt='Content' src={article.image.imgUrl + 'fit=crop&w=40&h=40'} className={classes.cardImage} />

    return (
      <div className={classes.noImageIconWrapper}>
        <NoThumbnailImage />
      </div>
    )
  }

  return (
    <FormControlLabel
      classes={{
        root: rootClass,
        label: classes.checkBoxLbl
      }}
      control={<Checkbox checked={checked} disabled={disabled} onChange={onChange} name={name} color='primary' />}
      label={(
        <Grid
          role='row button'
          data-test={`catalog-list-item-${index}`}
          container
          tabIndex={0}
          direction='row'
          alignItems='center'
        >
          <Grid item md={8}>
            <Box display='block'>
              <Box display='flex' flexDirection='row'>
                <Box data-test={`content-thumbnail-${index}`}>
                  {getThumbnailImage()}
                </Box>
                <Box display='flex' flexDirection='column' flex={1}>
                  <Typography variant='body2' data-test={`content-title-${index}`} style={{ display: 'flex' }}>{article.title} {nameAdornment}</Typography>
                  <Typography variant='body2' noWrap className={classes.textSummary} data-test={`content-summary-${index}`}>{article.subtitle?.html}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={2} className={classes.typeColumn} style={{ '--type-color': palette.contentType[article.primaryType[0]] }} data-test='content-type'>
            <span>{article.primaryType[0]}</span>
          </Grid>
          <Grid item md={2} className={classes.dateUpdatedColumn} data-test={`content-lastupdate-${index}`} title={format(parseISO(article.updatedAt), 'PPpppp')}>
            {parseISO(article.updatedAt).toLocaleDateString()}
          </Grid>
        </Grid>
      )}
    />
  )
})

export default CatalogItem
