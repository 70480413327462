import { Divider, Grid, Typography } from '@material-ui/core'
import { RemoveRedEyeOutlined } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { NoThumbnailImage } from '../../../../components/icons'
import { useStyles } from './style'

const PlaylistGrid = ({ data, loading }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2} justifyContent='space-between'>
      {(loading ? new Array(6).fill({}) : data).map(
        ({ sk, name, image, count }, idx) => (
          <Grid
            key={sk ?? idx}
            item
            xs={5}
            container
            spacing={2}
            wrap='nowrap'
            className={classes.playlist}
          >
            <Grid item>
              {image ? (
                image?.imgUrl ? (
                  <img
                    src={image.imgUrl + 'w=80&h=58'}
                    className={classes.image}
                  />
                ) : (
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    className={classes.image}
                  >
                    <NoThumbnailImage color='disabled' />
                  </Grid>
                )
              ) : (
                <Skeleton variant='rect' className={classes.image} />
              )}
            </Grid>
            <Grid item xs className={classes.info}>
              <Typography variant='subtitle2'>
                {name ?? <Skeleton width={100} />}
              </Typography>
              <Divider variant='fullWidth' />
              {count ? (
                <Typography
                  variant='caption'
                  color='textSecondary'
                  className={classes.viewers}
                >
                  <RemoveRedEyeOutlined fontSize='inherit' />
                  {`${count} viewer${count === 1 ? '' : 's'}`}
                </Typography>
              ) : (
                <Skeleton width={50} />
              )}
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  )
}

export default PlaylistGrid
