import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    borderRadius: spacing(0.5),
    outline: `1px solid ${palette.divider}`,
    marginRight: spacing(3),
    marginBottom: spacing(3),
    maxWidth: 207
  },
  templateImg: {
    maxHeight: 142
  },
  description: {
    padding: spacing(2),
    flex: 1
  },
  fromTxt: {
    color: palette.blue.dark
  },
  ctnBtns: {
    padding: spacing(0, 2, 3)
  },
  viewBtn: {
    marginRight: spacing(1)
  }
}))
