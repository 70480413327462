export const accent = {
  0: '#F2F8FC',
  5: '#E5F1F8',
  10: '#CCE3F2',
  20: '#99C8E4',
  30: '#66ACD7',
  40: '#3391C9',
  50: '#0075BC',
  60: '#005E96',
  70: '#004671',
  80: '#002F4B',
  90: '#001726',
  100: '#000C13'
}

export const grey = {
  0: '#FEFCFF',
  5: '#F5F5F5',
  10: '#DDDBE0',
  20: '#CCC9CF',
  30: '#B9B7BD',
  40: '#A5A4AA',
  50: '#908F96',
  60: '#7A7A80',
  70: '#636469',
  80: '#4A4C50',
  90: '#313335',
  100: '#18191A'
}

export const alert = {
  0: '#FFEEF0',
  5: '#FFDCE0',
  10: '#FFCAD0',
  20: '#FFA2AD',
  30: '#FE7E8D',
  40: '#F96071',
  50: '#F1485B',
  60: '#E03347',
  70: '#C91C31',
  80: '#AD1224',
  90: '#870918',
  100: '#56030D'
}

export const success = {
  0: '#F2FFF4',
  5: '#D3F4D9',
  10: '#B6E9BF',
  20: '#82D48F',
  30: '#55BE64',
  40: '#2FA83E',
  50: '#11931F',
  60: '#007D0C',
  70: '#006708',
  80: '#005205',
  90: '#003C03',
  100: '#002401'
}
