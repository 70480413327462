import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  gridRow: {
    height: 60,
    borderBottom: '1px solid #E0E0E0',
    '&::before': {
      content: 'close-quote',
      position: 'absolute',
      borderRadius: spacing(0, 2, 2, 0),
      width: spacing(0.75),
      height: spacing(3.75),
      backgroundColor: `var(--type-bgcolor, ${palette.contentType.article})`
    },
    '&:hover': {
      backgroundColor: palette.background.selected,
      cursor: 'pointer'
    },
    '&:focus': {
      backgroundColor: palette.background.selected
    }
  },
  gridRowLast: {
    border: 'none'
  },
  dateUpdatedColumn: {
    textAlign: 'center'
  },
  typeColumn: {
    color: `var(--type-color, ${palette.contentType.article})`,
    textTransform: 'capitalize',
    '& svg': {
      marginRight: spacing(1.5),
      marginTop: spacing(-0.5),
      float: 'left'
    }
  },
  textSummary: {
    color: palette.text.secondary,
    maxWidth: '42rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cardImage: {
    width: 40,
    height: 40,
    borderRadius: 4,
    marginRight: spacing(2),
    float: 'left'
  },
  contentColumn: {
    padding: spacing(0, 2.5, 0, 3)
  },
  noImageIconWrapper: {
    float: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '40px',
    marginRight: '16px',
    padding: spacing(1.2, 1.5),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    color: palette.text.disabled,
    '& svg': {
      marginTop: 3
    }
  }
}))
