import React from 'react'
import { TemplateHierarchy, TemplatePromotedContent } from '../../../components'
import { useTemplateFormContext } from '../template-form-context'
import { useStyles } from './style'

const Structure = () => {
  const classes = useStyles()
  const { template } = useTemplateFormContext()

  return (
    <div className={classes.root}>
      <TemplateHierarchy template={template} />
      <TemplatePromotedContent template={template} />
    </div>
  )
}

export default Structure
