import { Grid, Typography } from '@material-ui/core'
import { Done, Remove } from '@material-ui/icons'
import React from 'react'
import { IconMap } from '../../'
import { useStyles } from './style'

const FillingInformation = React.memo(({ section: { title, steps }, isSelected, value }) => {
  const classes = useStyles({ isSelected })

  return (
    <Grid container className={classes.root}>
      <Grid container spacing={1} alignItems='center' className={classes.title}>
        <Grid item>{IconMap[value]}</Grid>
        <Grid item>
          <Typography variant='body2' aria-label={`${title} section`} tabIndex={0}>{title}</Typography>
        </Grid>
      </Grid>
      <Grid container direction='column' role='list' spacing={1} className={classes.steps}>
        {Object.entries(steps).map(([key, step]) => (
          <Grid
            data-test='fields'
            container
            key={key}
            tabIndex={0}
            role='listitem'
            aria-label={`${step.label} field, status: ${step.valid ? 'filled' : 'empty'}`}
            alignItems='center'
            className={classes.stepLabel} spacing={1}
          >
            <Grid item data-test='field-tick'>
              {step.valid ? <Done style={{ color: '#2B9D1D' }} /> : <Remove />}
            </Grid>
            <Grid item data-test='field-name'><Typography variant='body2'>{step.label}</Typography></Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
})

export default FillingInformation
