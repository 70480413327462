import { FormInputRow, Input } from '@emerald-works-nova/components'
import { Divider, Grid, MenuItem, Typography } from '@material-ui/core'
import React from 'react'
import { useContentForm } from '../../content-form-context'
import { useStyles } from './style'

const Status = () => {
  const classes = useStyles()
  const { form, isReadOnly } = useContentForm()

  const migrationStatusList = React.useMemo(() => ([
    { label: 'Not Ready', value: 'not_ready' },
    { label: 'Migrated', value: 'migrated' }
  ]), [])

  const reviewStatusList = React.useMemo(() => ([
    { label: 'Awaiting Review', value: 'awaiting_review' },
    { label: 'In Progress', value: 'in_progress' },
    { label: 'Check Internal Links', value: 'check_internal_links' },
    { label: 'Complete', value: 'complete' }
  ]), [])

  return (
    <Grid container className={classes.root}>
      <Typography variant='h6'>Status</Typography>
      <Divider className={classes.titleDivider} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInputRow
            title='Migration status'
            smallFormTitles
            description='Select a migration status to content imported from legacy platforms'
            InputComponent={
              <Input
                select
                autoFocus
                disabled
                control={form.control}
                name='migrationStatus'
              >
                {migrationStatusList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Input>
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInputRow
            title='Review status'
            smallFormTitles
            description='Select a review status to content imported from legacy platforms'
            InputComponent={
              <Input
                select
                autoFocus
                disabled={isReadOnly}
                control={form.control}
                name='reviewStatus'
              >
                {reviewStatusList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Input>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Status
