import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  title: {
    margin: spacing(3, 0, 1.25)
  },
  fieldCtn: {
    width: 'initial'
  },
  fieldWrapper: {
    marginBottom: spacing(2),
    width: 'fit-content',
    flexDirection: 'column',
    '&:not(:last-child)': {
      margin: spacing(0, 2, 2, 0)
    }
  },
  infoContainer: {
    border: `1px solid ${palette.grey.main}`,
    borderRadius: 4,
    width: 'initial'
  },
  descriptionWrapper: {
    maxWidth: 310
  },
  fieldTitle: {
    margin: spacing(4, 0, 1.25),
    width: '100%'
  },
  infoText: {
    lineHeight: '54px',
    paddingLeft: spacing(2)
  },
  btnCopy: {
    textTransform: 'none',
    padding: spacing(0.75, 2)
  },
  disableTitle: {
    marginTop: spacing(3.75)
  },
  btnDisable: {
    backgroundColor: `${palette.error.main} !important`,
    color: palette.white.main,
    textTransform: 'none',
    padding: spacing(0.75, 2)
  },
  btnEnable: {
    textTransform: 'none',
    padding: spacing(0.75, 2),
    width: '100%'
  },
  btnDisabling: {
    color: `${palette.white.main} !important`,
    opacity: 0.5
  }
}))
