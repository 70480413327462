import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BannerStatic } from '../../components'
import { UserListSlice } from '../../reducers'

const consumerTenantKey = process.env.REACT_APP_CONSUMER_TENANT_KEY ?? 'www'

const useConsumerTenant = () => {
  const dispatch = useDispatch()
  const userPoolId = useSelector(UserListSlice.selectors.selectUserPoolId)

  React.useEffect(() => {
    const getTenant = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_GATEWAY_ADDRESS + `/tenant-info?tenantKey=${consumerTenantKey}`)
        const tenantData = await response.json()
        dispatch(UserListSlice.actions.setUserPoolId(tenantData.userPoolId))
      } catch (error) {
        console.error('Error fetching consumer users', error)
        BannerStatic.show({ label: 'Error fetching consumer users', isError: true })
      }
    }
    if (!userPoolId) {
      getTenant()
    }
  }, [userPoolId, dispatch])

  return userPoolId
}

export default useConsumerTenant
