import { BannerStatic } from '../../components'
import * as actions from './slice'

const getUserProfile = {
  eventName: 'getProfile',
  onSuccess: { action: actions.setUser, redux: true },
  onError: (e) => {
    console.error('getUserProfile', e)
    BannerStatic.show({ label: 'Error trying to get user profile', isError: true })
  }
}

const updateUserProfile = {
  eventName: 'updateProfile',
  onSuccess: { action: actions.setUser, redux: true },
  onError: (e) => {
    console.error('updateProfile', e)
    BannerStatic.show({ label: 'Error trying to save user profile', isError: true })
  }
}

export {
  getUserProfile,
  updateUserProfile
}
