import { ActionButton } from '@emerald-works-nova/components'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { BannerStatic } from '../../../components'
import { TenantEditSlice } from '../../../reducers'
import { useTenantFormContext } from '../tenant-form-context'
import MenuSwitchInput from './menuSwitchInput'
import { useStyles } from './style'

const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

const Features = () => {
  const classes = useStyles()
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { tenant, mainClasses } = useTenantFormContext()
  const { pk } = tenant
  const [value, setValue] = useState(tenant.features?.weeklyEmails || '')
  const index = WEEKDAYS.findIndex(x => x === value)

  const [tenantUpdate] = useEvent([
    {
      eventName: 'tenantUpdate',
      onSuccess: (tenant) => {
        BannerStatic.show({ label: 'Features configuration updated' })
        setIsLoading(false)
        dispatch(TenantEditSlice.actions.updateTenantFeatures(tenant.features))
      },
      onError: () => {
        BannerStatic.show({
          label: 'There was an error saving the toolkit features configuration',
          isError: true
        })
        setIsLoading(false)
      }
    }
  ])

  const handleUpdate = () => {
    tenantUpdate.trigger({
      pk,
      features: {
        weeklyEmails: value
      }
    })
    setIsLoading(true)
  }

  return (
    <Grid container className={classes.root}>
      <Grid container alignItems='center' className={mainClasses.ctnTitle}>
        <Grid item xs={9}>
          <Typography variant='h6'>Features</Typography>
        </Grid>
        <Grid container item justifyContent='flex-end' xs={3}>
          <ActionButton
            onClick={handleUpdate}
            disabled={isLoading}
            variant='primary'
            style={{ height: 'min-content' }}
          > Save Changes
          </ActionButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='space-between' style={{ paddingRight: spacing(3) }}>
          <Box>
            <Typography variant='caption' color='textSecondary'>
              Define the features will be available to this Toolkit
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MenuSwitchInput
          itemList={WEEKDAYS}
          onClick={setValue}
          disabled={isLoading}
          defaultValue={index >= 0 ? index : 0}
          isChecked={value !== ''}
        />
      </Grid>
    </Grid>
  )
}

export default Features
