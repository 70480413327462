import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js'
import BarChart from './bar-chart'
import UsageChart from './usage-chart'
import EngagementChart from './engagement-chart'
import PieChart from './pie-chart'

Chart.register(
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip
)

Chart.defaults.font.family = 'Roboto'
Chart.defaults.font.size = 14

Chart.defaults.scale.ticks.font = {
  size: 10,
  weight: 500
}

Chart.defaults.plugins.tooltip = {
  ...Chart.defaults.plugins.tooltip,
  backgroundColor: 'rgba(250, 250, 250, 1)',
  bodyColor: 'rgba(0, 0, 0, 0.6)',
  bodyFont: { size: 12 },
  borderColor: 'rgba(0, 0, 0, 0.12)',
  borderWidth: 1,
  cornerRadius: 4,
  displayColors: false,
  padding: 10,
  titleFont: { size: 12, weight: 'normal' },
  titleColor: 'rgba(0, 0, 0, 0.6)',
  titleSpacing: 10
}

export { BarChart, PieChart, EngagementChart, UsageChart }
