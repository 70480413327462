import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    minHeight: 500,
    padding: spacing(0, 3, 3)
  },
  ctnTitle: {
    marginBottom: spacing(3)
  },
  configCtn: {
    width: 800,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 6,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: spacing(2, 2, 4),
    marginBottom: spacing(4)
  },
  btnCopy: {
    textTransform: 'none',
    padding: spacing(0.75, 2),
    marginBottom: spacing(2),
    backgroundColor: 'rgba(255,255,255, 0.7)',
    border: `1px solid ${palette.text.hint}`,
    color: palette.text.primary
  },
  attrAccordion: {
    marginBottom: '16px !important',
    marginTop: spacing(2)
  },
  attrAccordionDetail: {
    padding: spacing(1, 0, 2)
  },
  attrAccordionSummary: {
    padding: 'unset',
    justifyContent: 'flex-start'
  },
  attrAccordionSummaryContent: {
    flexGrow: 'unset'
  },
  btnUploadSamlFile: {
    margin: spacing(2, 0),
    textTransform: 'capitalize'
  },
  providerLogo: {
    width: spacing(5)
  },
  titlePreview: {
    margin: spacing(3, 0, 1.25, 0)
  },
  descriptionWrapper: {
    maxWidth: ({ fillWidth }) => fillWidth ? 'initial' : 310,
    width: '100%'
  },
  inputWrapper: {
    flex: 1,
    width: ({ fillWidth }) => fillWidth ? '100%' : 'initial',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02))',
    border: '1px solid rgba(0, 0, 0, 0.38)',
    borderRadius: 4,
    paddingInline: 26,
    paddingBlock: 24,
    padding: spacing(3, 0)
  },
  providerLbl: {
    textTransform: 'none'
  }
}))
