import name from './name'

const selectSliceData = state => state[name]
const selectTenantList = state => selectSliceData(state).list
const selectTotal = state => selectSliceData(state).total

export {
  selectTenantList,
  selectTotal
}
