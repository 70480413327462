const readFileText = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file, 'UTF-8')
    reader.onload = evt => resolve(evt.target.result)
    reader.onerror = evt => reject(evt)
  })
}

export {
  readFileText
}
