import { useEvent } from '@emerald-works/react-event-bus-client'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserListSlice } from '../../../../reducers'

export const useArkUserListByRole = ({ tenantId, userPoolId, role }) => {
  const dispatch = useDispatch()

  const userList = useSelector(UserListSlice.selectors.selectUserList)
  const lastKey = useSelector(UserListSlice.selectors.selectLastKey)
  const total = useSelector(UserListSlice.selectors.selectTotal)

  const [getUsersByRole, addUserByRole] = useEvent([UserListSlice.eventBus.getUsersByRole, UserListSlice.eventBus.addUserByRole])

  const isLoading = React.useMemo(() => !getUsersByRole.hasBeenTriggered || getUsersByRole.isWorking, [getUsersByRole.isWorking, getUsersByRole.hasBeenTriggered])
  const isFetching = React.useMemo(() => addUserByRole.isWorking, [addUserByRole.isWorking])

  React.useEffect(() => {
    userPoolId && getUsersByRole.trigger({ tenantId, userPoolId, role, addProfile: true })
  }, [getUsersByRole, tenantId, userPoolId, role])

  React.useEffect(() => () => dispatch(UserListSlice.actions.resetUserList()), [dispatch])

  const onFetchMoreUsers = () => addUserByRole.trigger({ tenantId, userPoolId, lastKey, role })
  return { userList, total, isLoading, isFetching, lastKey, onFetchMoreUsers }
}
