import { useTheme } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { flatten } from 'lodash'
import React, { useEffect, useState } from 'react'
import { contentFormats } from './content-types'
import { useStyles } from './style'

const ContentTypeSelect = ({ value, onChange, blockTypeOptions }) => {
  const classes = useStyles()
  const { palette } = useTheme()
  const [selectedType, setSelectedType] = useState('')

  useEffect(() => {
    const format = contentFormats.find(f => f.name === value[0])
    const type = !value[1] || !format.types ? format : format.types.find(t => t.name === value[1])
    const selected = {
      format: format.name,
      type: type.name,
      label: !value[1] ? (<strong>{format.label}</strong>) : (<><strong>{format.label}</strong> - {type.label}</>)
    }

    setSelectedType(selected)
  }, [value])

  const handleChange = (ev) => {
    setSelectedType(ev.target.value)
    const { format, type } = ev.target.value
    onChange(Array.from(new Set([format, type])))
  }

  const handleRenderValue = ({ label }) => label

  const items = () => {
    const formatItems = contentFormats.map(format => {
      const formatItemValue = {
        format: format.name,
        type: format.name,
        label: (<strong>{format.label}</strong>)
      }
      const formatItem = (
        <MenuItem key={format.name} className={classes.formatItem} value={formatItemValue}>
          <div className={classes.itemIcon} style={{ color: palette.contentType[format.name] }}>{format.icon}</div>
          {format.label}
          <Divider className={classes.formatDivider} />
        </MenuItem>
      )

      const typeItems = format.types.map(type => {
        const typeItemValue = {
          format: format.name,
          type: type.name,
          label: (<><strong>{format.label}</strong> - {type.label}</>)
        }
        return (
          <MenuItem key={type.name} className={classes.typeItem} value={typeItemValue}>
            <div className={classes.itemIcon}>{format.icon}</div>
            {type.label}
            <Divider className={classes.typeDivider} />
          </MenuItem>
        )
      })

      return [formatItem, ...typeItems]
    })

    return flatten(formatItems)
  }

  return (
    <Grid container direction='column'>
      <Select
        id='content-type-select'
        value={selectedType}
        variant='outlined'
        placeholder='Select the content type'
        onChange={handleChange}
        renderValue={handleRenderValue}
        MenuProps={{
          classes: { list: classes.menuList }
        }}
        disabled={blockTypeOptions}
      >
        {items()}
      </Select>
    </Grid>
  )
}

export default ContentTypeSelect
