import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  menuList: {
    padding: spacing(1, 3.75)
  },
  formatItem: {
    padding: spacing(2.75, 0),
    borderBottom: '1px solid #ECECEC'
  },
  formatDivider: {
    margin: spacing(0, 3.75, 0.75)
  },
  typeItem: {
    padding: spacing(1, 0),
    marginLeft: spacing(2.25),
    borderBottom: '1px solid #ECECEC'
  },
  typeDivider: {
    margin: spacing(0, 3.75, 0, 6)
  },
  itemIcon: {
    marginRight: spacing(1),
    display: 'flex'
  }
}))
