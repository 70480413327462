import { useEvent } from '@emerald-works/react-event-bus-client'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserListSlice } from '../../../../reducers'

export const useArkUserList = ({ userPoolId, filter }) => {
  const dispatch = useDispatch()

  const userList = useSelector(UserListSlice.selectors.selectUserList)
  const lastKey = useSelector(UserListSlice.selectors.selectLastKey)
  const total = useSelector(UserListSlice.selectors.selectTotal)

  const [getUserList, addUserList] = useEvent([UserListSlice.eventBus.getUserList, UserListSlice.eventBus.addUserList])

  const isLoading = React.useMemo(() => getUserList.isWorking || !getUserList.hasBeenTriggered, [getUserList.isWorking, getUserList.hasBeenTriggered])
  const isFetching = React.useMemo(() => addUserList.isWorking, [addUserList.isWorking])

  React.useEffect(() => {
    userPoolId && getUserList.trigger({ userPoolId, filter, addProfile: true })
  }, [getUserList, userPoolId, filter])

  React.useEffect(() => () => dispatch(UserListSlice.actions.resetUserList()), [dispatch])

  const onFetchMoreUsers = () => addUserList.trigger({ userPoolId, lastKey, filter })
  return { userList, total, isLoading, isFetching, lastKey, onFetchMoreUsers }
}
