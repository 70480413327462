import { ActionButton } from '@emerald-works-nova/components'
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from './style'
import templateImg from './template-img.png'

const TemplateCard = React.memo(({
  template = {},
  onTemplateSelect = () => {}
}) => {
  const classes = useStyles()
  const { name, parent } = template

  const handleClick = React.useCallback(() => onTemplateSelect(template), [onTemplateSelect, template])

  return (
    <Grid container direction='column' className={classes.root}>
      <img className={classes.templateImg} src={templateImg} />
      <Grid container direction='column' className={classes.description}>
        <Typography variant='h6'>{name}</Typography>
        <Typography variant='caption' color='textSecondary'>From <span className={classes.fromTxt}>{parent?.name || 'Catalogue'}</span></Typography>
      </Grid>
      <Grid className={classes.ctnBtns} container direction='row' justifyContent='flex-start'>
        <ActionButton className={classes.viewBtn} variant='primary' dataTest='create-tenant-save-button'>
          View
        </ActionButton>
        <ActionButton
          variant='primary'
          dataTest='create-tenant-save-button'
          onClick={handleClick}
        >
          Use it
        </ActionButton>
      </Grid>
    </Grid>
  )
})

export { TemplateCard }
