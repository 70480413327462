import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  textOption: {
    fontSize: 16,
    color: theme.palette.text.secondary
  },
  createText: {
    color: '#0070BD',
    fontSize: 16,
    marginLeft: theme.spacing(1)
  },
  createOptionInput: {
    padding: theme.spacing(0.25, 1, 0.25, 1),
    marginLeft: theme.spacing(1),
    border: '1px solid #0070BD',
    borderRadius: theme.spacing(2)
  },
  chipRoot: {
    background: 'transparent',
    border: '1px solid rgba(0, 0, 0, 0.6)'
  },
  option: {
    '&[aria-selected="true"]': {
      '&:not([data-focus="true"])': {
        background: theme.palette.white.main
      }
    }
  }
}))
