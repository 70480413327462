import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonGroup, IconButton as MuiIconButton, Popover } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  group: {
    // Button divider
    '& .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child)': {
      position: 'relative',
      '& :after': {
        content: '""',
        position: 'absolute',
        margin: 'auto',
        top: 0,
        bottom: 0,
        right: 0,
        height: '50%',
        borderRight: `1px solid ${palette.divider}`
      }
    }
  }
}))

const IconButton = (props) => {
  const safeProps = { ...props }
  delete safeProps.disableElevation
  delete safeProps.fullWidth
  return <MuiIconButton {...safeProps} />
}

const LogoEditMenu = React.memo(props => {
  const { anchorEl, onEdit, onDelete, onClose } = props
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const handleEdit = () => {
    onEdit()
    onClose()
  }
  const handleDelete = () => {
    onDelete()
    onClose()
  }
  return (
    <Popover
      id={open ? 'logo-edit-popover' : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ButtonGroup aria-label='edit logo menu' className={classes.group}>
        {onEdit &&
          <IconButton aria-label='upload new logo' color='inherit' onClick={handleEdit}>
            <Edit />
          </IconButton>}
        <IconButton aria-label='upload new logo' onClick={handleDelete}>
          <Delete color='error' />
        </IconButton>
      </ButtonGroup>
    </Popover>
  )
})
export default LogoEditMenu
