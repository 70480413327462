import React from 'react'
import { useStyles } from './style'

const FooterToolbar = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      © 2020 Emerald Works Limited  |  Mind Tools
    </div>
  )
}

export default FooterToolbar
