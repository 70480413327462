import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  loadingCtn: { minHeight: 500 },
  paperWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: 'close-quote',
      position: 'absolute',
      borderRadius: spacing(0, 2, 2, 0),
      width: spacing(0.75),
      height: '80%',
      backgroundColor: ({ enabled, pendingConfirmation }) => {
        if (pendingConfirmation) return palette.error.main
        return enabled ? palette.primary.light : palette.grey.dark
      }
    }
  },
  backdrop: {
    zIndex: 1,
    backgroundColor: palette.backdrop.main
  },
  loadingMsg: {
    marginTop: spacing(4),
    fontSize: spacing(2)
  }
}))
