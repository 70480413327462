import { useEvent } from '@emerald-works/react-event-bus-client'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserListSlice } from '../../../../reducers'

export const useArkUserListPending = ({ tenantId, userPoolId, role }) => {
  const dispatch = useDispatch()

  const userList = useSelector(UserListSlice.selectors.selectUserList)
  const lastKey = useSelector(UserListSlice.selectors.selectLastKey)
  const total = useSelector(UserListSlice.selectors.selectTotal)

  const [getUsersPendingRole, addUserPendingRole] = useEvent([UserListSlice.eventBus.getUsersPendingRole, UserListSlice.eventBus.addUserPendingRole])

  const isLoading = React.useMemo(() => !getUsersPendingRole.hasBeenTriggered || getUsersPendingRole.isWorking, [getUsersPendingRole.isWorking, getUsersPendingRole.hasBeenTriggered])
  const isFetching = React.useMemo(() => addUserPendingRole.isWorking, [addUserPendingRole.isWorking])

  React.useEffect(() => {
    userPoolId && getUsersPendingRole.trigger({ tenantId, userPoolId, role, addProfile: true })
  }, [getUsersPendingRole, tenantId, userPoolId, role])

  React.useEffect(() => () => dispatch(UserListSlice.actions.resetUserList()), [dispatch])

  const onFetchMoreUsers = () => addUserPendingRole.trigger({ tenantId, userPoolId, lastKey, role })
  return { userList, total, isLoading, isFetching, lastKey, onFetchMoreUsers }
}
