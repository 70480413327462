import React from 'react'
import classNames from 'classnames'
import { Grid } from '@material-ui/core'
import { useStyles } from './style'

const SectionHeader = ({ title, location, statusDetail }, forwardRef) => {
  const classes = useStyles()

  return (
    <div className={classes.root} role='textbox' aria-label='Content Editor' ref={forwardRef}>
      {location && (
        <h6 className={classes.location}>
          {location}
        </h6>
      )}
      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <h3 className={classNames(classes.title)} aria-label={`content title, ${title}`}>
            {title}
          </h3>
        </Grid>
        <Grid item>
          {statusDetail}
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(React.forwardRef(SectionHeader))
