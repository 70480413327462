import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    color: ({ change }) =>
      change >= 0 ? palette.success.main : palette.error.main
  }
}))

const Percentage = props => {
  const { change, count } = props
  const classes = useStyles(props)
  const prev = count - change
  const percentage =
    prev !== 0
      ? Math.round(Math.abs(change / prev) * 100)
      : change === 0
        ? 0
        : 100

  return (
    <span className={classes.root}>
      {(change >= 0 ? '↑' : '↓') + ` ${percentage}%`}
    </span>
  )
}

export default Percentage
