import { useEvent } from '@emerald-works/react-event-bus-client'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserListSlice } from '../../../../reducers'

const useUserList = ({ userPoolId, filter }) => {
  const dispatch = useDispatch()

  const userList = useSelector(UserListSlice.selectors.selectUserList)
  const lastKey = useSelector(UserListSlice.selectors.selectLastKey)
  const total = useSelector(UserListSlice.selectors.selectTotal)

  const [getUserList, addUserList] = useEvent([
    UserListSlice.eventBus.getUserList,
    UserListSlice.eventBus.addUserList
  ])

  const isLoading = React.useMemo(() => {
    return getUserList.isWorking || !getUserList.hasBeenTriggered
  }, [getUserList.isWorking, getUserList.hasBeenTriggered])

  React.useEffect(() => {
    if (userPoolId) {
      getUserList.trigger({ userPoolId, filter })
    }
  }, [getUserList, userPoolId, filter])

  React.useEffect(
    () => () => dispatch(UserListSlice.actions.resetUserList()),
    [dispatch]
  )

  const onFetchMoreUsers = () => {
    addUserList.trigger({ userPoolId, lastKey, filter })
  }
  return { userList, total, isLoading, lastKey, onFetchMoreUsers }
}

export default useUserList
