import { Box, CircularProgress, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import React from 'react'
import { useStyles } from '../style'
import { useStyles as usePasswordTabStyles } from './style'
import { UserListSlice } from '../../../reducers'
import { Info } from '../components'
import { ActionButton } from '@emerald-works-nova/components'
import { useEvent } from '@emerald-works/react-event-bus-client'
import useConsumerTenant from '../../consumer-list/useConsumerTenant'
import { useExternalApp } from '../../../hooks'

const status = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  BASE: 'BASE'
}

const PasswordTab = () => {
  const classes = useStyles()
  const passwordClasses = usePasswordTabStyles()
  const userPoolId = useConsumerTenant()
  const { baseToolkitUrl } = useExternalApp({ excludeProtocol: true })
  const { username } = useSelector(UserListSlice.selectors.selectUser)

  const [emailStatus, setEmailStatus] = React.useState(status.BASE)

  const [resetPassword] = useEvent([
    {
      eventName: 'triggerPasswordReset',
      onSuccess: () => setEmailStatus(status.SUCCESS),
      onError: (e) => {
        console.error('The email could not be sent to the user.', e)
        setEmailStatus(status.ERROR)
      }
    }
  ])

  const handlePasswordResetEmail = async () => {
    resetPassword.trigger({
      username,
      userPoolId,
      verifyUrl: baseToolkitUrl
    })
  }

  return (
    <Grid
      container
      spacing={4}
      direction='row'
      wrap='nowrap'
      className={classes.tabPanel}
    >
      <Grid item>
        <Info title='Password reset email' xs={12}>
          Send to users the email with instructions to reset their password.
          <br />
          {emailStatus === status.SUCCESS && (
            <Box component='span' color='success.main' data-test='email-success'>
              The email has been sent.
            </Box>
          )}
          {emailStatus === status.ERROR && (
            <Box component='span' color='error.main' data-test='email-error'>
              The email could not be sent to the user.
            </Box>
          )}
          {emailStatus === status.BASE && <br />}
        </Info>

        <ActionButton
          onClick={handlePasswordResetEmail}
          variant='secondary'
          disabled={resetPassword.isWorking}
          className={passwordClasses.button}
          dataTest='reset-password-send-email-button'
        >
          {resetPassword.isWorking ? (
            <CircularProgress size={25} color='primary' />
          ) : (
            'Send email'
          )}
        </ActionButton>
      </Grid>
    </Grid>
  )
}

export default PasswordTab
