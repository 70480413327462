import React from 'react'
import { MtLogo } from './logo'
import { Typography, LinearProgress, withStyles } from '@material-ui/core'

import { useStyles } from './style'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#E45C0F'
  }
}))(LinearProgress)

const AppToolbar = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.loading_wrapper}>
        <MtLogo width={236} height={59} className={classes.logo} />
        <Typography
          variant='h5'
          align='center'
          aria-level='1'
          className={classes.title1}
        >
          The Ark
        </Typography>

        <Typography variant='body2' align='center' className={classes.title2}>
          Loading your website experience
        </Typography>
        <BorderLinearProgress />
      </div>
    </div>
  )
}

export default AppToolbar
