import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {},
  gridHeader: {
    height: 60,
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: spacing(3)
  },
  tablePaper: {
    width: '100%'
  },
  tableHead: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.25
  },
  dateUpdatedHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginRight: spacing(1)
    }
  },
  loadMoreBtn: {
    margin: spacing(5, 0)
  },
  loadingAnimation: {
    margin: spacing(5, 0),
    height: '50vh'
  },
  emptyList: {
    minHeight: spacing(30),
    color: palette.text.secondary
  },
  filtersCtn: {
    marginTop: spacing(2)
  }
}))
