
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import React from 'react'
import useUserList from '../tenant-edit/users-tab/hooks/useUserList'
import { useStyles } from './style'
import ConsumerItem from './consumer-item'
import {
  FiltersContainer as Filters,
  SearchFilter
} from '../../components/filters'
import { debounce } from 'lodash'
import { generatePath, useNavigate } from 'react-router-dom'
import { routes } from '../../routes'
import useConsumerTenant from './useConsumerTenant'

const initialFilters = {
  search: ''
}

const formatEmailSearch = (term) => term ? 'email ^= "' + term + '"' : ''

const ConsumerList = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [filters, setFilters] = React.useState(initialFilters)
  const [searchTerm, setSearchTerm] = React.useState(formatEmailSearch())

  const userPoolId = useConsumerTenant()

  const {
    userList,
    total,
    isLoading,
    onFetchMoreUsers,
    lastKey
  } = useUserList({ userPoolId, filter: searchTerm })

  const handleClearFilters = React.useCallback(() => setFilters(initialFilters), [
    setFilters
  ])

  const debouncedSearch = React.useMemo(
    () =>
      debounce(term => {
        setSearchTerm(formatEmailSearch(term))
      }, 1000),
    [setSearchTerm]
  )

  React.useEffect(() => {
    debouncedSearch(filters.search)
  }, [debouncedSearch, filters.search])

  const handleClick = user => {
    navigate(
      generatePath(routes.consumerEdit.path, { id: user.username })
    )
  }

  const handleKeyPress = (e, user) => e.which === 13 && handleClick(user)

  return (
    <>
      <Grid container classes={{ root: classes.header }}>
        <Grid container direction='row' alignItems='center'>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography id='tenant-list-title' tabIndex={0} component='div' variant='h5'>
              Consumers
              <Typography
                component='span'
                variant='subtitle1'
                className={classes.userTotal}
              >
                {`  (${searchTerm ? userList.length : total})  `}
              </Typography>
            </Typography>
          </Grid>
          <Filters filters={filters} onChange={setFilters} onClear={handleClearFilters}>
            <SearchFilter name='search' label='Search (email)' />
          </Filters>
        </Grid>
      </Grid>
      <Grid
        id='user-list'
        classes={{ root: classes.tablePaper }}
        tabIndex={0}
        aria-label='Content List'
        elevation={0}
      >
        <Grid
          id='user-list-headers'
          container
          tabIndex={0}
          direction='row'
          alignItems='center'
          className={classes.gridHeader}
          classes={{ root: classes.tableHead }}
          aria-label='List headers, Title, Type, Last update'
        >
          <Grid item md={3} style={{ paddingLeft: 16 }}>User</Grid>
          <Grid item md={2}>Subscription</Grid>
          <Grid item md={2}>Role</Grid>
          <Grid item md={2}>Last Access</Grid>
          <Grid item md>Status</Grid>
        </Grid>
        {!isLoading && userList.length > 0 && userList.map((row, key) => (
          <ConsumerItem
            key={key}
            index={key}
            user={row}
            classes={classes}
            userPoolId={userPoolId}
            lastOne={key === userList.length - 1}
            onClick={handleClick}
            onKeyPress={handleKeyPress}
          />
        ))}
        {isLoading && userPoolId && (
          <Grid
            id='user-list-loading'
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            classes={{ root: classes.loadingAnimation }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
        {!isLoading && !userList.length && (
          <Grid
            id='user-list-empty'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.emptyList }}
          >
            <Typography variant='h5'> No users found! </Typography>
          </Grid>
        )}
      </Grid>

      {!isLoading && lastKey && (
        <Grid
          id='users-list-load-more'
          container
          direction='row'
          justifyContent='center'
          align='center'
          classes={{ root: classes.loadMoreBtn }}
        >
          <Grid item xs={12}>
            <Button variant='contained' color='primary' data-test='load-more-button' onClick={onFetchMoreUsers}>
                Load More
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ConsumerList
