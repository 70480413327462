import React, { useState, useMemo, useEffect } from 'react'
import { Box, Button, CircularProgress, Grid, Paper, Switch, Typography } from '@material-ui/core'
import { useStyles } from './style'
import PromotedItemsModal from './promoted-items-modal'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { ArrowUpward, Star, Settings } from '@material-ui/icons'
import CatalogItem from '../../pages/content/catalog-item'
import { useTemplateFormContext } from '../../pages/template-edit/template-form-context'
import { BannerStatic } from '..'

const TemplatePromotedContent = ({ template }) => {
  const classes = useStyles()
  const [isPromotedModalOpen, setPromotedModalOpen] = useState(false)
  const [promotedContent, setPromotedContent] = useState([])
  const selectedPromotedContent = useMemo(() => promotedContent.map(c => c.content), [promotedContent])
  const { toggleTemplatePromotedContent } = useTemplateFormContext()

  const [getPromotedContent] = useEvent([{
    eventName: 'promotedContentGetPromotedContent',
    onSuccess: (payload) => {
      setPromotedContent(payload.promotedContent)
    },
    onError: (err) => {
      console.log('Error -> ', err)
      BannerStatic.show({ label: 'Error fetching promoted content', isError: true })
    }
  }])

  const handleToggleTemplatePromotedContent = () => {
    toggleTemplatePromotedContent.trigger({
      sk: template.sk,
      isPromotedContentEnabled: !template.isPromotedContentEnabled
    })
  }

  useEventsOnViewLoad(() => {
    getPromotedContent.trigger({
      template,
      verifyFeatureToggle: false
    })
  }, [getPromotedContent])

  useEffect(() => {
    const promotedContentToggled = template.isPromotedContentEnabled && toggleTemplatePromotedContent.hasBeenTriggered

    if (promotedContentToggled && !promotedContent.length) {
      setPromotedModalOpen(true)
    }
  }, [template.isPromotedContentEnabled, toggleTemplatePromotedContent.hasBeenTriggered, promotedContent.length])

  const handleClosePromotedModal = () => setPromotedModalOpen(false)

  const handleCloseOkPromotedModal = () => {
    setPromotedModalOpen(false)
    getPromotedContent.trigger({
      template,
      verifyFeatureToggle: false
    })
  }
  const handleOpenPromotedModal = () => setPromotedModalOpen(true)

  if (!template || getPromotedContent.isWorking) {
    return (
      <Grid container className={classes.root} justifyContent='center' alignItems='center'>
        <CircularProgress size={60} />
      </Grid>
    )
  }

  return (
    <>
      <PromotedItemsModal
        open={isPromotedModalOpen}
        onClose={handleClosePromotedModal}
        onCloseOk={handleCloseOkPromotedModal}
        selected={selectedPromotedContent}
        template={template}
      />
      <div className={classes.root}>
        <Grid container className={classes.titleCtn}>
          <Grid item xs={10}>
            <Typography variant='subtitle1'>Promoted content</Typography>
          </Grid>
        </Grid>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Typography variant='body2' style={{ display: 'flex', flex: 1 }}>Select 5 pieces of content to be highlighted on the homepage</Typography>
          <Switch
            checked={template.isPromotedContentEnabled}
            disabled={toggleTemplatePromotedContent.isWorking}
            color='primary'
            onChange={() => {
              handleToggleTemplatePromotedContent()
            }}
          />
        </Box>
        {template.isPromotedContentEnabled && (
          <>
            <Box display='flex' mt={2} mb={2} flex={1} justifyContent='end' onClick={handleOpenPromotedModal}>
              <Button variant='outlined' color='primary' startIcon={<Settings />}>Manage</Button>
            </Box>
            <Box>
              <Grid container className={classes.selectedContentRoot}>
                <Paper
                  id='catalog-list'
                  classes={{ root: classes.tablePaper }}
                  tabIndex={0}
                  aria-label='Content List'
                  elevation={1}
                >
                  <Grid
                    id='catalog-list-headers'
                    container
                    tabIndex={0}
                    direction='row'
                    alignItems='center'
                    className={classes.gridHeader}
                    classes={{ root: classes.tableHead }}
                    aria-label='List headers, Title, Type, Last update'
                  >
                    <Grid item md={8}>Title</Grid>
                    <Grid item md={2}>Type</Grid>
                    <Grid item md={2} className={classes.dateUpdatedHeader}>
                      <ArrowUpward fontSize='small' />
                      <span>Last update</span>
                    </Grid>
                  </Grid>
                  {promotedContent.map((row, key) => {
                    const nameAdornment = key === 0 && (
                      <>
                        <Star fontSize='small' className={classes.mainPromotedIcon} />
                        <span className={classes.mainPromotedText}>Main Promoted Card</span>
                      </>
                    )
                    return (
                      <CatalogItem
                        key={key}
                        index={key}
                        onClick={() => { }}
                        article={row.content}
                        classes={classes}
                        nameAdornment={nameAdornment}
                        lastOne={key === promotedContent.length - 1}
                        name={row.sk}
                      />
                    )
                  })}
                </Paper>
              </Grid>
            </Box>
          </>
        )}
      </div>
    </>
  )
}

export default TemplatePromotedContent
