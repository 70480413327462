import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  filtersGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
    // marginRight: spacing(3)
  }
}))
