import { ActionButton, FormInputRow, ImageUtil, Input } from '@emerald-works-nova/components'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import classNames from 'classnames'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { BannerStatic } from '../../../components'
import { CopyIcon } from '../../../components/icons'
import { TenantEditSlice } from '../../../reducers'
import { readFileText } from '../../../util/file-util'
import { InputColor, InputLogo } from '../components'
import { SamlAttributeInput } from '../components/saml-atttribute-input'
import { useTenantFormContext } from '../tenant-form-context'
import { useStyles } from './style'

const SamlTab = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { tenant, setIsLoading, setLoadingMsg, mainClasses } = useTenantFormContext()
  const form = useForm({ mode: 'onChange' })
  const { getValues, setValue, formState: { errors }, watch } = form
  const [showAccordionAttrMap, setShowAccordionAttrMap] = React.useState(false)

  const { loginProviders, region, cognitoSettings: { userPoolId, userPoolDomain }, pk } = tenant
  const acsUrl = React.useMemo(() => `https://${userPoolDomain}.auth.${region}.amazoncognito.com/saml2/idpresponse`, [userPoolDomain, region])
  const entityId = React.useMemo(() => `urn:amazon:cognito:sp:${userPoolId}`, [userPoolId])
  const textToCopy = React.useMemo(() => `${acsUrl}\n${entityId}`, [acsUrl, entityId])
  const provider = React.useMemo(() => loginProviders?.find(p => p.providerType === 'SAML'), [loginProviders])
  const [radioHasSaml, setRadioHasSaml] = React.useState(provider?.samlMetadata ? 'yes' : 'no')

  const [tenantUpdateSso] = useEvent([{
    eventName: 'tenantUpdateSso',
    onSuccess: (tenant) => {
      setIsLoading(false)
      BannerStatic.show({ label: 'SSO configuration updated' })
      dispatch(TenantEditSlice.actions.updateTenantLoginProviders(tenant.loginProviders))
    }
  }])
  useEventsOnViewLoad(() => {
    tenantUpdateSso.registerAdhocOnErrorListener((...args) => {
      console.log('tenantUpdateSso.registerAdhocOnErrorListener', args)
      setIsLoading(false)
      BannerStatic.show({ label: 'There was an error saving the toolkit SSO configuration', isError: true })
    })
  }, [tenantUpdateSso, setIsLoading])

  // initialize the form fields
  React.useEffect(() => {
    if (!provider) return
    Object.entries(provider).forEach(([key, value]) => setValue(key, value))
  }, [provider, setValue])

  const onCopy = React.useCallback(() => {
    BannerStatic.show({
      label: 'Content copied',
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
    })
  }, [])
  const handleSave = React.useCallback(() => {
    tenantUpdateSso.trigger({ pk, provider: getValues() })
    setLoadingMsg('Updating SSO configuration')
  }, [getValues, tenantUpdateSso, setLoadingMsg, pk])

  React.useEffect(() => { setIsLoading(tenantUpdateSso.isWorking) }, [setIsLoading, tenantUpdateSso.isWorking])

  const fileInputRef = React.useRef()
  const onChooseFile = React.useCallback(async (file) => {
    const content = await readFileText(file)
    setValue('samlMetadata', content)
    if (fileInputRef.current) fileInputRef.current.value = ''
  }, [setValue, fileInputRef])

  const providerLogo = watch('providerLogo')
  const providerButtonLabel = watch('buttonLabel')
  const providerLogoAlt = watch('logoAlt', '')
  const providerName = watch('name', 'SSO')
  const buttonColor = watch('buttonColor')

  const getIcon = (providerLogo) => {
    if (!providerLogo) return null
    return (
      <img
        className={classes.providerLogo}
        src={ImageUtil.ImgixGenerateImageUrl(providerLogo.s3Url, providerLogo.imgixCropParams)}
      />
    )
  }

  return (
    <Grid className={classes.root}>
      <Grid container alignItems='center' className={classNames(mainClasses.ctnTitle, classes.ctnTitle)}>
        <Grid item xs={8}>
          <Typography variant='h6'>Single Sign On</Typography>
        </Grid>
        <Grid container item justifyContent='flex-end' xs={4}>
          {radioHasSaml === 'yes' && (
            <ActionButton
              variant='primary'
              dataTest='button-save-tenant-sso'
              onClick={handleSave}
            > Save Changes
            </ActionButton>
          )}
        </Grid>
      </Grid>
      <Typography gutterBottom variant='body1'><strong>Configuration</strong></Typography>
      <Typography gutterBottom color='textSecondary' variant='body1'>Copy our live metadata information below to share with the client.</Typography>
      <Grid className={classes.configCtn}>
        <Grid container justifyContent='flex-end'>
          <CopyToClipboard text={textToCopy} onCopy={onCopy}>
            <Button
              variant='outlined'
              data-test='copy-acs-entity-button'
              className={classes.btnCopy}
              endIcon={<CopyIcon />}
            > Copy Snippet
            </Button>
          </CopyToClipboard>
        </Grid>
        <Grid>
          <Typography color='textSecondary' variant='body1'>{acsUrl}</Typography>
          <Typography color='textSecondary' variant='body1'>{entityId}</Typography>
        </Grid>
      </Grid>

      <Divider style={{ marginBottom: 16 }} />

      <Typography gutterBottom variant='body1'><strong>Do you have the client's metadata to import?</strong></Typography>

      <RadioGroup aria-label='Has SAML metadata file?' name='hasSaml' value={radioHasSaml} onChange={({ target }) => setRadioHasSaml(target.value)}>
        <Grid container>
          <Grid item xs={6}>
            <FormControlLabel value='no' control={<Radio color='primary' />} label="No, I don't have SAML Metadata file/content yet" />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel value='yes' control={<Radio color='primary' />} label='Yes, I have SAML Metadata file/content' />
          </Grid>
        </Grid>
      </RadioGroup>
      <br />

      {radioHasSaml === 'yes' && (
        <Grid container>
          <Typography variant='h6'><strong>SSO Settings</strong></Typography>
          <Grid item xs={12}>
            <FormInputRow
              title='Provider Name'
              smallFormTitles
              description='This is the name of the identity provider. It will be shown to your users in the login button e.g (Azure, Microsoft, Google, AD)'
              InputComponent={
                <Input
                  autoFocus
                  rules={{ required: true }}
                  label='Provider Name'
                  name='name'
                  control={form.control}
                  valid={errors.providerName === undefined}
                  error='Provider Name is required'
                  showErrors
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputRow
              title='SAML Metadata'
              smallFormTitles
              description='This is the metada configuration file that your identity provider will give you.'
              InputComponent={(
                <>
                  <Input
                    label='SAML Metadata'
                    name='samlMetadata'
                    control={form.control}
                    valid={errors.samlMetadata === undefined}
                    error='SAML Metadata is required'
                    showErrors
                    multiline
                    minRows={4}
                    maxRows={10}
                  />
                  <Button className={classes.btnUploadSamlFile} variant='contained' color='primary' component='label'>
                    Paste from file
                    <input ref={fileInputRef} type='file' onChange={({ target }) => onChooseFile(target.files[0])} hidden />
                  </Button>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Accordion
              elevation={0}
              classes={{ root: classes.attrAccordion }}
              expanded={showAccordionAttrMap}
              onChange={() => setShowAccordionAttrMap(!showAccordionAttrMap)}
            >
              <AccordionSummary
                classes={{ root: classes.attrAccordionSummary, content: classes.attrAccordionSummaryContent }}
                expandIcon={<ExpandMore />}
                aria-controls='panel-basic'
                id='panel-basic-header'
              >
                <Typography variant='body1' data-test='basic-info'>Attribute Mapping</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.attrAccordionDetail }}>
                <Grid container>
                  <Typography variant='caption'><strong>Map attributes between your provider and your user pool.</strong></Typography>
                  <Typography variant='caption'>Your required attributes are mapped to the equivalent attributes. Each attribute you add must be mapped to a attribute.</Typography>
                  <SamlAttributeInput provider={provider} setValue={setValue} />
                </Grid>
              </AccordionDetails>
            </Accordion>

          </Grid>
          <Typography variant='h6'><strong>Customize Login Button (Optional)</strong></Typography>
          <Grid item xs={12}>
            <FormInputRow
              title='Login Button Label'
              smallFormTitles
              description='This is the text that will appear on the login button before the SSO provider name. For example, "Log in with Google".'
              InputComponent={<Input label='Button Label' name='buttonLabel' control={form.control} />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputRow
              title='Login Button Logo'
              smallFormTitles
              description='The provider logo to be uploaded and displayed on the SSO button.'
              InputComponent={(
                <InputLogo
                  name='providerLogo'
                  minWidth={30}
                  minHeight={30}
                  maxSize={1048576}
                  {...form}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputRow
              title='Button Colour Style'
              smallFormTitles
              description='This is style of the colour button. Selecting coloured, the colour used will be that of the branding.'
              InputComponent={
                <InputColor
                  {...form}
                  errors={errors}
                  name='buttonColor'
                  defaultValue={tenant?.mainColor || '#E0E0E0'}
                />
              }
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormInputRow
              title='Logo Alt'
              description="The logo's Alt attribute."
              InputComponent={
                <Input
                  label='Logo Alt'
                  name='logoAlt'
                  control={form.control}
                />
              }
            />
          </Grid> */}
          <Grid item container justifyContent='center' xs={12}>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.titlePreview}>Login Button Preview</Typography>
            </Grid>
            <Grid container direction='row' alignItems='flex-start'>
              <Grid item className={classes.descriptionWrapper}>
                <Typography variant='caption' color='textSecondary'>Check the button preview to the side.</Typography>
              </Grid>
              <Grid item container justifyContent='center' className={classes.inputWrapper}>
                <Button
                  variant='contained'
                  classes={{ label: classes.providerLbl }}
                  startIcon={getIcon(providerLogo)}
                  style={{ background: buttonColor }}
                  alt={providerLogoAlt}
                >
                  {`${providerButtonLabel || 'Sign in with'} ${providerName}`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default SamlTab
