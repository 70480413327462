import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  title: {
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: 400,
    margin: 0
  },
  location: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 400,
    margin: 0
  }
}))
