import * as actions from './slice'

const getEnvInfo = {
  eventName: 'coreGetEnvInfo',
  onSuccess: {
    action: actions.getEnvInfo,
    redux: true
  }
}

export {
  getEnvInfo
}
