import React, { useCallback } from 'react'
import { NovaEditor } from '@emerald-works-nova/editor'
import { Controller } from 'react-hook-form'
import { useContentForm } from '../../content-form-context'
import { useSelector } from 'react-redux'
import ContentSlice from '../../../../reducers/content'
import { useStyles } from './style'
import { usePromiseEvent } from '../../../../hooks'

const Body = () => {
  const classes = useStyles()
  const { form, isReadOnly } = useContentForm()
  const content = useSelector(ContentSlice.selectors.selectContent)

  const deleteTranscript = usePromiseEvent('contentDeleteTranscript')
  const deleteImage = usePromiseEvent('contentDeleteImage')

  const searchContent = usePromiseEvent('catalogSearch')

  const handleSearchContent = (search) => {
    return searchContent({
      size: 10,
      filters: {
        search
      }
    })
  }

  const readTime = form.getValues().readTime
  const updateReadTime = (newTime) => {
    form.setValue('readTime', newTime)
  }

  const onDeleteTranscript = useCallback(async (transcriptKey) => {
    await deleteTranscript({ s3Key: transcriptKey })
  }, [deleteTranscript])

  const onDeleteImage = useCallback(async (imageKey) => {
    await deleteImage({ s3Key: imageKey })
  }, [deleteImage])

  return (
    <div className={classes.root}>
      <Controller
        name='body'
        control={form.control}
        defaultValue={null}
        render={(
          { field: { onChange, value } }
        ) => (
          <NovaEditor
            endUserView={isReadOnly}
            body={value}
            type={content.primaryType[0]}
            onChange={onChange}
            onDeleteTranscript={onDeleteTranscript}
            onDeleteImage={onDeleteImage}
            handleSearchContent={handleSearchContent}
            readTime={readTime}
            updateReadTime={updateReadTime}
          />
        )}
      />
    </div>
  )
}

export default Body
