import React from 'react'
import { Paper } from '@material-ui/core'
import { useStyles } from './style'

const Panel = ({
  maxWidth,
  padding,
  margin,
  className,
  style,
  children,
  ...rest
}) => {
  const classes = useStyles({
    maxWidth,
    padding,
    margin
  })
  return (
    <Paper
      {...rest}
      elevation={0}
      style={style}
      className={className}
      classes={{
        root: classes.root
      }}
    >
      {children}
    </Paper>
  )
}

export default Panel
