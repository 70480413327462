import React from 'react'
import { ApplicationAuth, useTenant } from '@emerald-works-nova/auth'
import { EventBusProvider } from '@emerald-works/react-event-bus-client'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import './app.css'
import { AppLoading, BannerStatic } from './components'
import { EventContextProvider } from './hooks/event'
import Routes, { routes } from './routes'
import { createTheme } from './theme'

const App = () => {
  const tenant = useTenant()
  return (
    <ApplicationAuth
      loginRoute={routes.login.path}
      LoadingComponent={AppLoading}
      defaultTenantKey='admin'
      tenant={tenant}
    >
      <EventBusProvider
        eventBusURL={process.env.REACT_APP_EVENT_BUS_URL}
        connectionParams={{ tenantKey: tenant?.tenantKey }}
        useAuthProvider
        fetchUserProfile
        namespace='ark'
        waitForConnection
        onConnectionChange={(status) => console.log('Connection onConnectionChange hook with status:', status)}
        onOpen={() => console.log('Connection onOpen hook')}
        onClose={() => console.log('Connection onClose hook')}
      >
        <EventContextProvider>
          <ThemeProvider theme={createTheme()}>
            <CssBaseline />
            <BannerStatic />
            <Routes />
          </ThemeProvider>
        </EventContextProvider>
      </EventBusProvider>
    </ApplicationAuth>
  )
}

export default App
