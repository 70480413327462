import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(0, 3, 3),
    '& .MuiGrid-item': {
      '& .MuiGrid-container:nth-child(2)': {
        // this is not ideal
        gap: spacing(3)
      }
    }
  }
}))
