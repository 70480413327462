import { Grid, Divider, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import React from 'react'
import { useStyles } from '../style'
import { UserListSlice } from '../../../reducers'
import { Info, Input } from '../components'
import { ActionButton } from '@emerald-works-nova/components'
import { capitalize } from 'lodash'
import RoleInput from './role-input'
import useDetailForm from './useDetailForm'
import useConsumerTenant from '../../consumer-list/useConsumerTenant'
import ConsumerToggle from '../../../components/toggle-user'

const formatDate = date => new Date(date).toLocaleDateString()

const DetailTab = () => {
  const classes = useStyles()

  const user = useSelector(UserListSlice.selectors.selectUser)
  const userPoolId = useConsumerTenant()

  const {
    givenName,
    familyName,
    roles,
    username,
    email,
    createdAt,
    lastAccess
  } = user ?? {}

  const {
    isEdit,
    control,
    formState: { isDirty },
    onSubmit,
    handleSubmit,
    handleEdit,
    handleCancelEdit,
    loading
  } = useDetailForm()

  return (
    <form>
      <Grid
        container
        direction='column'
        spacing={3}
        className={classes.tabPanel}
      >
        <Grid container item>
          <Info title='First Name'>
            {isEdit ? (
              <Input name='givenName' control={control} />
            ) : (
              givenName
            )}
          </Info>
          <Info title='Last Name'>
            {isEdit ? (
              <Input name='familyName' control={control} />
            ) : (
              familyName
            )}
          </Info>
          <Info title='Role'>
            {isEdit ? (
              <RoleInput control={control} />
            ) : (
              capitalize(roles?.[0] ?? 'No role')
            )}
          </Info>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item>
          <Info title='Member ID'>{username}</Info>
          <Info title='Email Address'>
            {isEdit ? (
              <Input name='email' control={control} />
            ) : (
              email
            )}
          </Info>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item>
          <Info title='Creation Date'>{formatDate(createdAt)}</Info>
          <Info title='Last Access'>
            {lastAccess ? formatDate(lastAccess) : "Hasn't signed in"}
          </Info>
          <Info title='User Status'>
            <Box mt={-1.5}>
              <ConsumerToggle user={user} userPoolId={userPoolId} />
            </Box>
          </Info>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container spacing={2} item xs={12}>
          {isEdit ? (
            <>
              <Grid item>
                <ActionButton
                  onClick={handleCancelEdit}
                  variant='secondary'
                  dataTest='cancel-btn'
                >
                  Cancel
                </ActionButton>
              </Grid>
              <Grid item>
                <ActionButton
                  onClick={handleSubmit(onSubmit)}
                  variant='primary'
                  isWorking={loading}
                  disabled={!isDirty}
                  dataTest='save-changes-btn'
                >
                  Save changes
                </ActionButton>
              </Grid>
            </>
          ) : (
            <Grid item>
              <ActionButton
                onClick={handleEdit}
                variant='secondary'
                dataTest='edit-user-details-btn'
              >
                Edit user details
              </ActionButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

export default DetailTab
