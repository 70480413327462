import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    backgroundColor: '#F9FAFB',
    borderRadius: 4,
    padding: spacing(1.5),
    paddingBlock: spacing(0.5),
    listStyleType: 'none',
    width: ({ fullWidth }) => fullWidth ? '100%' : 'fit-content'
  },
  key: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: 5,
    height: 5,
    lineHeight: '1em',
    borderRadius: 5,
    marginRight: spacing(1)

  },
  label: {
    marginBlock: spacing(1),
    color: '#5C5C5C'
  },
  text: {
  }
}))

const Legend = (props) => {
  const { labels, colors } = props
  const classes = useStyles(props)
  return (
    <ul className={classes.root}>
      {labels.map((label, idx) => (
        <li key={label ?? idx}>
          <Typography variant='body2' className={classes.label}>
            {label ? (
              <>
                <span
                  className={classes.key}
                  style={{ backgroundColor: colors[idx] || 'grey' }}
                />
                {label}
              </>
            ) : (
              <Grid component='span' container wrap='nowrap' alignItems='center'>
                <Skeleton variant='circle' className={classes.key} />
                <Skeleton width='70%' />
              </Grid>
            )}
          </Typography>
        </li>
      ))}
    </ul>
  )
}

export default Legend
