import { useTenant } from '@emerald-works-nova/auth'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Backdrop, Box, Button, CircularProgress, Divider, Grid, Paper, useTheme } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { SectionHeader, ToggleUserActive } from '../../components'
import { AllRoles } from '../../helpers/roles/department-data'
import { ArkUserSlice } from '../../reducers'
import { routes } from '../../routes'
import { Info, UserRolesRadio } from './components'
import { useStyles } from './style'

const formatDate = date => new Date(date).toLocaleDateString()

const ArkUserEdit = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { spacing } = useTheme()
  const { id } = useParams()
  // this page will only appear to admins and those users will always be using the `admin` tenant
  const { pk: tenantId, userPoolId } = useTenant()
  const user = useSelector(ArkUserSlice.selectors.selectUser)

  const { givenName, familyName, enabled, username, email, createdAt, lastAccess, profile, roles } = user ?? {}
  const userDept = React.useMemo(() => roles && AllRoles.find(r => r.value === roles[0])?.label, [roles])

  const classes = useStyles({ enabled, pendingConfirmation: !!profile?.preferredRole })

  const [getUser] = useEvent([ArkUserSlice.eventBus.getUser])

  useEventsOnViewLoad(() => {
    getUser.trigger({
      tenantId,
      userPoolId,
      username: id,
      extraAttributes: ['roles', 'lastAccess', 'profile']
    })
  }, [getUser, userPoolId, id])

  React.useEffect(() => () => dispatch(ArkUserSlice.actions.resetUserDetails()), [dispatch])
  const handleCancel = () => navigate(routes.arkUsers.path)
  const isLoading = getUser.isWorking

  if ((id && !user) || isLoading) {
    return (
      <Backdrop id='backdrop-loading' className={classes.backdrop} open={isLoading}>
        <Grid container direction='column' className={classes.loadingCtn} justifyContent='center' alignItems='center'>
          <Grid item>
            <CircularProgress size={100} />
          </Grid>
          <Grid item className={classes.loadingMsg}>
            <strong>Loading user data...</strong>
          </Grid>
        </Grid>
      </Backdrop>
    )
  }

  return (
    <Box position='relative'>
      <Grid container style={{ marginBottom: spacing(3) }}>
        <Grid item xs={9} data-test='title'>
          <SectionHeader title={`${user.givenName} ${user.familyName}`} location='Ark User /' />
        </Grid>
      </Grid>
      <Paper className={classes.paperWrapper}>
        <Box p={4} flex='auto'>
          <Box mb={3}>
            <Button
              color='primary'
              size='small'
              data-test='backtolist-button'
              onClick={handleCancel}
              startIcon={<ArrowBackIcon />}
            >
              <span style={{ textTransform: 'none' }}>Back to User List</span>
            </Button>
          </Box>
          <Box display='flex' mb={4}>
            <Info title='First Name'>{givenName}</Info>
            <Info title='Last Name'>{familyName}</Info>
          </Box>
          <Box display='flex' mb={4}>
            <Info title='Email Address'>{email}</Info>
            <Info title='Username'>{username}</Info>
            <Info title='Department'>{userDept}</Info>
          </Box>
          <Box display='flex' mb={4}>
            <Info title='Creation Date'>{formatDate(createdAt)}</Info>
            <Info title='Last Access'>{lastAccess ? formatDate(lastAccess) : "Hasn't signed in"}</Info>
          </Box>
          <Box mb={4}>
            <Divider />
          </Box>
          <Box display='flex' flexDirection='column' mb={4}>
            <UserRolesRadio />
          </Box>
          <Box mb={4}>
            <Divider />
          </Box>
          <Box display='flex' mb={4}>
            <Info title='User Status'>
              <ToggleUserActive user={user} userPoolId={userPoolId} />
            </Info>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default ArkUserEdit
