import { ActionButton } from '@emerald-works-nova/components'
import {
  Collapse,
  Container,
  Grid, Paper,
  Typography
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import React, { useEffect } from 'react'
import { useStyles } from './style'

const Banner = React.forwardRef(({
  open,
  onClose,
  label,
  dismissLabel = 'Dismiss',
  icon,
  isError,
  autoHide = true,
  autoHideDelay = 5000
}, ref) => {
  const classes = useStyles()

  useEffect(() => {
    if (open && autoHide) {
      const timer = setTimeout(() => {
        onClose()
      }, autoHideDelay)
      return () => clearTimeout(timer)
    }
  }, [autoHide, open, autoHideDelay, onClose])

  return (
    <Collapse in={open} ref={ref}>
      <Paper elevation={0} className={classes.root}>
        <Container>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <div style={{ marginTop: 6 }}>
                    {icon || isError ? <ErrorOutlineOutlinedIcon style={{ color: '#B00020' }} /> : <CheckCircleOutlineIcon />}
                  </div>
                </Grid>
                <Grid item>
                  <Typography data-test='bannermessage' variant='body2' color='textSecondary'>
                    {label}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ActionButton onClick={onClose} variant='secondary' noBorder>
                {dismissLabel}
              </ActionButton>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Collapse>
  )
})

Banner.displayName = 'Banner'

export default Banner
