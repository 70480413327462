import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  filterTypeControl: {
    marginLeft: spacing(3)
  },
  filterTypelabelOutlined: {
    transform: 'translate(14px, 10px) scale(1)'
  },
  filterTypeSelect: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 170,
    minHeight: spacing(4.5),
    paddingTop: spacing(1),
    paddingBottom: spacing(0.5),
    boxSizing: 'border-box',
    '&:focus': {
      backgroundColor: 'transparent'
    }
  }
}))
