import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: props => props.padding !== undefined ? props.padding : 24,
    maxWidth: props => props.maxWidth || '100%',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    margin: props => props.margin || '0 auto'
  }
}))
