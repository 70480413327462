import { useEffect } from 'react'

export default (
  initialValues,
  setValue
) => {
  useEffect(() => {
    Object.entries(initialValues).forEach(([name, value]) => {
      setValue(name, value)
    })
  }, [initialValues, setValue])
}
