import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(0, 3, 3),
    '& .MuiGrid-item': {
      '& .MuiGrid-container:nth-child(2)': {
        gap: spacing(3)
      }
    }
  },
  saveBtn: {
    marginTop: spacing(4)
  },
  popmenu: {
    width: '100%',
    maxWidth: 330
  },
  weeklyEmail: {
    margin: spacing(2, 0)
  }
}))
