import { ActionButton } from '@emerald-works-nova/components'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { CircularProgress, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React from 'react'
import ManageCategoryModal from '../manage-category'
import CategoryItem from './category-item'
import { useStyles } from './style'

const TemplateHierarchy = ({
  viewOnly,
  template,
  setTemplate,
  templateSK,
  onChangeTemplate,
  onAddCategory
}) => {
  const classes = useStyles()

  const [hierarchy, setHierarchy] = React.useState([])
  const [openAll, setOpenAll] = React.useState(false)
  const [openManageCategory, setOpenManageCategory] = React.useState(false)
  const [managedCategory, setManagedCategory] = React.useState()

  const [catGetGroupCatByTemplate, templateGet] = useEvent([
    {
      eventName: 'catGetGroupCatByTemplate',
      onSuccess: ({ categories }) => setHierarchy(categories)
    },
    {
      eventName: 'templateGet',
      onSuccess: ({ template }) => {
        setTemplate(template)
        getGroupCategories(template)
      }
    }
  ])

  const getTemplate = React.useCallback(() => {
    if (!templateSK) throw new Error('The template SK or the template are required parameters')
    templateGet.trigger({ id: templateSK })
  }, [templateGet, templateSK])

  const getGroupCategories = React.useCallback((template) => {
    catGetGroupCatByTemplate.trigger({
      tenantId: template.tenantId,
      templateSK: template.sk
    })
  }, [catGetGroupCatByTemplate])

  useEventsOnViewLoad(() => {
    if (!template) {
      getTemplate()
      return
    }

    if (!catGetGroupCatByTemplate.hasBeenTriggered) {
      catGetGroupCatByTemplate.trigger({
        identityPoolId: template.cognitoIdentityId,
        templateSK: template.sk
      })
    }
  }, [template, getTemplate, catGetGroupCatByTemplate])

  const handleOpenAll = React.useCallback((_, v) => setOpenAll(v), [])

  const onManageCategory = React.useCallback(category => {
    setManagedCategory(category)
    setOpenManageCategory(true)
  }, [setManagedCategory, setOpenManageCategory])

  if (!template || catGetGroupCatByTemplate.isWorking | templateGet.isWorking) {
    return (
      <Grid container className={classes.root} justifyContent='center' alignItems='center'>
        <CircularProgress size={60} />
      </Grid>
    )
  }

  return (
    <>
      <ManageCategoryModal
        open={openManageCategory}
        template={template}
        category={managedCategory}
        onClose={() => setOpenManageCategory(false)}
      />
      <div className={classes.root}>
        <Grid container className={classes.titleCtn}>
          <Grid item xs={10}>
            <Typography variant='subtitle1'>{template.name}</Typography>
          </Grid>
          {!viewOnly && onChangeTemplate && (
            <Grid container item xs={2} justifyContent='flex-end'>
              <ActionButton variant='secundary' dataTest='change-template-button' onClick={onChangeTemplate}>
                Change Template
              </ActionButton>
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.openAllCtn} justifyContent='flex-end'>
          <FormControlLabel
            control={<Switch color='primary' />}
            label='Open all'
            labelPlacement='start'
            data-test='open-all-button'
            onChange={handleOpenAll}
          />
        </Grid>
        <Grid container className={classes.categoriesCtn} direction='row'>
          {hierarchy.map(category => (
            <CategoryItem
              key={category.sk}
              category={category}
              type='group'
              openAll={openAll}
              viewOnly={viewOnly}
              template={template}
              onManageCategory={onManageCategory}
            />
          ))}
          {(onAddCategory && !viewOnly) && (
            <Grid
              item
              container
              className={classes.addCategoryCtn}
              direction='row'
              alignItems='center'
              onClick={onAddCategory}
            >
              <AddCircleOutlineIcon className={classes.addIcon} />
              <Typography variant='body2'>New Group Category</Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}

export default TemplateHierarchy
