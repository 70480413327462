import { ActionButton } from '@emerald-works-nova/components'
import {
  useEvent,
  useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  useTheme
} from '@material-ui/core'
import {
  Assignment,
  CardMembership,
  ListAlt,
  LockOutlined
} from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { BannerStatic, Panel, SectionHeader, TabPanel } from '../../components'
import { UserListSlice } from '../../reducers'
import { routes } from '../../routes'

import useConsumerTenant from '../consumer-list/useConsumerTenant'
import DetailTab from './detail-tab'
import Summary from './summary'
import PasswordTab from './password-tab'
import SubscriptionTab from './subscription-tab'

import { useStyles } from './style'

const SectionNames = {
  details: 'details',
  subscription: 'subscription',
  password: 'password',
  notes: 'notes'
}
const SectionMap = {
  [SectionNames.details]: {
    component: <DetailTab />,
    title: 'Details',
    icon: <Assignment />,
    isVisible: () => true,
    isDisabled: () => false
  },
  [SectionNames.subscription]: {
    component: <SubscriptionTab />,
    title: 'Subscription',
    icon: <CardMembership />,
    // TODO - re-enable when subscription data and functionality is available
    isVisible: () => false,
    isDisabled: () => false
  },
  [SectionNames.password]: {
    component: <PasswordTab />,
    title: 'Password Settings',
    icon: <LockOutlined />,
    isVisible: () => true,
    isDisabled: () => false
  },
  [SectionNames.notes]: {
    component: <div />,
    title: 'Notes',
    icon: <ListAlt />,
    // TODO - re-enable when notes functionality is available
    isVisible: () => false,
    isDisabled: () => false
  }
}

const loadingMsg = 'Loading consumer data...'

const ConsumerEdit = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { spacing } = useTheme()
  const { id } = useParams()
  const userPoolId = useConsumerTenant()

  const [getUser] = useEvent([UserListSlice.eventBus.getUser])
  const user = useSelector(UserListSlice.selectors.selectUser)

  useEventsOnViewLoad(() => {
    if (userPoolId) {
      getUser.trigger({
        userPoolId,
        username: id,
        extraAttributes: ['roles', 'lastAccess']
      })
    }
  }, [getUser, userPoolId, id])

  useEventsOnViewLoad(() => {
    const handleError = e => {
      console.log('getUser.registerAdhocOnErrorListener', e)
      BannerStatic.show({ label: 'Error retriving consumer user', isError: true })
      handleCancel()
    }

    getUser.registerAdhocOnErrorListener(handleError)

    return () => getUser.unregisterAdhocListener(handleError, 'onError')
  }, [getUser])

  React.useEffect(
    () => () => dispatch(UserListSlice.actions.resetUserDetails()),
    [dispatch]
  )

  const [tabValue, setTabValue] = React.useState(
    Object.entries(SectionMap)[0][0]
  )
  const handleTabChange = React.useCallback(
    (_, newValue) => setTabValue(newValue),
    [setTabValue]
  )

  const handleCancel = () => navigate(routes.consumerList.path)

  const isLoading = getUser.isWorking

  if ((id && (!user || !userPoolId)) || isLoading) {
    return (
      <Backdrop
        id='backdrop-loading'
        className={classes.backdrop}
        open={isLoading}
      >
        <Grid
          container
          direction='column'
          className={classes.loadingCtn}
          justifyContent='center'
          alignItems='center'
        >
          <Grid item>
            <CircularProgress size={100} />
          </Grid>
          <Grid item className={classes.loadingMsg}>
            <strong>{loadingMsg}</strong>
          </Grid>
        </Grid>
      </Backdrop>
    )
  }

  return (
    <>
      <Grid container style={{ marginBottom: spacing(3) }}>
        <Grid item xs={9} data-test='title'>
          <SectionHeader title={`${user.givenName} ${user.familyName}`} location='Consumer /' />
        </Grid>
        <Grid item xs={3} className={classes.controlBtnContainer}>
          <ActionButton
            variant='secondary'
            dataTest='backtolist-button'
            onClick={handleCancel}
          >
            Back to list
          </ActionButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* Consumer tabs */}
        <Grid item xs={3}>
          <Panel padding='16px'>
            <Summary />
          </Panel>
        </Grid>
        <Grid item xs>
          <Panel padding='0px 24px 16px 24px'>
            <Grid container>
              <Grid item xs={12}>
                <Tabs
                  indicatorColor='primary'
                  textColor='primary'
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label='Consumer Tabs'
                  classes={{
                    root: classes.tabs
                  }}
                >
                  {Object.entries(SectionMap)
                    .filter(([key]) => SectionMap[key].isVisible())
                    .map(([key, section]) => (
                      <Tab
                        id={`tab-${key}`}
                        aria-controls={`tabpanel-${key}`}
                        key={key}
                        value={key}
                        label={section.title}
                        icon={section.icon}
                        classes={{
                          labelIcon: classes.labelIcon,
                          wrapper: classes.tabWrapper
                        }}
                        data-test={`tab-${key}`}
                      />
                    ))}
                </Tabs>
              </Grid>
            </Grid>
            {/* Tabs content */}
            {Object.entries(SectionMap).map(([key]) => (
              <TabPanel key={key} value={tabValue} index={key} data-test={`tabpanel-${key}`}>
                <Box sx={{ pt: 5 }}>{SectionMap[key].component}</Box>
              </TabPanel>
            ))}
          </Panel>
        </Grid>
      </Grid>
    </>
  )
}

export default ConsumerEdit
