import React from 'react'
import {
  Grid,
  Tab,
  Tabs,
  useTheme
} from '@material-ui/core'
import { Assignment } from '@material-ui/icons'
import { useTenant } from '@emerald-works-nova/auth'
import { Panel, SectionHeader, TabPanel } from '../../components'
import { FileDocumentEditIcon } from '../../components/icons'
import { useStyles } from './style'
import Usage from './usage-tab'
import Content from './content-tab'

const SectionNames = { usage: 'usage', content: 'content' }
const SectionMap = {
  [SectionNames.usage]: {
    component: (props) => <Usage {...props} />,
    title: 'Usage',
    icon: <Assignment />,
    isVisible: () => true
  },
  [SectionNames.content]: {
    component: (props) => <Content {...props} />,
    title: 'Content',
    icon: <FileDocumentEditIcon />,
    isVisible: () => true
  }
}

const Reporting = () => {
  const classes = useStyles()
  const { pk: tenantId } = useTenant()
  const { spacing } = useTheme()

  const [tabValue, setTabValue] = React.useState(
    Object.entries(SectionMap)[0][0]
  )
  const handleTabChange = React.useCallback(
    (_, newValue) => setTabValue(newValue),
    [setTabValue]
  )
  return (
    <>
      <Grid container style={{ marginBottom: spacing(3) }}>
        <Grid item xs={9} data-test='title'>
          <SectionHeader title='Reporting' />
        </Grid>
        {
        // TODO: re-enable in v2
        /* <Grid item>
          <DateFilter />
        </Grid> */
        }
      </Grid>
      <Grid container spacing={2}>
        {/* Reporting tabs */}
        <Grid item xs={12}>
          <Panel padding={0}>
            <Grid container>
              <Grid container item>
                <Tabs
                  indicatorColor='primary'
                  textColor='primary'
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label='Reporting Tabs'
                  classes={{
                    root: classes.tabs
                  }}
                >
                  {Object.entries(SectionMap).map(([key, section]) => (
                    <Tab
                      id={`tab-${key}`}
                      aria-controls={`tabpanel-${key}`}
                      key={key}
                      value={key}
                      label={section.title}
                      icon={section.icon}
                      classes={{ wrapper: classes.tabWrapper }}
                    />
                  ))}
                </Tabs>
              </Grid>
            </Grid>
            {/* Tabs content */}
            {Object.entries(SectionMap).map(([key]) => (
              <TabPanel key={key} value={tabValue} index={key}>
                {SectionMap[key].component({ tenantId })}
              </TabPanel>
            ))}
          </Panel>
        </Grid>
      </Grid>
    </>
  )
}

export default Reporting
