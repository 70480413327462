import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    minHeight: 500
  },
  titleCtn: {
    marginBottom: spacing(3)
  },
  openAllCtn: {
    marginBottom: spacing(1.5)
  },
  categoriesCtn: {
    border: `1px solid ${palette.grey.light}`,
    borderRadius: 6,
    padding: spacing(2.5)
  },
  addCategoryCtn: {
    backgroundColor: palette.grey.light,
    borderBottom: `1px solid ${palette.grey.main}`,
    padding: spacing(1.5),
    minHeight: 38,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  addIcon: {
    marginLeft: spacing(5.25),
    marginRight: spacing(1)
  }
}))
