import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Button, Paper, Grid, Divider, Typography, CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { ArrowBack } from '@material-ui/icons'
import { UserListSlice } from '../../../../reducers'
import { parseISO } from 'date-fns'
import ToggleUser from '../toggle-user'
import { useStyles } from './style'
import RoleSelector from '../user-roles'
import { ALL_GROUPS } from '../../../../util/userGroups'

const UserDetail = ({ user, userPoolId, onBackToListClick, tenantKey }) => {
  const dispatch = useDispatch()
  const [enabled, setEnabled] = useState(user.enabled)
  const [weeklySuscription, setWeeklySuscription] = useState(false)
  const [userDetail, setUserDetail] = useState()
  const [role, setRole] = useState()
  const [roles, setRoles] = useState()

  const handleBackClick = () => {
    onBackToListClick()
  }

  const filterRoles = (rolesList) => rolesList.filter(x => ALL_GROUPS.includes(x.GroupName)).map(x => x.GroupName)

  const [getUserDetails, getWeeklySuscription, getUserRoles] = useEvent([
    {
      eventName: 'adminGetUserCognito',
      onSuccess: payload => {
        setUserDetail(payload)
      }
    },
    {
      eventName: 'getWeeklySuscription',
      onSuccess: payload => {
        setWeeklySuscription(payload?.newsletter?.emailWeeklySubscription || false)
      }
    },
    {
      eventName: 'adminGetUserRoles',
      onSuccess: payload => {
        setRole(payload.role[0])
        setRoles(filterRoles(payload.roles.Groups))
      }
    }
  ])
  const isLoading = React.useMemo(() =>
    (
      getUserDetails.isWorking ||
    getWeeklySuscription.isWorking ||
    getUserRoles.isWorking
    ), [getUserDetails.isWorking, getWeeklySuscription.isWorking, getUserRoles.isWorking]
  )

  const classes = useStyles({
    enabled,
    isLoading: isLoading,
    suscribed: weeklySuscription || false,
    rows: userDetail?.custom?.length
  })

  useEventsOnViewLoad(() => {
    dispatch(UserListSlice.actions.resetUserDetails())
    getUserDetails.trigger({ userPoolId, username: user.username })
    getWeeklySuscription.trigger({ tenantKey, username: user.username })
    getUserRoles.trigger({ userPoolId, username: user.username })
  }, [getUserDetails, getWeeklySuscription, getUserRoles, dispatch])

  const renderCustom = () => (
    userDetail.custom.map((att, index, arr) => (
      <Grid key={`attributes-${index}`} item container xs={12} spacing={2}>
        <Grid item xs={3}>
          <Typography variant='body1' data-test={`attribute-label-${index}`}>
            Attribute
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant='body1' data-test={`attribute-name-label-${index}`}>
            Attribute Name
          </Typography>
        </Grid>
        <Grid item xs={3} data-test={`att-value-${index}`}>
          {att.Value}
        </Grid>
        <Grid item xs={9} data-test={`att-name-${index}`}>
          {att.Name}
        </Grid>
        {index + 1 !== arr.length && (
          <Grid item xs={12}>
            <Divider variant='fullWidth' />
          </Grid>
        )}
      </Grid>
    ))
  )

  return (
    <>
      <Button color='primary' size='small' onClick={() => handleBackClick()} data-test='user-detail-back-button'> <ArrowBack /> Back to User List </Button>
      <Paper
        id='user-list'
        className={classes.root}
        tabIndex={0}
        aria-label='Content List'
        elevation={0}
        style={{ margin: 20 }}
      >
        {!isLoading ? userDetail && (
          <Grid container spacing={2} className={classes.paper}>
            <Grid item xs={6}>
              <Typography variant='h5' data-test='first-name-label'>
                First Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h5' data-test='last-name-label'>
                Last Name
              </Typography>
            </Grid>
            <Grid item xs={6} data-test='first-name'>
              {userDetail?.givenName}
            </Grid>
            <Grid item xs={6} data-test='last-name'>
              {userDetail?.familyName}
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h5' data-test='email-label'>
                Email Address
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h5' data-test='username-label'>
                Username
              </Typography>
            </Grid>
            <Grid item xs={6} data-test='email'>
              {userDetail?.email}
            </Grid>
            <Grid item xs={6} data-test='username'>
              {userDetail?.username}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' data-test='creation-label'>
                Creation Date
              </Typography>
            </Grid>
            <Grid item xs={12} data-test='creation'>
              {parseISO(userDetail?.createdAt).toLocaleDateString()}
            </Grid>
            <Grid item xs={12}>
              <Divider variant='fullWidth' />
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h5' data-test='role-label'>
                User Role
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h5' data-test='email-suscription-label'>
                Weekly Email Subscription
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h5' data-test='status-label'>
                User Status
              </Typography>
            </Grid>
            <Grid item xs={4} data-test='role' className={classes.role}>
              {role && <RoleSelector user={user} userRole={role} roles={roles} userPoolId={userPoolId} />}
            </Grid>
            <Grid item xs={4} className={classes.suscription} data-test='email-suscription'>
              {weeklySuscription ? 'Suscribed' : 'Unsuscribed'}
            </Grid>
            <Grid item xs={4} data-test='status'>
              <ToggleUser user={user} userPoolId={userPoolId} onToggle={(value) => setEnabled(value)} />
            </Grid>
            {userDetail?.custom && (
              <>
                <Grid item xs={12}>
                  <Divider variant='fullWidth' />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h5' data-test='sso-attributes'>
                    SSO Attributes
                  </Typography>
                </Grid>
                {renderCustom()}
              </>
            )}
          </Grid>
        ) : (
          <Grid
            id='tenant-list-loading'
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            classes={{ root: classes.loadingAnimation }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
      </Paper>
    </>
  )
}

export default UserDetail
