import {
  useEvent,
  useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { ReportingSlice } from '../../../reducers'
import {
  BarChart,
  ContentTable,
  InfoCard,
  PieChart,
  PlaylistGrid
} from '../components'
import { useStyles } from './style'

const ContentTab = ({ tenantId }) => {
  const classes = useStyles()

  const {
    topCategories,
    topSubcategories,
    topContent,
    topPlaylists,
    contentByType
  } = useSelector(ReportingSlice.selectors.selectContentTab)

  const filters = useSelector(ReportingSlice.selectors.selectFilters)

  const [getContentTab] = useEvent([ReportingSlice.eventBus.getContentTab])

  useEventsOnViewLoad(() => {
    getContentTab.trigger({ tenantId, filters })
  }, [getContentTab, filters])

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={6}>
        <InfoCard
          title='Top subjects'
          tooltip='The number of unique users who viewed content related to the subjects displayed in the navigation menu.'
        >
          <BarChart data={topCategories} loading={getContentTab.isWorking} />
        </InfoCard>
      </Grid>
      <Grid item xs={6}>
        <InfoCard
          title='Top topics'
          tooltip='The number of unique users who viewed content related to topics.'
        >
          <BarChart
            data={topSubcategories}
            barColor='#C1E7FF'
            loading={getContentTab.isWorking}
          />
        </InfoCard>
      </Grid>
      <Grid item xs={6}>
        <InfoCard
          title='Resource types'
          tooltip='The percentage proportion of resource type usage.'
        >
          <PieChart data={contentByType} loading={!contentByType || getContentTab.isWorking} />
        </InfoCard>
      </Grid>
      <Grid item xs={6}>
        <InfoCard
          title='Top resources'
          tooltip='The number of unique users who viewed a piece of content.'
        >
          <ContentTable
            data={topContent}
            loading={!topContent || getContentTab.isWorking}
          />
        </InfoCard>
      </Grid>
      <Grid item xs={12}>
        <InfoCard
          title='Top Playlists'
          tooltip='Top playlists based on the number of unique viewers.'
        >
          <PlaylistGrid
            data={topPlaylists}
            loading={!topPlaylists || getContentTab.isWorking}
          />
        </InfoCard>
      </Grid>
    </Grid>
  )
}

export default ContentTab
