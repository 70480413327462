import React from 'react'
import { Chart } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Box, useTheme } from '@material-ui/core'
import options from './options'
import Legend from '../legend'
import { parseDate } from './util'

const loadingData = [150, 175, 160, 220, 190]
const loadingLabels = new Array(5)
  .fill(null)
  .map(
    (_item, idx, arr) => {
      const d = new Date()
      d.setDate(d.getDate() - (arr.length - idx - 1) * 7)
      return d
    }
  )

const UsageChart = ({ data, loading }) => {
  const { palette } = useTheme()

  const labels = data?.map(({ sk }) => parseDate(sk))
  const values = data?.map(({ count }) => count)
  const color = loading ? 'rgba(33, 33, 33, 0.08)' : palette.primary.main
  const chartData = {
    labels: loading ? loadingLabels : labels,
    datasets: [
      {
        id: '',
        label: 'Active Users',
        data: loading ? loadingData : values,
        backgroundColor: color,
        borderColor: color
      }
    ]
  }

  return (
    <>
      <Line
        options={{ ...options, events: loading ? [] : Chart.defaults.events }}
        data={chartData}
      />
      <Box ml={2}>
        <Legend labels={['Active users']} colors={[palette.primary.main]} />
      </Box>
    </>
  )
}

export default UsageChart
