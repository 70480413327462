// List of Cognito's accepted attributes
const getAttributeList = () => [
  {
    label: 'Address',
    name: 'address',
    description: "User's preferred postal address."
  },
  {
    label: 'Birthdate',
    name: 'birthdate',
    description: "User's birthday, represented as an ISO 8601:2004 YYYY-MM-DD format."
  },
  {
    label: 'Email',
    name: 'email',
    description: "User's preferred email address"
  },
  {
    label: 'Email Verified',
    name: 'email_verified',
    description: "True if the user's email address has been verified."
  },
  {
    label: 'Family Name',
    name: 'family_name',
    description: 'Surname(s) or last name(s) of the user'
  },
  {
    label: 'Gender',
    name: 'gender',
    description: "User's gender. Values does not need to be restricted to male or female."
  },
  {
    label: 'Given Name',
    name: 'given_name',
    description: 'Given name(s) or first name(s) of the user.'
  },
  {
    label: 'Locale',
    name: 'locale',
    description: "User's locale, represented as a BCP47 language tag."
  },
  {
    label: 'Middle Name',
    name: 'middle_name',
    description: 'Middle name(s) of the user.'
  },
  {
    label: 'Name',
    name: 'name',
    description: "User's full name in displayable form including all name parts."
  },
  {
    label: 'Nickname',
    name: 'nickname',
    description: 'Casual name of the user that may or may not be the same as the Given Name.'
  },
  {
    label: 'Phone Number',
    name: 'phone_number',
    description: "User's preferred telephone number. E.164 is recommended as the format of this Claim."
  },
  {
    label: 'Phone Number Verified',
    name: 'phone_number_verified',
    description: "True if the user's phone number has been verified."
  },
  {
    label: 'Picture',
    name: 'picture',
    description: "URL of the user's profile picture. URL ideally refers to an image file."
  },
  {
    label: 'Preferred Username',
    name: 'preferred_username',
    description: 'Shorthand name by which the user wishes to be referred to.'
  },
  {
    label: 'Profile',
    name: 'profile',
    description: "URL of the user''s profile page."
  },
  {
    label: 'Zone Info',
    name: 'zoneinfo',
    description: "Value from zoneinfo time zone database for the user's time zone, i.e., Europe/Paris."
  },
  {
    label: 'Updated At',
    name: 'updated_at',
    description: "Time the user's information was last updated, UTC timestamp."
  },
  {
    label: 'Username',
    name: 'username',
    description: "User's preferred username."
  },
  {
    label: 'Website',
    name: 'website',
    description: "URL of the user's Web page or blog."
  }
]

export { getAttributeList }
