import { useEvent } from '@emerald-works/react-event-bus-client'
import React from 'react'
import { useSelector } from 'react-redux'
import { buildToolkitUrl } from '../helpers/toolkit'
import { AppSlice } from '../reducers'

const useExternalApp = ({ tenantKey = 'www', excludeProtocol = false } = {}) => {
  const envInfo = useSelector(AppSlice.selectors.selectEnvInfo)
  const [getEnvInfo] = useEvent([AppSlice.eventBus.getEnvInfo])

  const baseToolkitUrl = React.useMemo(() => {
    if (process.env.REACT_APP_TOOLKIT_URL) {
      return process.env.REACT_APP_TOOLKIT_URL
    }

    if (!envInfo) {
      getEnvInfo.trigger()
      return null
    }

    const { stage, toolkitAppName, tld } = envInfo
    return buildToolkitUrl(tenantKey, stage, toolkitAppName, tld, excludeProtocol)
  }, [envInfo, tenantKey, getEnvInfo, excludeProtocol])

  return { baseToolkitUrl }
}

export default useExternalApp
