export const ADMIN = 'admin'
export const EDITOR = 'editor'
export const CX = 'cx'
export const MARKETING = 'marketing'
export const FREE = 'free'
export const CONSUMER = 'consumer'
export const SUSPENDED = 'suspended'
export const EW = 'ewUser'
export const CLIENT_MANAGER = 'clientManager'
export const ENTERPRISE_READER = 'enterpriseReader'

export const ALL_GROUPS = [
  ADMIN,
  FREE,
  CONSUMER,
  SUSPENDED,
  EW,
  EDITOR,
  MARKETING,
  CX,
  CLIENT_MANAGER,
  ENTERPRISE_READER
]
