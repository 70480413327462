import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: '100%',
    padding: spacing(0, 3, 3)
  },
  ctnTitle: {
    marginBottom: spacing(5)
  }
}))
