import {
  useEvent,
  useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'
import { Grid } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { ReportingSlice } from '../../../reducers'
import {
  UsageChart,
  EngagementChart,
  InfoCard,
  StatCard,
  Time,
  UserTable
} from '../components'
import { useStyles } from './style'
import { Percentage } from '../components/info-card'

const Usage = ({ tenantId }) => {
  const classes = useStyles()
  const filters = useSelector(ReportingSlice.selectors.selectFilters)

  const {
    activeUsers,
    activeUsersOverTime,
    averageViewTime,
    totalViewTime,
    resourcesViewed,
    resourcesByUser,
    registeredUsers,
    usersLimit
  } = useSelector(ReportingSlice.selectors.selectUsageTab)

  const [getUsageTab] = useEvent([ReportingSlice.eventBus.getUsageTab])

  useEventsOnViewLoad(() => {
    getUsageTab.trigger({ tenantId, filters })
  }, [getUsageTab, filters])

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={3}>
          <StatCard
            title='Active users'
            textAlign='center'
            tooltip='The  number of unique users who logged into the system and accessed at least one resource.'
            data={activeUsers}
            renderChange={value => (
              <span className={classes.neutral}>
                {value >= 0 ? `+ ${value} new` : `${value}`}
              </span>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            title='Total view time'
            tooltip='Total time spent viewing content pages. Based on content length calculation.'
            data={totalViewTime}
            renderValue={value => <Time time={value} />}
            renderChange={(change, count) => (
              <Percentage change={change} count={count} />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            title='Resources Viewed'
            tooltip='The  number of unique content page views.'
            data={resourcesViewed}
            renderChange={(change, count) => (
              <Percentage change={change} count={count} />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            title='Avg view time per user'
            tooltip='The average amount of time a user spends viewing content pages.'
            data={averageViewTime}
            renderValue={value => <Time time={value} />}
            renderChange={(change, count) => (
              <Percentage change={change} count={count} />
            )}
          />
        </Grid>
        <Grid item xs={9}>
          <InfoCard
            title='Usage overview'
            tooltip='Displays the number of active users over time.'
          >
            <UsageChart
              data={activeUsersOverTime}
              loading={getUsageTab.isWorking}
            />
          </InfoCard>
        </Grid>
        <Grid item xs={3}>
          <InfoCard
            title='User engagement'
            tooltip='Potential audience refers to the number of users agreed in the service contract. Actual audience refers to the number of users who registered or used the toolkit at least once.'
          >
            <EngagementChart
              data={{ registeredUsers, usersLimit }}
              loading={getUsageTab.isWorking}
            />
          </InfoCard>
        </Grid>
        <Grid item xs={12}>
          <InfoCard
            title='Top users'
            tooltip='Top users based on the number of unique content page views.'
          >
            <UserTable data={resourcesByUser} />
          </InfoCard>
        </Grid>
      </Grid>
    </>
  )
}

export default Usage
