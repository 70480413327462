import * as actions from './slice'

const getTenant = {
  eventName: 'tenantGetAdmin',
  onSuccess: [{ action: actions.getTenant, redux: true }
  ]
}

export {
  getTenant
}
