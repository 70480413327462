import { ActionButton, FormInputRow, Input } from '@emerald-works-nova/components'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { Divider, Grid, Typography } from '@material-ui/core'
import { kebabCase } from 'lodash'
import React, { useState } from 'react'
import Metering from '../components/metering'
import StubbedContent from '../components/stubbed-content'
import TempUserField from '../components/tempuser-field'
import { useTenantFormContext } from '../tenant-form-context'
import { useStyles } from './style'
import { buildToolkitUrl } from '../../../helpers/toolkit'

const DetailTab = () => {
  const classes = useStyles()
  const {
    form,
    envInfo,
    isEdit,
    onUpdateTenant,
    tenant,
    handleNextClick,
    mainClasses
  } = useTenantFormContext()

  const {
    watch,
    setValue,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    trigger
  } = form
  const [loading, setLoading] = useState(false)
  const tenantName = watch('name')

  React.useEffect(() => {
    if (!tenantName && !isEdit) {
      setValue('tenantKey', '')
      setValue('tenantUrl', '')
      return
    }

    const tenantKey = tenant?.tenantKey || kebabCase(tenantName)
    if (!isEdit) setValue('tenantKey', tenantKey)

    if (!envInfo) {
      setValue('tenantUrl', '')
      return
    }

    const { stage, toolkitAppName, tld } = envInfo
    const toolkitUrl = buildToolkitUrl(tenantKey, stage, toolkitAppName, tld)

    setValue('tenantUrl', toolkitUrl)
  }, [tenantName, tenant, setValue, envInfo, isEdit])

  const [isNameInUse] = useEvent([
    {
      eventName: 'tenantGet',
      onSuccess: e => {
        if (!e) {
          clearErrors('tenantKey')
        } else {
          setError('tenantKey', {
            type: 'exist',
            message: 'Name already exist'
          })
        }
        setLoading(false)
      }
    }
  ])

  const handleBlur = e => {
    if (e.target.value && !isEdit) {
      const tenantKey = getValues('tenantKey')
      isNameInUse.trigger({ tenantKey })
      setLoading(true)
      envInfo &&
        setValue(
          'tenantUrl',
          `https://${tenantKey}.${envInfo.stage}.${envInfo.toolkitAppName}.${envInfo.tld}`
        )
    }
  }

  const onNext = async () => {
    const result = await trigger(['tenantKey', 'name', 'clientName'])
    result && handleNextClick()
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid container alignItems='center' className={mainClasses.ctnTitle}>
          <Grid item xs={8}>
            <Typography variant='h6'>Details</Typography>
          </Grid>
          <Grid container item justifyContent='flex-end' xs={4}>
            {!isEdit && (
              <ActionButton
                variant='primary'
                dataTest='create-tenant-save-button'
                onClick={onNext}
                // disabled={nextBtnDisabled}
                className={mainClasses.navBtn}
              >Next
              </ActionButton>
            )}
            {isEdit && (
              <ActionButton
                variant='primary'
                dataTest='button-save-tenant-sso'
                onClick={onUpdateTenant}
              >Save Changes
              </ActionButton>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormInputRow
            title='Client Name'
            description='This information is mandatory, but it does not need to be unique.'
            autofocus
            smallFormTitles
            InputComponent={
              <Input
                label='Client Name'
                name='clientName'
                control={form.control}
                rules={{ required: true }}
                valid={errors.clientName === undefined}
                error='Client Name is required'
                showErrors
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputRow
            title='Toolkit Name'
            description='The toolkit name must be unique and you cannot change it after the creation of the toolkit'
            smallFormTitles
            InputComponent={
              <Input
                rules={{
                  required: {
                    value: 'true',
                    message: 'Toolkit Name is required'
                  }
                }}
                onBlur={handleBlur}
                label='Name'
                name='name'
                control={form.control}
                valid={errors.name === undefined}
                error={errors.name?.message}
                showErrors
              />
            }
          />
        </Grid>

        <Grid item xs={12} className={isEdit ? classes.greyBackground : ''}>
          <FormInputRow
            title='Toolkit ID'
            description="This information is automatically configured after entering the toolkit name. It will be used as the subdomain of the toolkit's URL."
            smallFormTitles
            InputComponent={
              <Input
                label='Toolkit ID'
                name='tenantKey'
                control={form.control}
                valid={errors.tenantKey === undefined}
                error={errors.tenantKey?.message}
                rules={{
                  required: {
                    value: 'true',
                    message: 'Toolkit ID is required'
                  }
                }}
                helperText={loading && 'Checking if this ID is available...'}
                className={
                  errors.tenantKey === undefined ? classes.loading : ''
                }
                onBlur={handleBlur}
                InputProps={isEdit ? { readOnly: true } : ''}
                showErrors
              />
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.greyBackground}>
          <FormInputRow
            title='Toolkit URL'
            description='The web address for the Toolkit.'
            smallFormTitles
            InputComponent={
              <Input
                label='Toolkit URL'
                name='tenantUrl'
                control={form.control}
                InputProps={{
                  readOnly: true
                }}
                className={getValues('tenantUrl') ? '' : classes.disableTouch}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputRow
            title='Number of Users'
            description='This is the maximum number of users that the client is paying to have on their toolkit. This information will feed the reporting tool and clients will not be cut off if their users exceed the number you specify.'
            smallFormTitles
            InputComponent={
              <Input
                label='Number of Users'
                name='usersLimit'
                textRegex='^[0-9\b]+$'
                control={form.control}
              />
            }
          />
        </Grid>
        <Divider className={classes.detailsDivider} />
        <StubbedContent />
        <Divider className={classes.detailsDivider} />
        <Metering />
        {isEdit && <TempUserField />}
      </Grid>
    </div>
  )
}

export default DetailTab
