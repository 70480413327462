import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    minHeight: 400
  },
  gridHeader: {
    height: 60,
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: spacing(1)
  },
  tablePaper: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: spacing(0, 1.5),
    minHeight: 500
  },
  tableHead: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.25
  },
  loadMoreBtn: {
    margin: spacing(5, 0)
  },
  loadingAnimation: {
    minHeight: 400
  },
  emptyList: {
    minHeight: spacing(30),
    color: palette.text.secondary
  },
  ctnTitle: {
    padding: spacing(4, 0)
  },
  container: {
    width: '100%',
    padding: spacing(0, 3, 3)
  },
  status: {
    color: ({ enabled }) => enabled ? palette.success.main : palette.grey.main
  }
}))
