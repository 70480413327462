import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    marginBottom: spacing(3)
  },
  contentTotal: {
    color: palette.text.secondary,
    marginLeft: spacing(1)
  },
  gridHeader: {
    height: 60,
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: spacing(1)
  },
  tablePaper: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: spacing(0, 1.5),
    minHeight: 500
  },
  tableHead: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.25
  },
  dateUpdatedHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginRight: spacing(1)
    }
  },
  loadMoreBtn: {
    margin: spacing(5, 0)
  },
  loadingAnimation: {
    minHeight: 400
  },
  emptyList: {
    minHeight: spacing(30),
    color: palette.text.secondary
  }
}))
