import * as actions from './slice'

const getTenantList = {
  eventName: 'tenantSearch',
  onSuccess: [{ action: actions.getTenantList, redux: true }
  ]
}

const addTenantList = {
  eventName: 'tenantSearch',
  onSuccess: [{ action: actions.addTenantList, redux: true }
  ]
}

export {
  getTenantList,
  addTenantList
}
