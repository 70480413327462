import React from 'react'
import { useStyles } from './style'
import { useGroup } from '../../hooks/index'
import { useNavigate } from 'react-router'
import { routes } from '../../routes'
import { ALLOW_REPORTING } from '../../util/permissions'

const FormExample = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { groups } = useGroup()

  React.useEffect(() => {
    if (groups?.includes(ALLOW_REPORTING)) {
      navigate(routes.reporting.path)
    }
  }, [groups, navigate])

  return (
    <div className={classes.root}>
      This is the home page
    </div>
  )
}

export default FormExample
