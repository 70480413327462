import { createSlice } from '@reduxjs/toolkit'
import name from './name'

export const SectionMap = {
  details: {
    title: 'Details',
    steps: {
      title: {
        name: 'title',
        label: 'Title'
      },
      subtitle: {
        name: 'subtitle.html',
        label: 'Subtitle'
      },
      metaDescription: {
        name: 'metaDescription',
        label: 'Meta Description'
      },
      keywords: {
        name: 'keywords',
        label: 'Keywords'
      }
    }
  },
  thumbnail: {
    title: 'Thumbnail',
    steps: {
      image: {
        name: 'image.name',
        label: 'Image'
      },
      altText: {
        name: 'image.altText',
        label: 'Alt Text'
      },
      caption: {
        name: 'image.caption',
        label: 'Caption'
      },
      stockPhotos: {
        name: 'image.stockPhotos',
        label: 'Stock Photos'
      },
      author: {
        name: 'image.author',
        label: 'Author'
      }
    }
  },
  body: {
    title: 'Body',
    steps: {
      mainImage: {
        name: 'mainImage',
        label: 'Main Image'
      },
      body: {
        name: 'body',
        label: 'Body Content'
      }
    }
  },
  status: {
    title: 'Status ',
    steps: {
      migration: {
        name: 'migrationStatus',
        label: 'Migration Status'
      },
      review: {
        name: 'reviewStatus',
        label: 'Review Status'
      }
    }
  }
}

// Store
export const contentEdit = createSlice({
  name,
  initialState: {
    sections: SectionMap
  },
  reducers: {
    setSections: (state, { payload }) => {
      state.sections = payload
    }
  }
})

export const { setSections } = contentEdit.actions

export default contentEdit.reducer
