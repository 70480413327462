import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const app = createSlice({
  name,
  initialState: {
    envInfo: undefined
  },
  reducers: {
    getEnvInfo: (state, { payload }) => {
      state.envInfo = payload
    }
  }
})

export const { getEnvInfo } = app.actions

export default app.reducer
