import { merge as deepMerge } from 'lodash'
import { HTML_FONT_SIZE, FONT_SIZE, DEFAULT_FAMILY } from './settings'
import * as helpers from './helpers'

// This is the base typography that will be used to build the theme.
// `typography` param option will either override or add values to
// the typography property of theme object that is inject for the entire application.

// Add new properties as needed and all application components will have the
// new proprity available in the `theme.typography` object

// For more information about each property and what it means:
// https://material-ui.com/customization/typography/

export default (options = {}) => {
  return (palette) => {
    const baseTypography = {
      htmlFontSize: HTML_FONT_SIZE,
      fontSize: FONT_SIZE,
      fontFamily: DEFAULT_FAMILY,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      // a collection of properties to be applied to all default material ui variants.
      // defaults: h1-h6, subtitle1, subtitle2, body1, body2, button, caption, overline
      // you can also add proprites to a specific variant like the h1 example.
      // for more information: https://material-ui.com/customization/typography/#variants
      allVariants: {},
      h1: {
        fontSize: 96,
        fontWeight: 300,
        letterSpacing: -1.5,
        textAlign: 'left'
      },
      h2: {
        fontSize: 60,
        fontWeight: 300,
        letterSpacing: -0.5,
        textAlign: 'left'
      },
      h3: {
        fontSize: 48,
        fontWeight: 400,
        letterSpacing: 0,
        textAlign: 'left'
      },
      h4: {
        fontSize: 34,
        fontWeight: 400,
        letterSpacing: 0,
        textAlign: 'left'
      },
      h5: {
        fontWeight: 400,
        fontSize: 24,
        letterSpacing: 0.18,
        textAlign: 'left'
      },
      h6: {
        fontWeight: 500,
        fontSize: 22,
        letterSpacing: 0.15,
        textAlign: 'left'
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0.15,
        textAlign: 'left'
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: 0.1,
        textAlign: 'left'
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0.5,
        textAlign: 'left'
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: 0.25,
        textAlign: 'left'
      },
      button: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: 1.25,
        textAlign: 'left'
      },
      caption: {
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0.4,
        textAlign: 'left'
      },
      overline: {
        fontWeight: 500,
        fontSize: 10,
        letterSpacing: 1.5,
        textAlign: 'left',
        textTransform: 'uppercase'
      },
      // Add extra typography elements here.
      // palette object is pass as a param so any custom color applied to
      // some typography element should be added there and then used it here
      customTitle: {
        // transform pixel to rem.
        // Responsive font sizes based on htmlFontSize.
        fontSize: helpers.pxToRem(30),
        color: palette.text.customColor
      },
      ...helpers
    }

    return deepMerge(baseTypography, options)
  }
}
