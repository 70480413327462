import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import React, { useEffect, useState } from 'react'
import { ContentTypeIcon } from '../'
import { useStyles } from './style'

const ContentTypeRadioButton = ({
  value, blockTypeOptions
}) => {
  const classes = useStyles()
  const [selectedType, setSelectedType] = useState(value[0])
  const [secondaryType, setSecondaryType] = useState(value[1])

  useEffect(() => {
    setSelectedType(value[0])
    setSecondaryType(value[1])
  }, [value])

  return (
    <Grid container spacing={2} direction='column' alignItems={blockTypeOptions ? 'flex-start' : 'center'}>
      <Grid item>
        <ToggleButtonGroup
          size='large'
          value={selectedType}
          exclusive
          classes={{
            root: classes.root,
            groupedHorizontal: classes.groupedHorizontal
          }}
        >
          <ToggleButton
            data-test={`${selectedType}-content-type-button`}
            value={selectedType}
            disabled={blockTypeOptions}
            classes={{
              root: classes.button,
              selected: classes.buttonSelected
            }}
          >
            {!blockTypeOptions && (
              <div className={classes.selectedIcon}>
                <CheckIcon classes={{ root: classes.icon }} />
              </div>
            )}
            <ContentTypeIcon type={selectedType} secondaryType={secondaryType} showDescription />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  )
}

export default ContentTypeRadioButton
