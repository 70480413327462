import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    color: ({ isSelected }) => isSelected ? palette.primary.main : palette.text.secondary,
    marginBottom: spacing(1),
    position: 'relative',
    '&::before': {
      display: ({ isSelected }) => isSelected ? 'block' : 'none',
      content: 'close-quote',
      position: 'absolute',
      width: spacing(0.5),
      height: spacing(3),
      left: -12,
      top: 4,
      backgroundColor: palette.primary.main
    }
  },
  steps: {
    marginBottom: spacing(1)
  },
  stepLabel: {
    color: palette.text.secondary
  }
}))
