import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  dateFilterPicker: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 170,
    minHeight: spacing(4.5),
    boxSizing: 'border-box',
    marginLeft: spacing(3)
  },
  dateFilterAdornedEnd: {
    paddingRight: spacing(1),
    '& >input': {
      padding: spacing(1, 0, 1, 1)
    }
  },
  keyboardAdornment: {
    padding: spacing(1)
  },
  dateFilterLabel: {
    transform: 'translate(14px, 12px) scale(1)'
  }
}))
