import { useEvent } from '@emerald-works/react-event-bus-client'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { BannerStatic } from '../../../components'
import { useExternalApp } from '../../../hooks'
import { UserListSlice } from '../../../reducers'
import useConsumerTenant from '../../consumer-list/useConsumerTenant'

const getChangedFields = (dirty, data) => {
  const dirtyFields = Object.keys(dirty)
  return dirtyFields.reduce((acc, field) => {
    acc[field] = data[field]
    return acc
  }, {})
}

const useDetailForm = () => {
  const user = useSelector(UserListSlice.selectors.selectUser)

  const form = useForm()
  const userPoolId = useConsumerTenant()
  const dispatch = useDispatch()

  const { baseToolkitUrl } = useExternalApp({ excludeProtocol: true })

  const [isEdit, setIsEdit] = React.useState(false)

  const [updateRoleUser, updateUser] = useEvent([
    {
      eventName: 'adminUpdateRoleUser',
      onSuccess: payload => {
        dispatch(UserListSlice.actions.setUserRole(payload.role))
        BannerStatic.show({ label: 'User role changed successfully' })
        setIsEdit(false)
      },
      onError: e => {
        console.error('Error while attempting to change the user role', e)
        BannerStatic.show({
          label: 'Error while attempting to change the user role',
          isError: true
        })
      }
    },
    {
      eventName: 'adminUpdateUser',
      onSuccess: () => {
        const updatedFields = getChangedFields(
          form.formState.dirtyFields,
          form.getValues()
        )
        delete updatedFields.role
        dispatch(UserListSlice.actions.setUserDetail(updatedFields))
        BannerStatic.show({ label: 'User updated successfully' })
        setIsEdit(false)
      },
      onError: e => {
        console.error("Error while attempting to update the user's details", e)
        BannerStatic.show({
          label: "Error while attempting to update the user's details",
          isError: true
        })
      }
    }
  ])

  const resetForm = React.useCallback(() => {
    form.reset({
      role: user.roles?.[0],
      givenName: user.givenName,
      familyName: user.familyName,
      email: user.email
    })
  }, [user, form])

  React.useEffect(() => {
    if (user) {
      resetForm()
    }
  }, [user, resetForm])

  const handleEdit = () => {
    setIsEdit(true)
  }
  const handleCancelEdit = () => {
    setIsEdit(false)
    resetForm()
  }

  const loading = updateRoleUser.isWorking || updateUser.isWorking

  const onSubmit = data => {
    const fieldsToUpdate = getChangedFields(form.formState.dirtyFields, data)
    const { role, ...other } = fieldsToUpdate

    if (role) {
      updateRoleUser.trigger({
        userPoolId,
        username: user.username,
        role: data.role,
        previousRole: user.roles?.[0]
      })
    }

    if (Object.keys(other).length) {
      const { givenName, familyName, email } = other
      updateUser.trigger({
        userPoolId,
        username: user.username,
        attributes: {
          given_name: givenName,
          family_name: familyName,
          email
        },
        verifyUrl: baseToolkitUrl
      })
    }
  }

  return { ...form, onSubmit, loading, isEdit, handleEdit, handleCancelEdit }
}

export default useDetailForm
