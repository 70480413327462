import { SvgIcon } from '@material-ui/core'
import React from 'react'

export const FileDocumentEditIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M2 0C0.89 0 0 0.89 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H6V18.09L8.09 16H2V14H10.09L12.09 12H2V10H14.09L16 8.09V6L10 0H2ZM9 1.5L14.5 7H9V1.5ZM16.15 11C16.076 10.9984 16.0024 11.0117 15.9337 11.0392C15.8649 11.0667 15.8025 11.1078 15.75 11.16L14.73 12.18L16.82 14.26L17.84 13.25C18.05 13.03 18.05 12.67 17.84 12.46L16.54 11.16C16.4889 11.1087 16.4281 11.0682 16.3611 11.0407C16.2942 11.0132 16.2224 10.9994 16.15 11ZM14.14 12.77L8 18.92V21H10.08L16.23 14.85L14.14 12.77Z' />
  </SvgIcon>
)

export const NoThumbnailImage = (props) => (
  <SvgIcon {...props}>
    <path d='M21.9 19.9001L13.41 11.4101L3.59 1.5901L2.1 0.100098L0.690002 1.5101L3 3.8301V17.0001C3 18.1001 3.9 19.0001 5 19.0001H18.17L20.48 21.3101L21.9 19.9001ZM5 16.0001L8.5 11.5001L11 14.5101L12.17 13.0001L15.17 16.0001H5ZM21 16.1701L5.83 1.0001H19C20.1 1.0001 21 1.9001 21 3.0001V16.1701Z' />
  </SvgIcon>
)

export const H1Icon = (props) => (
  <SvgIcon {...props}>
    <path d='M11 7H13V17H11V13H7V17H5V7H7V11H11V7ZM17.57 7C16.9766 7.94943 16.0661 8.658 15 9V10H17V17H19V7H17.57Z' />
  </SvgIcon>
)

export const H2Icon = (props) => (
  <SvgIcon {...props}>
    <path d='M9 7.00048H11V17.0005H9V13.0005H5V17.0005H3V7.00048H5V11.0005H9V7.00048ZM17 15.0005C17.51 14.5905 17.6 14.3805 18.06 13.9505C18.497 13.5505 18.908 13.1225 19.29 12.6705C19.624 12.2805 19.91 11.8505 20.14 11.3905C20.34 11.0005 20.445 10.5685 20.45 10.1305C20.4549 9.68918 20.3627 9.2522 20.18 8.85048C20.0027 8.46567 19.7433 8.12434 19.42 7.85048C19.074 7.56739 18.6768 7.3535 18.25 7.22048C17.765 7.0675 17.2585 6.99323 16.75 7.00048C16.39 7.00048 16.033 7.03348 15.68 7.10048C15.337 7.16048 15.002 7.25848 14.68 7.39048C14.3757 7.5208 14.0872 7.68518 13.82 7.88048C13.5333 8.09054 13.2595 8.31761 13 8.56049L14.24 9.78048C14.5479 9.5128 14.8831 9.27818 15.24 9.08048C15.59 8.88048 15.987 8.77648 16.39 8.78048C16.845 8.75048 17.296 8.88648 17.66 9.16048C17.97 9.43848 18.137 9.84448 18.11 10.2605C18.096 10.6565 17.97 11.0405 17.75 11.3705C17.465 11.8236 17.1295 12.243 16.75 12.6205C16.31 13.0505 15.77 13.5405 15.16 14.0505C14.55 14.5605 13.75 15.1105 13 15.7005V17.0005H21V15.0005H17Z' />
  </SvgIcon>
)

export const H3Icon = (props) => (
  <SvgIcon {...props}>
    <path d='M14.11 14.2176C14.465 14.5046 14.86 14.7406 15.28 14.9176C15.714 15.0976 16.18 15.1906 16.65 15.1876C17.134 15.2046 17.615 15.1016 18.05 14.8876C18.2133 14.8161 18.3522 14.6985 18.4499 14.5494C18.5475 14.4002 18.5997 14.2259 18.6 14.0476C18.6023 13.8444 18.5505 13.6443 18.45 13.4676C18.327 13.2776 18.15 13.1276 17.94 13.0376C17.6212 12.9007 17.2844 12.8098 16.94 12.7676C16.4367 12.6949 15.9285 12.6614 15.42 12.6676V11.0976C16.1616 11.1503 16.9051 11.027 17.59 10.7376C17.7716 10.6572 17.9269 10.5274 18.0383 10.363C18.1497 10.1985 18.2127 10.0061 18.22 9.80764C18.2325 9.65125 18.204 9.49431 18.1374 9.35227C18.0707 9.21024 17.9682 9.08801 17.84 8.99764C17.5 8.79464 17.106 8.69764 16.71 8.71764C16.3146 8.73045 15.9263 8.82583 15.57 8.99764C15.195 9.16483 14.8395 9.37275 14.51 9.61764L13.29 8.22764C13.79 7.85064 14.343 7.54764 14.93 7.32764C15.5381 7.10355 16.1819 6.99174 16.83 6.99764C17.355 6.99064 17.88 7.04764 18.39 7.16764C18.82 7.26764 19.23 7.44464 19.6 7.68764C19.925 7.89764 20.195 8.18264 20.39 8.51764C20.58 8.85964 20.677 9.24764 20.67 9.63764C20.68 10.1176 20.493 10.5806 20.15 10.9176C19.7326 11.3084 19.2341 11.602 18.69 11.7776V11.8376C19.2988 11.9775 19.8648 12.2622 20.34 12.6676C20.777 13.0496 21.02 13.6076 21 14.1876C21.005 14.6076 20.887 15.0226 20.66 15.3776C20.43 15.7346 20.122 16.0346 19.76 16.2576C19.352 16.5106 18.907 16.6976 18.44 16.8076C17.9266 16.9357 17.3992 16.9996 16.87 16.9976C16.0838 17.0174 15.3007 16.892 14.56 16.6276C13.9698 16.4132 13.4338 16.0719 12.99 15.6276L14.11 14.2176ZM9 10.9996H5V6.99964H3V16.9996H5V12.9996H9V16.9996H11V6.99964H9V10.9996Z' />
  </SvgIcon>
)

export const H4Icon = (props) => (
  <SvgIcon {...props}>
    <path d='M11 17H9V13H5V17H3V7H5V11H9V7H11V17ZM21 15H20V17H18V15H13V13L17.05 7H20V13H21V15ZM18 13V9L15.21 13H18Z' />
  </SvgIcon>
)

export const BoldIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M15.6 10.79C16.57 10.12 17.25 9.02 17.25 8C17.25 5.74 15.5 4 13.25 4H7V18H14.04C16.13 18 17.75 16.3 17.75 14.21C17.75 12.69 16.89 11.39 15.6 10.79ZM10 6.5H13C13.83 6.5 14.5 7.17 14.5 8C14.5 8.83 13.83 9.5 13 9.5H10V6.5ZM13.5 15.5H10V12.5H13.5C14.33 12.5 15 13.17 15 14C15 14.83 14.33 15.5 13.5 15.5Z' />
  </SvgIcon>
)

export const ItalicIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M10 4V7H12.21L8.79 15H6V18H14V15H11.79L15.21 7H18V4H10Z' />
  </SvgIcon>
)

export const UnderlineIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M5 21H19V19H5V21ZM12 17C13.5913 17 15.1174 16.3679 16.2426 15.2426C17.3679 14.1174 18 12.5913 18 11V3H15.5V11C15.5 11.9283 15.1313 12.8185 14.4749 13.4749C13.8185 14.1313 12.9283 14.5 12 14.5C11.0717 14.5 10.1815 14.1313 9.52513 13.4749C8.86875 12.8185 8.5 11.9283 8.5 11V3H6V11C6 12.5913 6.63214 14.1174 7.75736 15.2426C8.88258 16.3679 10.4087 17 12 17Z' />
  </SvgIcon>
)

export const PointListIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M4 10.5C3.17 10.5 2.5 11.17 2.5 12C2.5 12.83 3.17 13.5 4 13.5C4.83 13.5 5.5 12.83 5.5 12C5.5 11.17 4.83 10.5 4 10.5ZM4 4.5C3.17 4.5 2.5 5.17 2.5 6C2.5 6.83 3.17 7.5 4 7.5C4.83 7.5 5.5 6.83 5.5 6C5.5 5.17 4.83 4.5 4 4.5ZM4 16.5C3.17 16.5 2.5 17.18 2.5 18C2.5 18.82 3.18 19.5 4 19.5C4.82 19.5 5.5 18.82 5.5 18C5.5 17.18 4.83 16.5 4 16.5ZM7 19H21V17H7V19ZM7 13H21V11H7V13ZM7 5V7H21V5H7Z' />
  </SvgIcon>
)

export const NumberedListIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M2 17H4V17.5H3V18.5H4V19H2V20H5V16H2V17ZM3 8H4V4H2V5H3V8ZM2 11H3.8L2 13.1V14H5V13H3.2L5 10.9V10H2V11ZM7 5V7H21V5H7ZM7 19H21V17H7V19ZM7 13H21V11H7V13Z' />
  </SvgIcon>
)

export const AlignTextLeftIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M15 15H3V17H15V15ZM15 7H3V9H15V7ZM3 13H21V11H3V13ZM3 21H21V19H3V21ZM3 3V5H21V3H3Z' />
  </SvgIcon>
)

export const AlignTextCenterIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M7 15V17H17V15H7ZM3 21H21V19H3V21ZM3 13H21V11H3V13ZM7 7V9H17V7H7ZM3 3V5H21V3H3Z' />
  </SvgIcon>
)

export const AlignTextRightIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M3 21H21V19H3V21ZM9 17H21V15H9V17ZM3 13H21V11H3V13ZM9 9H21V7H9V9ZM3 3V5H21V3H3Z' />
  </SvgIcon>
)

export const InsertNoteIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M20.41 8.41L15.58 3.58C15.21 3.21 14.7 3 14.17 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V9.83C21 9.3 20.79 8.79 20.41 8.41ZM7 7H14V9H7V7ZM17 17H7V15H17V17ZM17 13H7V11H17V13Z' />
  </SvgIcon>
)

export const InsertTableIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M10 10.02H15V21H10V10.02ZM17 21H20C21.1 21 22 20.1 22 19V10H17V21ZM20 3H5C3.9 3 3 3.9 3 5V8H22V5C22 3.9 21.1 3 20 3ZM3 19C3 20.1 3.9 21 5 21H8V10H3V19Z' />
  </SvgIcon>
)

export const InsertFileIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M16.5 6V17.5C16.5 19.71 14.71 21.5 12.5 21.5C10.29 21.5 8.5 19.71 8.5 17.5V5C8.5 3.62 9.62 2.5 11 2.5C12.38 2.5 13.5 3.62 13.5 5V15.5C13.5 16.05 13.05 16.5 12.5 16.5C11.95 16.5 11.5 16.05 11.5 15.5V6H10V15.5C10 16.88 11.12 18 12.5 18C13.88 18 15 16.88 15 15.5V5C15 2.79 13.21 1 11 1C8.79 1 7 2.79 7 5V17.5C7 20.54 9.46 23 12.5 23C15.54 23 18 20.54 18 17.5V6H16.5Z' />
  </SvgIcon>
)

export const InsertImageIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19ZM8.5 13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z' />
  </SvgIcon>
)

export const InsertChartIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17Z' />
  </SvgIcon>
)

export const FolderStructureIcon = (props) => (
  <SvgIcon {...props}>
    <path d='M20.5 8.5V0.5H13.5V3.5H7.5V0.5H0.5V8.5H7.5V5.5H9.5V15.5H13.5V18.5H20.5V10.5H13.5V13.5H11.5V5.5H13.5V8.5H20.5ZM5.5 6.5H2.5V2.5H5.5V6.5ZM15.5 12.5H18.5V16.5H15.5V12.5ZM15.5 2.5H18.5V6.5H15.5V2.5Z' />
  </SvgIcon>
)

export const CopyIcon = (props) => (
  <SvgIcon {...props} width='15' height='18' viewBox='0 0 15 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M11 0.75H2C1.175 0.75 0.5 1.425 0.5 2.25V12.75H2V2.25H11V0.75ZM13.25 3.75H5C4.175 3.75 3.5 4.425 3.5 5.25V15.75C3.5 16.575 4.175 17.25 5 17.25H13.25C14.075 17.25 14.75 16.575 14.75 15.75V5.25C14.75 4.425 14.075 3.75 13.25 3.75ZM13.25 15.75H5V5.25H13.25V15.75Z' fill='black' fillOpacity='0.87' />
  </SvgIcon>
)
