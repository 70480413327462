import { Grid } from '@material-ui/core'
import React from 'react'
import { useStyles } from './style'

const AutoSaveStatus = ({ isSaving, isLoading, error, hasChanges }) => {
  const classes = useStyles()
  const statusMap = React.useMemo(() => ({
    saving: {
      label: 'Saving content',
      color: '#0070BD'
    },
    hasChanges: {
      label: 'Content has unsaved changes',
      color: '#0070BD'
    },
    loading: {
      label: 'Loading content',
      color: '#0070BD'
    },
    error: {
      label: 'Unable to save content',
      color: '#B00020'
    },
    saved: {
      color: '#2B9D1D',
      label: 'All changes saved'
    }
  }), [])

  const selectedStatus = React.useMemo(() => {
    if (error) return statusMap.error
    if (isSaving) return statusMap.saving
    if (isLoading) return statusMap.loading
    if (hasChanges) return statusMap.hasChanges

    return statusMap.saved
  }, [isSaving, isLoading, error, statusMap, hasChanges])

  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item>
        <div className={classes.point} style={{ backgroundColor: selectedStatus.color }} />
      </Grid>
      <Grid item>
        <div className={classes.status} style={{ color: selectedStatus.color }} aria-live='polite' data-test='saved-changes'>
          {selectedStatus.label}
        </div>
      </Grid>
    </Grid>
  )
}

export default React.memo(AutoSaveStatus)
