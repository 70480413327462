import { ActionButton } from '@emerald-works-nova/components'
import { Box } from '@material-ui/core'
import { Auth } from '@aws-amplify/auth'
import RefreshIcon from '@material-ui/icons/Refresh'
import React from 'react'
import RolesListImg from '../../images/roles-list.png'

export const ConfirmationScreen = React.memo(() => {
  const onRefresh = () => window.location.reload()

  React.useEffect(() => { Auth.signOut({ global: true }) }, [])

  return (
    <>
      <Box display='flex' flexDirection='column' alignItems='center' mt={11.25} mb={8}>
        <Box fontSize={34} fontWeight={700} mb={1}>All good!!!</Box>
        <Box fontSize={18} mb={1}>You have successfully chosen your role on The Ark platform.</Box>
        <Box fontSize={18} mb={1}>Please wait for the settings to be updated.</Box>
        <Box fontSize={14} mb={1}>Come back later or refresh the page to see updates.</Box>
        <Box my={8}><img src={RolesListImg} /></Box>
      </Box>
      <Box display='flex' justifyContent='center' mt={8}>
        <ActionButton
          variant='primary'
          dataTest='onboard-continue'
          onClick={onRefresh}
          endIcon={<RefreshIcon />}
          size='large'
        >
          Refresh this page
        </ActionButton>
      </Box>
    </>
  )
})
