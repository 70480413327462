import { datadogRum } from '@datadog/browser-rum'
import { TenantProvider } from '@emerald-works-nova/auth'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import { version } from '../package.json'
import App from './App'
import './app.css'
import * as DomainHelpers from './helpers/domain'
import './libs/i18n'
import store from './reducers/store'
import * as serviceWorker from './serviceWorker'

if (DomainHelpers.basedURL().includes('ewnova.dev') ||
  DomainHelpers.basedURL().includes('ewnova.live') ||
  DomainHelpers.basedURL().includes('mindtools.com')) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'nova---ark',
    env: `${DomainHelpers.basedURL()}`,
    version: `v${version}`,
    trackInteractions: true
  })
  datadogRum.startSessionReplayRecording()
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <Router>
      <TenantProvider>
        <App />
      </TenantProvider>
    </Router>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
