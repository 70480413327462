import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import InfoCard from './info-card'
import CardSkeleton from './card-skeleton'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    justifyContent: 'space-between',
    padding: spacing(2)
  },
  header: {
    marginBottom: spacing(1)
  }
}))

const StatCard = ({ data, renderValue, renderChange, ...rest }) => {
  const classes = useStyles()

  return (
    <InfoCard
      {...rest}
      centerTitle
      classes={{ root: classes.root, header: classes.header }}
    >
      <Typography variant='h3' align='center'>
        {data === null ? (
          'N/A'
        ) : data === undefined ? (
          <CardSkeleton />
        ) : renderValue ? (
          renderValue(data.count)
        ) : (
          data.count
        )}
      </Typography>
      {data && renderChange && (
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          <Typography variant='subtitle2'>
            {renderChange(data.change, data.count)}
          </Typography>
        </Grid>
      )}
    </InfoCard>
  )
}

export default StatCard
