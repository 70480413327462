import { ActionButton } from '@emerald-works-nova/components'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { debounce } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { TenantListSlice } from '../../reducers'
import { routes } from '../../routes'
import { FiltersContainer as Filters, SearchFilter } from '../../components/filters'
import { useStyles } from './style'
import TenantItem from './tenant-item/tenant-item'

const TenantList = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialFilters = React.useMemo(() => ({ search: '' }), [])
  const [filters, setFilters] = React.useState(initialFilters)
  const pageSize = React.useMemo(() => 20, [])

  const tenantList = useSelector(TenantListSlice.selectors.selectTenantList)
  const tenantTotal = useSelector(TenantListSlice.selectors.selectTotal)

  const hasMoreTenants = React.useMemo(() => tenantTotal !== tenantList.length, [tenantList, tenantTotal])

  const [getTenantList, addTenantList] = useEvent([
    TenantListSlice.eventBus.getTenantList,
    TenantListSlice.eventBus.addTenantList
  ])

  const onFetchMoreTenants = React.useCallback(() => {
    addTenantList.trigger({
      from: tenantList.length,
      size: pageSize,
      filters
    })
  }, [addTenantList, tenantList, pageSize, filters])

  const isLoading = React.useMemo(() => {
    return getTenantList.isWorking || addTenantList.isWorking || !getTenantList.hasBeenTriggered
  }, [getTenantList.isWorking, getTenantList.hasBeenTriggered, addTenantList.isWorking])

  const debouncedSearch = React.useMemo(() => debounce(filters => {
    getTenantList.trigger({ size: pageSize, filters })
  }, 1000), [getTenantList, pageSize])

  React.useEffect(() => { debouncedSearch(filters) }, [debouncedSearch, filters])

  React.useEffect(() => () => dispatch(TenantListSlice.actions.resetTenantList()), [dispatch])

  const handleClearFilters = React.useCallback(() => setFilters(initialFilters), [setFilters, initialFilters])

  const handleTenantClick = React.useCallback(tenant => {
    navigate(generatePath(routes.tenantDetails.path, { id: tenant.pk }), { state: { tenant } })
  }, [navigate])

  const handleTenantKeyPress = React.useCallback((e, tenant) => e.which === 13 && handleTenantClick(tenant), [handleTenantClick])

  return (
    <>
      <Grid container classes={{ root: classes.header }}>
        <Grid container direction='row' alignItems='center'>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography id='tenant-list-title' tabIndex={0} component='div' variant='h5'>
            Client Toolkits
              {!!tenantTotal && <Typography component='span' variant='subtitle1' className={classes.contentTotal}> {`(${tenantTotal})`} </Typography>}
            </Typography>
          </Grid>
          <Filters filters={filters} onChange={setFilters} onClear={handleClearFilters}>
            <SearchFilter name='search' />
          </Filters>
          <Grid item>
            <ActionButton
              id='create-tenant-button'
              variant='primary'
              name='create-content'
              aria-label='create new content'
              onClick={() => navigate(routes.tenantNew.path)}
            > New Toolkit
            </ActionButton>
          </Grid>
        </Grid>
      </Grid>

      <Paper
        id='tenant-list'
        classes={{ root: classes.tablePaper }}
        tabIndex={0}
        aria-label='Content List'
        elevation={0}
      >
        <Grid
          id='tenant-list-headers'
          container
          tabIndex={0}
          direction='row'
          alignItems='center'
          className={classes.gridHeader}
          classes={{ root: classes.tableHead }}
          aria-label='List headers, Title, Type, Last update'
        >
          <Grid item md={4}>Name</Grid>
          <Grid item md={4}>Client</Grid>
          <Grid item md={2}>Creation Date</Grid>
          <Grid item md={2}>Status</Grid>
        </Grid>
        {!isLoading && tenantList.map((row, key) => (
          <TenantItem
            key={key}
            index={key}
            tenant={row}
            classes={classes}
            lastOne={key === tenantList.length - 1}
            onClick={handleTenantClick}
            onKeyPress={handleTenantKeyPress}
          />
        ))}
        {isLoading && (
          <Grid
            id='tenant-list-loading'
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            classes={{ root: classes.loadingAnimation }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
        {!isLoading && !tenantList.length && (
          <Grid
            id='tenant-list-empty'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.emptyList }}
          >
            <Typography variant='h5'> No tenant found! </Typography>
          </Grid>
        )}
      </Paper>
      {!isLoading && hasMoreTenants && (
        <Grid
          id='tenant-list-load-more'
          container
          direction='row'
          justifyContent='center'
          align='center'
          classes={{ root: classes.loadMoreBtn }}
        >
          <Grid item xs={12}>
            <Button variant='contained' color='primary' name='' onClick={onFetchMoreTenants}>
              Load More
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default TenantList
