import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  loadingCtn: { minHeight: 500 },
  controlBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  moreVertBtn: {
    marginRight: spacing(3),
    padding: spacing(0.75),
    minWidth: 'initial'
  },
  btnDivider: {
    height: '45%',
    backgroundColor: 'rgba(0,0,0,0.38)',
    width: 2,
    margin: '0 5%',
    alignSelf: 'center'
  },
  tabWrapper: {
    flexDirection: 'row',
    textTransform: 'none',
    '& svg': {
      marginRight: spacing(1.5)
    }
  },
  tabs: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  actionBtnCtn: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 16
  },
  doneBtn: {
    marginRight: 16,
    minWidth: 100
  },
  nextBtn: { minWidth: 100 },
  optionsMenu: {
    marginTop: spacing(1),
    minWidth: 152,
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)'
  },
  deleteOptions: {
    textTransform: 'capitalize',
    fontSize: 14,
    color: palette.error.main
  },
  backdrop: {
    zIndex: 1,
    backgroundColor: palette.backdrop.main
  },
  loadingMsg: {
    marginTop: spacing(4),
    fontSize: spacing(2)
  }
}))
