import { ActionButton, ConfirmationAlert } from '@emerald-works-nova/components'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'
import {
  BannerStatic, ContentBasicInformationForm, FormSectionTitle, Panel, SectionHeader
} from '../../components'
import { ContentSlice } from '../../reducers'
import { useDispatch } from 'react-redux'
import { routes } from '../../routes'
import { useStyles } from './style'

const ContentNew = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [showDiscardAlert, setShowDiscardAlert] = React.useState(false)
  const form = useForm()
  const dispatch = useDispatch()
  const { isDirty, errors } = form.formState

  const [saveContent] = useEvent([
    ContentSlice.eventBus.saveContent
  ])

  const watchTitle = form.watch('title', '')
  useEffect(() => {
    form.setValue('staticUrl', watchTitle)
  }, [form, watchTitle])

  const watchStaticUrl = form.watch('staticUrl', '')
  useEffect(() => {
    const sanitized = watchStaticUrl.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
    form.setValue('staticUrl', sanitized)
  }, [form, watchStaticUrl])

  useEventsOnViewLoad(() => {
    saveContent.registerAdhocOnSuccessListener(({ payload: { content } }) => {
      BannerStatic.show({
        label: `"${content.title}" was created in the Content list!`
      })
      form.reset()
      dispatch(ContentSlice.actions.addContentToList(content))
      dispatch(ContentSlice.actions.setContent({ content }))
      const contentUrl = generatePath(routes.contentEdit.path, {
        locationId: content.locationId
      })
      navigate(contentUrl)
    })
  }, [saveContent])

  const handleDiscard = () => {
    form.reset()
    navigate(routes.contentList.path)
  }

  const handleDiscardAlert = () => {
    const formValues = form.getValues()

    // contentType has default value
    delete formValues.contentType

    // TODO: check inner object emptys
    // subtitle.html
    const isEmpty = Object.values(formValues).every(p => (p === null || p === ''))

    if (isDirty && !isEmpty) {
      setShowDiscardAlert(true)
    } else {
      navigate(routes.contentList.path)
    }
  }

  const onSubmit = (data) => {
    saveContent.trigger(data)
  }

  return (
    <div className={classes.root}>
      <form noValidate autoComplete='off' onSubmit={form.handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          className={classes.header}
        >
          <Grid item>
            <SectionHeader
              title='New content'
              location='Content / '
            />
          </Grid>
          <Grid item>
            <ActionButton
              variant='secondary'
              dataTest='create-content-discard-button'
              style={{ marginRight: 22 }}
              onClick={handleDiscardAlert}
            >
              Discard
            </ActionButton>
            <ActionButton
              variant='primary'
              dataTest='next'
              type='submit'
              isWorking={saveContent.isWorking}
            >
              Next
            </ActionButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Panel maxWidth={1024}>
              <div className={classes.formHeader}>
                <FormSectionTitle> Basic Information </FormSectionTitle>
              </div>
              <ContentBasicInformationForm {...form} errors={errors} />
            </Panel>
          </Grid>
        </Grid>
      </form>
      <ConfirmationAlert
        title='Discard this new content?'
        description={'If you discard this new content, you\'ll lose all the information that you added.'}
        agreeText='Discard'
        showModal={showDiscardAlert}
        onConfirmation={handleDiscard}
        onCancel={() => setShowDiscardAlert(false)}
      />
    </div>
  )
}

export default ContentNew
