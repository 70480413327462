import { Skeleton } from '@material-ui/lab'

import React from 'react'

const CardSkeleton = (props) => {
  return (
    <Skeleton width='50%' style={{ margin: 'auto' }} {...props} />
  )
}

export default CardSkeleton
