import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { NoThumbnailImage } from '../../../components/icons'
import TypeContentIcon from '../type-content-icon'
import { useStyles } from './style'

const CatalogItem = React.memo(({ article, index, lastOne, onClick, nameAdornment = null, onKeyPress }) => {
  const classes = useStyles()
  const { palette } = useTheme()

  const rootClass = classNames(
    classes.gridRow,
    { [classes.gridRowLast]: lastOne }
  )

  const getThumbnailImage = () => {
    if (article.image?.imgUrl) {
      return (
        <Box data-test={`content-thumbnail-${index}`}>
          <img alt='Content' src={article.image.imgUrl + 'fit=crop&w=40&h=40'} className={classes.cardImage} />
        </Box>
      )
    }

    return (
      <div className={classes.noImageIconWrapper}>
        <NoThumbnailImage />
      </div>
    )
  }

  return (
    <Grid
      role='row button'
      data-test={`catalog-list-item-${index}`}
      container
      tabIndex={0}
      direction='row'
      alignItems='center'
      classes={{ root: rootClass }}
      onClick={() => onClick(article)}
      onKeyPress={(e) => onKeyPress(e, article)}
      style={{ '--type-bgcolor': palette.contentType[article.primaryType[0]] }}
    >
      <Grid item md={8} className={classes.contentColumn}>
        <Box display='block'>
          {getThumbnailImage()}
          <Box display='flex' flexDirection='column' flex={1}>
            <Typography variant='body2' data-test={`content-title-${index}`} style={{ display: 'flex' }}>{article.title} {nameAdornment}</Typography>
            <Typography variant='body2' noWrap className={classes.textSummary} data-test={`content-summary-${index}`}>{article.subtitle.html}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item md={2} className={classes.typeColumn} style={{ '--type-color': palette.contentType[article.primaryType[0]] }} data-test='content-type'>
        <TypeContentIcon type={article.primaryType[0]} />
        <span>{article.primaryType[0]}</span>
      </Grid>
      <Grid item md={2} className={classes.dateUpdatedColumn} data-test={`content-lastupdate-${index}`} title={format(parseISO(article.updatedAt), 'PPpppp')}>
        {parseISO(article.updatedAt).toLocaleDateString()}
      </Grid>
    </Grid>
  )
})

export default CatalogItem
