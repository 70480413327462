import React from 'react'
import { CompositeDecorator } from 'draft-js'

const Link = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData()
  return (
    <a href={url}>
      {children}
    </a>
  )
}

const Image = ({ contentState, entityKey }) => {
  const {
    height,
    src,
    width,
    alt = 'There is no description for this image'
  } = contentState.getEntity(entityKey).getData()

  return (
    <img src={src} height={height} width={width} alt={alt} />
  )
}

const imageEntities = (block, callback, contentState) => {
  block.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity()
      return (
        entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'IMAGE'
      )
    },
    callback
  )
}

const linkEntities = (block, callback, contentState) => {
  block.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity()
      return (
        entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
      )
    },
    callback
  )
}

const decorator = new CompositeDecorator([
  {
    strategy: linkEntities,
    component: Link
  },
  {
    strategy: imageEntities,
    component: Image
  }
])

export default decorator
