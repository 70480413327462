import { ContentFormats } from '@emerald-works-nova/components'
import { Grid, Typography, useTheme } from '@material-ui/core'
import { useStyles } from './style'

const ContentTypeIcon = ({ type = 'article', showDescription = false, secondaryType }) => {
  const selectedFormat = ContentFormats.find(f => f.name === type)
  const selectedType = selectedFormat.types.find(t => t.name === secondaryType)

  const { palette } = useTheme()

  const classes = useStyles({
    color: palette.contentType[selectedFormat.name]
  })

  return (
    <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
      <Grid item className={classes.icon}>{selectedFormat.icon}</Grid>
      <Grid item className={classes.labelCtn} aria-label={`content type, ${selectedFormat.label}`}>
        <Typography variant='body1' className={classes.label}>{selectedType?.label ?? selectedFormat.label}</Typography>
        {selectedType ? <Typography variant='caption' className={classes.subType}>{selectedFormat.label}</Typography> : <span>&nbsp;</span>}
        {showDescription && <Typography variant='body2' className={classes.description}>{selectedFormat.description}</Typography>}
      </Grid>
    </Grid>
  )
}

export default ContentTypeIcon
