import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { ArrowUpward } from '@material-ui/icons'
import React from 'react'
import CatalogItem from '../catalog-item'
import { useManageCategoryContext } from '../context'
import { useStyles } from './style'

const ContentTab = () => {
  const classes = useStyles()
  const {
    categoryContent,
    selectedContent,
    handleSelect,
    loadingCategoryContent
  } = useManageCategoryContext()

  if (loadingCategoryContent) {
    return (
      <Grid
        id='catalog-list-loading'
        container
        direction='row'
        justifyContent='center'
        alignContent='center'
        classes={{ root: classes.loadingAnimation }}
      >
        <CircularProgress size={60} color='primary' />
      </Grid>
    )
  }

  return (
    <Grid container className={classes.root}>
      <Paper
        id='catalog-list'
        classes={{ root: classes.tablePaper }}
        tabIndex={0}
        aria-label='Content List'
        elevation={0}
      >
        {!!categoryContent.length && (
          <Grid
            id='catalog-list-headers'
            container
            tabIndex={0}
            direction='row'
            alignItems='center'
            className={classes.gridHeader}
            classes={{ root: classes.tableHead }}
            aria-label='List headers, Title, Type, Last update'
          >
            <Grid item md={8}>Title</Grid>
            <Grid item md={2}>Type</Grid>
            <Grid item md={2} className={classes.dateUpdatedHeader}>
              <ArrowUpward fontSize='small' />
              <span>Last update</span>
            </Grid>
          </Grid>
        )}
        {categoryContent.map((row, key) => (
          <CatalogItem
            key={key}
            index={key}
            article={row}
            classes={classes}
            lastOne={key === categoryContent.length - 1}
            checked={selectedContent[row.sk]}
            onChange={ev => handleSelect(ev.target, row)}
            name={row.sk}
          />
        ))}
        {!categoryContent.length && (
          <Grid
            id='catalog-list-empty'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.emptyList }}
          >
            <Typography variant='h5'> No results found! </Typography>
          </Grid>
        )}
      </Paper>
    </Grid>
  )
}

export default ContentTab
