import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const content = createSlice({
  name,
  initialState: {
    user: undefined
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload }
    },
    setUserProfile: (state, { payload }) => {
      state.user = { ...state.user, profile: { ...payload } }
    },
    setUserRole: (state, { payload }) => {
      state.user = { ...state.user, roles: [payload.role] }
    },
    resetUserDetails: (state) => {
      state.user = undefined
    }
  }
})

export const { setUser, setUserProfile, setUserRole, resetUserDetails } = content.actions

export default content.reducer
