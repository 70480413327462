import { Chip } from '@material-ui/core'
import React from 'react'
import basePalette from '../../../../theme/palette/base-palette'
import { makeStyles } from '@material-ui/core/styles'

const StatusMap = {
  active: {
    name: 'Active',
    color: basePalette.success.main
  },
  canceled: {
    name: 'Canceled',
    color: basePalette.error.dark
  },
  expired: {
    name: 'Expired',
    color: basePalette.warning.dark
  },
  failed: {
    name: 'Failed',
    color: basePalette.error.main
  },
  future: {
    name: 'Future',
    color: basePalette.info.main
  },
  paused: {
    name: 'Paused',
    color: basePalette.warning.main
  }
}

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    backgroundColor: ({ status }) => StatusMap[status].color,
    color: palette.white.main,
    paddingInline: spacing(2.5)
  }
}))

const Status = ({ status }) => {
  const classes = useStyles({ status })
  return (
    <Chip
      size='small'
      label={StatusMap[status].name}
      className={classes.root}
    />
  )
}

export default Status
