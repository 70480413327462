import { makeStyles } from '@material-ui/core/styles'
import * as colors from '@material-ui/core/colors'

export const useStyles = makeStyles(({ palette, spacing }) => ({
  appBar: {
    background: colors.common.white,
    position: 'relative'
  },
  toolbar: {
    alignItems: 'stretch'
  },
  toolbarGutters: {
    padding: spacing(0, 5)
  },
  toolbarTitle: {
    color: palette.text.disabled,
    marginRight: '24px'
  },
  toolbarItem: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    padding: spacing(0, 2),
    marginRight: spacing(3),
    color: palette.text.disabled
  },
  activeLink: {
    color: palette.text.primary,
    borderBottom: `${spacing(0.5)}px solid ${palette.primary.main}`
  },
  toolbarIcons: {
    color: palette.text.secondary
  }
}))
