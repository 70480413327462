const options = {
  plugins: {
    tooltip: {
      enabled: true,
      callbacks: {
        label: context => {
          const total = context.dataset.data.reduce(
            (acc, curr) => acc + curr,
            0
          )
          const val = context.raw
          const percentage = Math.round((val / total) * 100)

          return [
            `${percentage}% (${val}) of potential users`,
            `${
              context.dataIndex === 1
                ? 'have registered up to date.'
                : 'are unregistered.'
            }`
          ]
        }
      }
    }
  }
}

export default options
