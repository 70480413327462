import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  tabWrapper: {
    flexDirection: 'row',
    textTransform: 'none',
    '& svg': {
      marginRight: spacing(1.5)
    }
  },
  tabs: {
    flexGrow: 1,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}))
