
import { Box, Grid, Typography } from '@material-ui/core'
import { debounce } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FiltersContainer as Filters, SearchFilter } from '../../components/filters'
import { UserListSlice } from '../../reducers'
import { ArkUserList, ArkUserListByRole, ArkUserListPending, DepartmentMenu } from './components'
import { useStyles } from './style'

const initialFilters = { search: '' }
const formatEmailSearch = term => term ? 'email ^= "' + term + '"' : ''

const ArkUsers = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [filters, setFilters] = React.useState(initialFilters)
  const [roleFilter, setRoleFilter] = React.useState('all')
  const [searchTerm, setSearchTerm] = React.useState(formatEmailSearch())
  const total = useSelector(UserListSlice.selectors.selectTotal)
  const users = useSelector(UserListSlice.selectors.selectUserList)
  // this page will only appear to admins and those users will always be using the `admin` tenant

  React.useEffect(() => () => dispatch(UserListSlice.actions.resetUserList()), [dispatch])

  const handleClearFilters = React.useCallback(() => setFilters(initialFilters), [setFilters])

  const debouncedSearch = React.useMemo(() => debounce(term => { setSearchTerm(formatEmailSearch(term)) }, 1000), [setSearchTerm])
  React.useEffect(() => { debouncedSearch(filters.search) }, [debouncedSearch, filters.search])
  const hasRoleSearch = React.useMemo(() => roleFilter !== 'all', [roleFilter])

  const UserList = () => {
    if (hasRoleSearch) {
      return roleFilter === 'pending' ? <ArkUserListPending filter={roleFilter} /> : <ArkUserListByRole filter={roleFilter} />
    }

    return <ArkUserList filter={searchTerm} />
  }

  return (
    <Box display='flex'>
      <Box marginRight={3}><DepartmentMenu onChange={setRoleFilter} value={roleFilter} /></Box>
      <Box flexGrow={1}>
        <Grid container classes={{ root: classes.header }}>
          <Grid container direction='row' alignItems='center'>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography id='tenant-list-title' tabIndex={0} component='div' variant='h5'>
                All Users
                <Typography
                  component='span'
                  variant='subtitle1'
                  className={classes.userTotal}
                >
                  {`(${searchTerm ? users.length : total})`}
                </Typography>
              </Typography>
            </Grid>
            <Filters filters={filters} onChange={setFilters} onClear={handleClearFilters}>
              <SearchFilter name='search' label='Search (email)' disabled={hasRoleSearch} />
            </Filters>
          </Grid>
        </Grid>
        {UserList()}
      </Box>
    </Box>
  )
}

export default ArkUsers
