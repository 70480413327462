import * as actions from './slice'

const getGroupCategoriesByTemplate = {
  eventName: 'catGetGroupCatByTemplate',
  onSuccess: {
    action: actions.getGroupCategoriesByTemplate,
    redux: true
  }
}

export {
  getGroupCategoriesByTemplate
}
