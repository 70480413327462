import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  controlBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  deleteBtn: {
    marginRight: spacing(2),
    minWidth: 'initial',
    color: palette.error.main
  },
  backBtn: {
    marginRight: spacing(2)
  },
  loadingSaveBtn: {
    marginLeft: spacing(1)
  },
  btnDivider: {
    height: '45%',
    backgroundColor: 'rgba(0,0,0,0.38)',
    width: 2,
    margin: '0 2.5%',
    alignSelf: 'center'
  },
  tabWrapper: {
    flexDirection: 'row',
    textTransform: 'none',
    '& svg': {
      marginRight: spacing(1.5)
    }
  },
  tabs: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  skeleton: {
    borderRadius: 4,
    flexGrow: 1
  },
  optionsMenu: {
    marginTop: spacing(1),
    minWidth: 152,
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)'
  },
  deleteOptions: {
    textTransform: 'capitalize',
    fontSize: 14,
    color: palette.error.main
  }
}))
