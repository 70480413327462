import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    height: '80vh'
  },
  codeWrapper: {
    overflowY: 'auto',
    maxHeight: '75vh'
  }
}))
