import { ContentTypeFilter } from '@emerald-works-nova/components'
import { Button, Grid, useTheme } from '@material-ui/core'
import React from 'react'
import { DateFilter, MigrationFilter, ReviewFilter, SearchFilter } from '../../../components/content-filters'
import { useStyles } from './style'

const FiltersContainer = React.memo(({ filters, onChange, onClear, clearBtnEnd }) => {
  const classes = useStyles()
  const { spacing } = useTheme()

  const hasFilters = React.useMemo(() => Object.values(filters).some(v => {
    if (Array.isArray(v)) return v.length > 0
    return !!v
  }), [filters])

  const handleChangeFilter = (filter, value) => onChange({ ...filters, [filter]: value })
  const typeRef = React.useRef()

  const clearFilter = React.useCallback(() => {
    typeRef.current.clear()
    onClear()
  }, [onClear, typeRef])

  const ClearBtn = () => (
    <Button
      id='catalog-clear-filters-button'
      color='primary'
      aria-label='clear filters'
      onClick={clearFilter}
      style={{ marginRight: spacing(2) }}
    >
        Clear filters
    </Button>
  )

  return (
    <Grid item aria-label='List filters' tabIndex={0} classes={{ root: classes.filtersGrid }}>
      {hasFilters && !clearBtnEnd && <ClearBtn />}
      <SearchFilter value={filters.search} onChange={value => handleChangeFilter('search', value)} />
      <ContentTypeFilter value={filters.type} onChange={({ value }) => handleChangeFilter('type', value)} ref={typeRef} variant='secondary' />
      <DateFilter value={filters.dateStart} onChange={value => handleChangeFilter('dateStart', value)} />
      <MigrationFilter value={filters.migrationStatus} onChange={value => handleChangeFilter('migrationStatus', value)} />
      <ReviewFilter value={filters.reviewStatus} onChange={value => handleChangeFilter('reviewStatus', value)} />
      {hasFilters && clearBtnEnd && <ClearBtn />}
    </Grid>
  )
})

export default FiltersContainer
