
const options = {
  responsive: true,
  borderWidth: 0,
  borderAlign: 'center',
  plugins: {
    tooltip: {
      enabled: true,
      callbacks: {
        label: context => {
          const { label, raw, dataset } = context
          const total = dataset.data.reduce((acc, curr) => acc + curr, 0)
          return [
            `${Math.round((raw / total) * 100)}% (${raw}) of all resources viewed are`,
            `are ${label.toLowerCase()} content.`
          ]
        }
      }
    }
  }
}

export default options
