import * as actions from './slice'

const getTemplates = {
  eventName: 'templateListAll',
  onSuccess: {
    action: actions.setTemplateList,
    redux: true
  }
}

export {
  getTemplates
}
