import * as actions from './slice'

const getContentTab = {
  eventName: 'reportingGetContent',
  onSuccess: [
    {
      action: actions.setContentTab,
      redux: true
    }
  ]
}
const getUsageTab = {
  eventName: 'reportingGetUsage',
  onSuccess: [
    {
      action: actions.setUsageTab,
      redux: true
    }
  ]
}

export {
  getContentTab,
  getUsageTab
}
