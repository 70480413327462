import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    minHeight: 400,
    '&::before': {
      content: 'close-quote',
      position: 'absolute',
      borderRadius: spacing(0, 2, 2, 0),
      width: spacing(0.75),
      height: ({ rows }) => rows ? `calc(390px + ${rows} * 100px + 20px)` : '390px',
      display: ({ isLoading }) => isLoading ? 'none' : 'inherit',
      backgroundColor: ({ enabled }) => enabled ? palette.primary.light : palette.grey.dark
    },
    pointerEvents: ({ isLoading }) => isLoading ? 'none' : 'initial'
  },
  paper: {
    padding: spacing(0, 6)
  },
  suscription: {
    color: ({ suscribed }) => suscribed ? palette.primary.light : palette.grey.dark
  },
  role: {
    textTransform: 'capitalize'
  },
  loadingAnimation: {
    minHeight: 400
  }
}))
