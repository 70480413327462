import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    minHeight: 350
  },
  titleCtn: {
    marginBottom: spacing(3)
  },
  selectedContentRoot: {
    marginBottom: spacing(3)
  },
  dashedDivider: {
    marginBottom: spacing(3),
    borderTop: '2px dashed rgba(0, 0, 0, 0.12)',
    background: 'transparent'
  },
  // Content list styles
  gridHeader: {
    height: 60,
    borderBottom: '1px solid #E0E0E0',
    paddingLeft: spacing(3)
  },
  tablePaper: {
    width: '100%',
    padding: spacing(2.5)
  },
  tableHead: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 0.25
  },
  dateUpdatedHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginRight: spacing(1)
    }
  },
  loadMoreBtn: {
    margin: spacing(5, 0)
  },
  loadingAnimation: {
    margin: spacing(5, 0),
    height: '50vh'
  },
  emptyList: {
    minHeight: spacing(30),
    color: palette.text.secondary
  },
  filtersCtn: {
    marginTop: spacing(2)
  },
  filtersGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
    // marginRight: spacing(3)
  },
  mainPromotedIcon: {
    color: '#FF9900',
    marginLeft: spacing(0.5)
  },
  mainPromotedText: {
    color: '#666666',
    marginLeft: spacing(0.5)
  }
}))
