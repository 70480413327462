import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { ArrowUpward } from '@material-ui/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import ManageCategorySlice from '../../../reducers/manage-category'
import CatalogItem from '../catalog-item'
import { useManageCategoryContext } from '../context'
import { Filters } from './filters'
import { useStyles } from './style'
import { debounce } from 'lodash'
import { BannerStatic } from '../..'

const CatalogTab = () => {
  const classes = useStyles()
  const { selectedContent, handleSelect } = useManageCategoryContext()

  const pageSize = 10
  const initialFilters = React.useMemo(() => ({ search: '', type: '', dateStart: null, dateEnd: null }), [])
  const [filters, setFilters] = React.useState(initialFilters)

  const contentList = useSelector(ManageCategorySlice.selectors.selectCatalogList)
  const contentTotal = useSelector(ManageCategorySlice.selectors.selectCatalogListTotal)

  const [getContentList, fetchMoreContent] = useEvent([
    ManageCategorySlice.eventBus.getCatalogContent,
    ManageCategorySlice.eventBus.fetchCatalogContent
  ])

  useEventsOnViewLoad(() => {
    getContentList.registerAdhocOnErrorListener(e => {
      console.log('getContentList.registerAdhocOnErrorListener', e)
      BannerStatic.show({ label: 'Error retriving content', isError: true })
    })
    fetchMoreContent.registerAdhocOnErrorListener(e => {
      console.log('fetchMoreContent.registerAdhocOnErrorListener', e)
      BannerStatic.show({ label: 'Error fetching more content', isError: true })
    })
  }, [getContentList, fetchMoreContent])

  React.useEffect(() => {
    if (contentList?.length) return
    getContentList.trigger({ size: pageSize, filters: {} })
  }, [getContentList, contentList])

  const hasMoreContent = React.useMemo(() => contentTotal !== contentList.length, [contentList, contentTotal])

  const onFetchMoreContent = React.useCallback(() => {
    fetchMoreContent.trigger({
      from: contentList.length,
      size: pageSize,
      filters
    })
  }, [fetchMoreContent, contentList, filters, pageSize])

  const debouncedSearch = React.useMemo(() => debounce(filters => {
    getContentList.trigger({ size: pageSize, filters })
  }, 1000), [getContentList])

  const handleChangeFilter = React.useCallback(filters => {
    setFilters(filters)
    debouncedSearch(filters)
  }, [setFilters, debouncedSearch])

  const handleClearFilters = React.useCallback(() => {
    setFilters(initialFilters)
    getContentList.trigger({ size: pageSize, filters: initialFilters })
  }, [setFilters, getContentList, initialFilters])

  return (
    <Grid container className={classes.root}>
      <Paper
        id='catalog-list'
        classes={{ root: classes.tablePaper }}
        tabIndex={0}
        aria-label='Content List'
        elevation={0}
      >
        <Grid container className={classes.filtersCtn}>
          <Filters filters={filters} onChange={handleChangeFilter} onClear={handleClearFilters} clearBtnEnd />
        </Grid>
        {!getContentList.isWorking && !!contentList.length && (
          <Grid
            id='catalog-list-headers'
            container
            tabIndex={0}
            direction='row'
            alignItems='center'
            className={classes.gridHeader}
            classes={{ root: classes.tableHead }}
            aria-label='List headers, Title, Type, Last update'
          >
            <Grid item md={8}>Title</Grid>
            <Grid item md={2}>Type</Grid>
            <Grid item md={2} className={classes.dateUpdatedHeader}>
              <ArrowUpward fontSize='small' />
              <span>Last update</span>
            </Grid>
          </Grid>
        )}
        {!getContentList.isWorking && contentList.map((row, key) => (
          <CatalogItem
            key={key}
            index={key}
            article={row}
            classes={classes}
            lastOne={key === contentList.length - 1}
            checked={selectedContent[row.sk]}
            onChange={ev => handleSelect(ev.target, row)}
            name={row.sk}
          />
        ))}
        {getContentList.isWorking && (
          <Grid
            id='catalog-list-loading'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.loadingAnimation }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
        {fetchMoreContent.isWorking && (
          <Grid
            id='catalog-list-loading'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.loadingAnimation }}
            style={{ height: 150 }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
        {getContentList.hasBeenTriggered && !getContentList.isWorking && !fetchMoreContent.isWorking && !contentList.length && (
          <Grid
            id='catalog-list-empty'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.emptyList }}
          >
            <Typography variant='h5'> No results found! </Typography>
          </Grid>
        )}
      </Paper>
      {!getContentList.isWorking && !fetchMoreContent.isWorking && hasMoreContent && (
        <Grid
          id='catalog-list-load-more'
          container
          direction='row'
          justifyContent='center'
          align='center'
          classes={{ root: classes.loadMoreBtn }}
        >
          <Grid item xs={12}>
            <Button variant='contained' color='primary' name='' onClick={onFetchMoreContent}>
              Load More
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default CatalogTab
