import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    border: `1px solid ${palette.text.hint}`,
    borderRadius: 6,
    background: 'rgba(33, 33, 33, 0.03)',
    padding: spacing(2),
    marginTop: spacing(2)
  },
  inputContainer: {
    padding: spacing(1),
    '&:first-child': {
      paddingLeft: 0
    },
    '&:last-child': {
      paddingRight: 0
    }
  },
  input: {
    background: palette.white.main
  },
  addAttrBtn: {
    textTransform: 'capitalize',
    background: palette.white.main
  },
  removeBtn: {
    textTransform: 'capitalize'
  }
}))
