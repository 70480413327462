import { Button, Grid } from '@material-ui/core'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getAttributeList } from './cognito-atributes'
import { InputTupple } from './input-tuple'
import { useStyles } from './style'

const SamlAttributeInput = React.memo(({ provider, setValue }) => {
  const classes = useStyles()
  const attributeList = React.useMemo(() => getAttributeList(), [])

  const [attributes, setAttributes] = React.useState([])

  React.useEffect(() => {
    if (!provider) return
    const ssoAttributes = provider.attributeMapping
    if (ssoAttributes?.length > 0) {
      setAttributes(ssoAttributes)
      attributeList.forEach(attr => {
        attr.disabled = !!ssoAttributes.find(ssoAttr => attr.name === ssoAttr.name)
      })
    }
  }, [provider, attributeList])

  const addAttribute = React.useCallback(() => {
    setAttributes(state => {
      const lastAttribute = state[(state.length - 1)]
      if (lastAttribute && !lastAttribute?.name) return [...state]
      return [...state, { key: uuidv4() }]
    })
  }, [setAttributes])

  const removeAttribute = React.useCallback((index) => {
    setAttributes(state => {
      const [deletedAttr] = state.splice(index, 1)
      if (deletedAttr) attributeList.find(attr => attr.name === deletedAttr.name).disabled = false
      setValue('attributeMapping', state)
      return [...state]
    })
  }, [setAttributes, setValue, attributeList])

  const onChangeAttributes = React.useCallback((index, { name, value, oldName }) => {
    setAttributes(state => {
      state[index] = { ...state[index], [name]: value }
      setValue('attributeMapping', state)
      if (name === 'name') attributeList.find(attr => attr.name === value).disabled = true
      if (oldName) attributeList.find(attr => attr.name === oldName).disabled = false
      return [...state]
    })
  }, [setAttributes, setValue, attributeList])

  return (
    <Grid container direction='column' className={classes.root}>
      {attributes.map((attr, index) => (
        <InputTupple
          key={attr.key}
          attribute={attr}
          attributeList={attributeList}
          onRemove={() => removeAttribute(index)}
          onChange={(ev) => onChangeAttributes(index, ev)}
        />
      ))}

      <Grid>
        <Button
          variant='outlined'
          data-test='add-attribute-button'
          onClick={addAttribute}
          style={{ marginTop: 8 }}
          className={classes.addAttrBtn}
        >
          Add Attribute
        </Button>
      </Grid>
    </Grid>
  )
})

export { SamlAttributeInput }
