import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  loadingCtn: { minHeight: 500 },
  controlBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  moreVertBtn: {
    marginRight: spacing(3),
    padding: spacing(0.75),
    minWidth: 'initial'
  },
  btnDivider: {
    height: '45%',
    backgroundColor: 'rgba(0,0,0,0.38)',
    width: 2,
    margin: '0 5%',
    alignSelf: 'center'
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    textTransform: 'none',
    '&&& svg': {
      marginBottom: 0,
      marginRight: spacing(1.5)
    }
  },
  tabDisabled: {
    color: palette.text.disabled,
    pointerEvents: 'none'
  },
  clickDisabled: {
    pointerEvents: 'none'
  },
  tabs: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  backdrop: {
    zIndex: 1,
    backgroundColor: palette.backdrop.main
  },
  loadingMsg: {
    marginTop: spacing(4),
    fontSize: spacing(2)
  },
  tabPanel: {
    minHeight: 400
  }
}))
