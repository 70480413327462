import { useSession } from '@emerald-works/react-auth'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import React, { createContext, useContext } from 'react'
import { AppSlice, UserProfileSlice } from '../reducers'

export const EventContext = createContext(null)

export const useEventContext = () => {
  return useContext(EventContext)
}

export const EventContextProvider = ({ children }) => {
  const [getEnvInfo] = useEvent([AppSlice.eventBus.getEnvInfo])
  const [getUserProfile] = useEvent([UserProfileSlice.eventBus.getUserProfile])

  const { isAuthenticated } = useSession()

  useEventsOnViewLoad(() => {
    if (isAuthenticated) {
      getEnvInfo.trigger()
      getUserProfile.trigger()
    }
  }, [isAuthenticated, getEnvInfo, getUserProfile])

  return (
    <EventContext.Provider value={{ getEnvInfo, getUserProfile }}>
      {children}
    </EventContext.Provider>
  )
}
