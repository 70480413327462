import { Description, EmojiObjects, LibraryBooks, MusicNote, OndemandVideo, Timeline } from '@material-ui/icons'
import React from 'react'

const TypeContentIcon = ({ type }) => {
  switch (type) {
    case 'audio': return <MusicNote />
    case 'video': return <OndemandVideo />
    case 'infographic': return <Timeline />
    case 'self-assessment': return <EmojiObjects />
    case 'exercise': return <LibraryBooks />
    default: return <Description />
  }
}

export default TypeContentIcon
