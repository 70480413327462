import React from 'react'
import { defaults } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Box, useTheme } from '@material-ui/core'
import options from './options'
import Legend from '../legend'

const labels = ['Potential audience', 'Actual audience']
const loadingLabels = [null, null]
const loadingData = [100, 120]

const EngagementChart = ({ data: { registeredUsers, usersLimit }, loading }) => {
  const { palette } = useTheme()
  const colors = [palette.grey[200], palette.secondary.light]
  const fallbackColors = [palette.grey[100], palette.grey[300]]

  const data = usersLimit
    ? [usersLimit - registeredUsers, registeredUsers]
    : [registeredUsers * 0.8, registeredUsers]

  const chartData = {
    datasets: [
      {
        data: loading ? loadingData : data,
        backgroundColor: usersLimit && !loading ? colors : fallbackColors,
        borderRadius: [0, 20],
        borderWidth: [0, 0],
        borderJoinStyle: 'round',
        offset: -10,
        cutout: '80%',
        rotation: 0
      }
    ]
  }

  return (
    <Box mt={8}>
      <Doughnut
        options={{ ...options, events: loading || !usersLimit ? [] : defaults.events }}
        data={chartData}
        height='100%'
      />
      <Box mt={4}>
        {loading ? (
          <Legend fullWidth labels={loadingLabels} colors={fallbackColors} />
        ) : usersLimit ? (
          <Legend fullWidth labels={labels} colors={colors} />
        ) : (
          <Legend
            labels={[
              'Details missing. Please provide us with an up to date number of users with access.',
              `${registeredUsers} users have registered up to date.`
            ]}
            colors={[]}
          />
        )}
      </Box>
    </Box>
  )
}

export default EngagementChart
