import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './style'
import React from 'react'
import { capitalize } from 'lodash'

const ConsumerItem = ({ user, index, onClick, onKeyPress }) => {
  const classes = useStyles({ enabled: user.enabled })

  return (
    <Grid
      role='row button'
      data-test={`user-list-item-${index}`}
      container
      tabIndex={0}
      direction='row'
      alignItems='center'
      classes={{ root: classes.root }}
      onClick={() => onClick(user)}
      onKeyPress={e => onKeyPress(e, user)}
    >
      <Grid item md={3} className={classes.userColumn}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='body2' data-test={`user-name-${index}`}>
              {user.givenName} {user.familyName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={2}>
        <Typography variant='body2' data-test={`user-subscription-${index}`}>
          {/* TODO: Add user subscription type e.g Annual */}
          -
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant='body2' data-test={`user-role-${index}`}>
          {capitalize(user.roles?.[0] ?? 'No role')}
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography variant='body2' data-test={`user-last-access-${index}`}>
          {user.lastAccess ? new Date(user.lastAccess).toLocaleDateString() : "Hasn't signed in"}
        </Typography>
      </Grid>
      <Grid item md>
        <Typography
          variant='body2'
          data-test={`user-status-${index}`}
          className={classes.status}
        >
          {user.enabled ? 'Active' : 'Inactive'}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ConsumerItem
