import React from 'react'

import { Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    borderRadius: 5,
    border: `1px solid ${palette.text.disabled}`,
    '&:hover': {
      border: `1px solid ${palette.text.primary}`
    },
    padding: 0
  },
  checked: {
    border: `1px solid ${palette.primary.main}`,
    backgroundColor: '#E5F4FE'
  },
  icon: {
    margin: 5,
    display: 'block',
    width: spacing(6),
    height: spacing(6),
    borderRadius: 2
  }
}))

const SquareRadio = props => {
  const classes = useStyles()

  const {
    color = 'rgba(0, 0, 0, 0.38)',
    className,
    name,
    disabled = false,
    actualColor,
    key
  } = props
  return (
    <Radio
      key={key}
      classes={{ root: classes.root, checked: classes.checked }}
      className={className}
      icon={
        <span className={classes.icon} style={{ backgroundColor: color }} />
      }
      checkedIcon={
        <span className={classes.icon} style={{ backgroundColor: color }} />
      }
      name={name}
      value={color}
      disabled={disabled}
      checked={color === actualColor}
    >
      {color}
    </Radio>
  )
}
export default SquareRadio
