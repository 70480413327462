import { Box, Grid, Typography } from '@material-ui/core'
import { Face } from '@material-ui/icons'
import { format, formatDistanceToNowStrict } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { UserListSlice } from '../../../reducers'
import { useStyles } from './style'

const Summary = () => {
  const classes = useStyles()
  const user = useSelector(UserListSlice.selectors.selectUser)

  return (
    <Grid container data-test='consumer-summmary'>
      <Grid item xs={3}>
        <Box className={classes.avatar} data-test='consumer-avatar'>
          <Face color='primary' />
        </Box>
      </Grid>
      <Grid container direction='column' item xs>
        <Typography variant='caption'>Contact</Typography>
        <Typography variant='body2' className={classes.email} data-test='consumer-email'>
          {user.email}
        </Typography>
        <br />
        <Typography variant='caption' data-test='consumer-last-access'>
          Last access: {user?.lastAccess
            ? formatDistanceToNowStrict(new Date(user.lastAccess))
            : "Hasn't signed in"}
        </Typography>
        <Typography variant='caption' data-test='consumer-member-since'>
          Member since: {format(new Date(user.createdAt), 'MMM d, y')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Summary
