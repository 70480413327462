import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  button: {
    width: 200,
    height: 120
  },
  groupedHorizontal: {
    marginRight: 24,
    marginBottom: 24,
    '&:not(:first-child)': {
      border: '1px solid rgba(0, 0, 0, 0.38)',
      borderRadius: 4
    },
    '&:not(:last-child)': {
      border: '1px solid rgba(0, 0, 0, 0.38)',
      borderRadius: 4
    },
    '&:nth-child(3)': {
      marginRight: 0
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  buttonSelected: {
    background: '#EBF4FA !important',
    border: '2px solid #0070BD !important'
  },
  selectedIcon: {
    height: 20,
    width: 20,
    border: '2px solid #ffff',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0070BD',
    color: '#fff',
    position: 'absolute',
    top: 4,
    right: 4
  },
  icon: {
    width: 12,
    height: 12
  }
}))
