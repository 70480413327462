import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { TemplateCard } from './template-card'
import { useStyles } from './style'

const TemplateSelect = ({
  onTemplateSelect = () => {}
}) => {
  const classes = useStyles()
  const [templates, setTemplates] = React.useState([])
  const [getTemplateList] = useEvent([{
    eventName: 'templateList',
    onSuccess: ({ templates }) => setTemplates(templates)
  }])
  useEventsOnViewLoad(() => { getTemplateList.trigger({ populate: true }) }, [getTemplateList])

  if (getTemplateList.isWorking) {
    return (
      <Grid container className={classes.root} justifyContent='center' alignItems='center'>
        <CircularProgress size={60} />
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant='subtitle1'>Templates</Typography>
      <Typography gutterBottom color='textSecondary' variant='body2'>Select the template you want to use as a basis. You can also create new templates from any template base in the Template option on the top navbar.</Typography>
      <Grid container>
        {templates.map((template, idx) => <TemplateCard key={idx} template={template} onTemplateSelect={onTemplateSelect} />)}
        {!templates?.length && <p>No templates to show</p>}
      </Grid>
    </div>
  )
}

export default TemplateSelect
