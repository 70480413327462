import React from 'react'
import { useTenantFormContext } from '../tenant-form-context'
import { TemplateHierarchy, TemplateSelect } from '../../../components'
import { useStyles } from './style'
import { Grid, Typography } from '@material-ui/core'
import { ActionButton } from '@emerald-works-nova/components'
import classNames from 'classnames'

const TemplateTab = () => {
  const classes = useStyles()
  const { form: { watch, setValue }, isEdit, handlePreviousClick, handleCreateTenant, mainClasses } = useTenantFormContext()
  const [template, setTemplate] = React.useState()
  const [isChangingTemplate, setIsChangingTemplate] = React.useState(false)

  const [baseTemplate, templateSK] = watch(['baseTemplate', 'templateSK'])

  const handleTemplateSelect = React.useCallback((template) => {
    setTemplate(template)
    setValue('baseTemplate', template.sk)
    setIsChangingTemplate(false)
  }, [setValue])

  const Body = () => {
    if ((!templateSK && !baseTemplate) || isChangingTemplate) {
      return <TemplateSelect onTemplateSelect={handleTemplateSelect} />
    }

    const hirarchyProps = { template, setTemplate, isEdit, templateSK: templateSK || baseTemplate }
    return (
      <TemplateHierarchy
        viewOnly
        {...hirarchyProps}
        onChangeTemplate={() => setIsChangingTemplate(true)}
      />
    )
  }

  return (
    <Grid className={classes.root}>
      <Grid container alignItems='center' className={classNames(mainClasses.ctnTitle, classes.ctnTitle)}>
        <Grid item xs={9}>
          <Typography variant='h6'>Website</Typography>
        </Grid>
        <Grid container item justifyContent='flex-end' xs={3}>
          {!isEdit && (
            <>
              <ActionButton
                variant='primary'
                dataTest='create-tenant-save-button'
                onClick={handlePreviousClick}
                className={mainClasses.navBtn}
              >Previous
              </ActionButton>
              <ActionButton
                variant='primary'
                dataTest='create-tenant-save-button'
                onClick={handleCreateTenant}
                className={mainClasses.navBtn}
              >Create
              </ActionButton>
            </>
          )}
        </Grid>
      </Grid>
      <Body />
    </Grid>
  )
}

export default TemplateTab
