import { useEvent } from '@emerald-works/react-event-bus-client'
import { Radio, RadioGroup, FormControlLabel, FormControl, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { BannerStatic } from '../../../components'

const RoleSelector = ({ user, userRole, roles, userPoolId }) => {
  const [role, setRole] = useState(userRole)
  const [value, setValue] = useState(userRole)

  const [updateRoleUser] = useEvent([
    {
      eventName: 'adminUpdateRoleUser',
      onSuccess: payload => {
        setRole(payload.role)
        BannerStatic.show({ label: 'Toolkit User changed successfully' })
      },
      onError: (e) => {
        console.log('error toggling user role', e)
        BannerStatic.show({ label: 'Error while attempting to change the Toolkit User role', isError: true })
      }
    }
  ])

  const handleChange = ev => {
    setValue(ev.target.value)
    updateRoleUser.trigger({ userPoolId, username: user.username, role: ev.target.value, previousRole: role })
  }

  const renderRadios = () => roles.map((item, index) => (
    <FormControlLabel
      key={`radio-${index}`}
      value={item}
      control={<Radio color='primary' />}
      label={item === 'enterprise' ? 'Reader' : item}
      disabled={updateRoleUser.isWorking}
    />
  ))

  return (
    <>
      {roles && (
        <FormControl component='fieldset'>
          <RadioGroup aria-label='role' name='role' value={value} onChange={handleChange} row>
            {renderRadios()}
            {updateRoleUser.isWorking && <CircularProgress size={20} color='primary' />}
          </RadioGroup>
        </FormControl>
      )}
    </>
  )
}

export default RoleSelector
