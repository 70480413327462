import { ContentTypeFilter } from '@emerald-works-nova/components'
import { Button, Grid, useTheme } from '@material-ui/core'
import React from 'react'
import { DateFilter, SearchFilter } from '../../../content-filters'
import { useStyles } from './style'

const Filters = React.memo(({ filters, onChange, onClear, clearBtnEnd }) => {
  const classes = useStyles()
  const { spacing } = useTheme()

  const hasFilters = React.useMemo(() => Object.values(filters).some(v => !!v), [filters])

  const handleChangeFilter = (filter, value) => onChange({ ...filters, [filter]: value })

  const ClearBtn = () => (
    <Button
      id='catalog-clear-filters-button'
      color='primary'
      aria-label='clear filters'
      onClick={onClear}
      style={{
        margin: spacing(
          0,
          clearBtnEnd ? 0 : 2,
          0,
          clearBtnEnd ? 2 : 0
        )
      }}
    >
        Clear filters
    </Button>
  )

  return (
    <Grid item aria-label='List filters' tabIndex={0} classes={{ root: classes.filtersGrid }}>
      {hasFilters && !clearBtnEnd && <ClearBtn />}
      <SearchFilter value={filters.search} onChange={value => handleChangeFilter('search', value)} />
      <ContentTypeFilter onChange={value => handleChangeFilter('type', value)} />
      <DateFilter value={filters.dateStart} onChange={value => handleChangeFilter('dateStart', value)} />
      {hasFilters && clearBtnEnd && <ClearBtn />}
    </Grid>
  )
})

export { Filters }
