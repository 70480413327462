import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  point: {
    width: 6,
    height: 6,
    borderRadius: 3
  }
}))
