import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { isObject, lowerCase } from 'lodash'
import React, { useEffect, useState } from 'react'
import KeywordList from './list'
import { useStyles } from './style'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />
const noop = () => { }

const KeywordSelect = ({
  onChange = noop,
  value = [],
  isReadOnly
}) => {
  const classes = useStyles()
  const filter = createFilterOptions()
  const [allOptions, setAllOptions] = useState([])
  const [selectedValues, setSelectedValues] = useState(value)
  useEffect(() => { setSelectedValues(value) }, [value])

  useEffect(() => {
    return () => {
      setSelectedValues([])
      setAllOptions([])
    }
  }, [])

  const [keywordList, keywordInsert] = useEvent([
    {
      eventName: 'keywordList',
      onSuccess: (payload) => {
        setAllOptions(payload.map(item => item.sk))
      }
    },
    {
      eventName: 'keywordInsert',
      onSuccess: (payload) => {
        setSelectedValues(oldArray => {
          const newValue = [...oldArray, payload.sk]
          onChange(newValue)
          return newValue
        })
      }
    }
  ])

  useEventsOnViewLoad(() => {
    keywordList.trigger()
    keywordInsert.registerAdhocOnSuccessListener(({ payload }) => {
      setAllOptions(oldArray => {
        return [payload.sk, ...oldArray]
      })
    })
  }, [keywordList])

  const handleOnChange = (_, selectedOptions) => {
    // Update content/create new options
    for (const option of selectedOptions) {
      if (option.createNewKeyword) {
        keywordInsert.trigger({
          keyword: option.inputValue
        })
      }
    }
    const selectedValues = selectedOptions.filter(option => !option.createNewKeyword)
    setSelectedValues(selectedValues)
    onChange(selectedValues)
  }

  const handleFilter = (options, params) => {
    const filtered = filter(options, params)

    // Suggest the creation of a new value
    // if user has typed something and there is no equals option
    if (params.inputValue !== '' && !filtered.some((option) => (isObject(option) ? option.sk : option) === params.inputValue)) {
      filtered.push({
        inputValue: params.inputValue,
        createNewKeyword: true,
        keyword: params.inputValue
      })
    }

    return filtered
  }

  const renderOption = (option, { selected }) => {
    if (option.createNewKeyword) {
      return (
        <>
          <span className={classes.textOption}>No result was found.</span>
          <span className={classes.createText}>Create </span>
          <span className={classes.createOptionInput}>{option.inputValue}</span>
        </>
      )
    } else {
      return (
        <>
          <Checkbox
            color='primary'
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            disabled={isReadOnly}
          />
          <span className={classes.textOption}>
            {option}
          </span>
        </>
      )
    }
  }

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        disabled={isReadOnly}
        variant='outlined'
        label='Keywords'
        helperText={keywordInsert.isWorking ? 'Creating keyword...' : keywordList.isWorking ? 'Fetching keyword list...' : ''}
        placeholder='Search or create keywords to add'
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {(keywordList.isWorking || keywordInsert.isWorking) ? <CircularProgress size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          )
        }}
      />
    )
  }

  return (
    <Autocomplete
      ChipProps={{
        classes: {
          root: classes.chipRoot
        }
      }}
      classes={{
        option: classes.option
      }}
      multiple
      autoHighlight
      disableCloseOnSelect
      onChange={handleOnChange}
      filterOptions={handleFilter}
      loading={keywordList.isWorking || keywordInsert.isWorking}
      loadingText='loading...'
      id='checkboxes-tags-demo'
      options={allOptions}
      value={selectedValues}
      ListboxComponent={KeywordList}
      getOptionLabel={(option) => isObject(option) ? option.sk : option}
      getOptionSelected={(option, value) => {
        return (lowerCase(option) === lowerCase(value)) || (
          option.createKeyword && lowerCase(option.sk) === lowerCase(value)
        )
      }}
      renderOption={renderOption}
      renderInput={renderInput}
      disabled={isReadOnly}
    />
  )
}

export default KeywordSelect
