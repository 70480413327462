import { FormControl, InputLabel, MenuItem, Select, useTheme } from '@material-ui/core'
import { VisibilityRounded, VisibilityOffRounded } from '@material-ui/icons'
import React from 'react'
import { useStyles } from './style'

const MigrationFilter = ({ value, onChange }) => {
  const classes = useStyles()
  const { spacing } = useTheme()

  const getMenuItems = React.useCallback(() => ([
    { icon: () => <VisibilityOffRounded />, label: 'Not Ready', value: 'not_ready' },
    { icon: () => <VisibilityRounded />, label: 'Migrated', value: 'migrated' }
  ]), [])

  return (
    <FormControl variant='outlined' className={classes.filterTypeControl}>
      <InputLabel
        id='catalog-filter-type'
        aria-label={value !== '' ? `Migration Status, ${value} selected` : 'Migration Status'}
        classes={{ outlined: classes.filterTypelabelOutlined }}
      >
          Migration Status
      </InputLabel>
      <Select
        id='catalog-filter-migration-select'
        labelId='catalog-filter-migration'
        value={value}
        label='Migration Status'
        onChange={ev => onChange(ev.target.value)}
        classes={{ root: classes.filterTypeSelect }}
      >
        <MenuItem value='' aria-label='None'>&nbsp;</MenuItem>
        {getMenuItems().map((item, key) => (
          <MenuItem key={key} value={item.value}>
            <item.icon />
            <span style={{ marginLeft: spacing(1.5) }}>{item.label}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MigrationFilter
