import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

const ContentTable = ({ data, loading }) => {
  return (
    <TableContainer component='div'>
      <Table size='small'>
        <TableBody>
          {(loading ? new Array(10).fill({}) : data).map(({ name, count }, idx) => (
            <TableRow key={name ?? idx}>
              <TableCell>
                {name ? `${idx + 1}. ${name}` : <Skeleton width={200} />}
              </TableCell>
              <TableCell>
                {count ? (
                  `${count} viewer${count > 1 ? 's' : ''}`
                ) : (
                  <Skeleton width={100} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ContentTable
