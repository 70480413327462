import ContentTeamActiveSvg from './images/content-team-active.svg'
import ContentTeamSvg from './images/content-team.svg'
import CxTeamActiveSvg from './images/cx-team-active.svg'
import CxTeamSvg from './images/cx-team.svg'
import MktTeamActiveSvg from './images/mkt-team-active.svg'
import MktTeamSvg from './images/mkt-team.svg'
import OtherTeamActiveSvg from './images/other-team-active.svg'
import OtherTeamSvg from './images/other-team.svg'
import PeTeamActiveSvg from './images/pe-team-active.svg'
import PeTeamSvg from './images/pe-team.svg'

const AdminRole = {
  label: 'Product & Engineering Team',
  description: 'This role sets full permission for the user.',
  value: 'admin',
  icon: <img src={PeTeamSvg} />,
  iconActive: <img src={PeTeamActiveSvg} />
}
const MarketingRole = {
  label: 'Consumer Marketing Team',
  description: 'For this role Marketing and Dashboard sections are available.',
  value: 'marketing',
  icon: <img src={MktTeamSvg} />,
  iconActive: <img src={MktTeamActiveSvg} />
}
const ClientManagerRole = {
  label: 'Other Teams',
  description: 'Dashboard and Clients (Read only) sections are available for this role.',
  value: 'clientManager',
  icon: <img src={OtherTeamSvg} />,
  iconActive: <img src={OtherTeamActiveSvg} />
}

const EditorRole = {
  label: 'Content Team',
  description: 'Content Editor and Templates sections are available for this role.',
  value: 'editor',
  icon: <img src={ContentTeamSvg} />,
  iconActive: <img src={ContentTeamActiveSvg} />
}
const CxRole = {
  label: 'Client Experience Team',
  description: 'This role allows setting up Content Editor and Clients sections.',
  value: 'cx',
  icon: <img src={CxTeamSvg} />,
  iconActive: <img src={CxTeamActiveSvg} />
}

export const AllRoles = [AdminRole, MarketingRole, ClientManagerRole, EditorRole, CxRole]
export const AllRolesStr = AllRoles.map(r => r.value)

export const OnboardingRoles = [[AdminRole, MarketingRole, ClientManagerRole], [EditorRole, CxRole]]
