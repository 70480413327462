import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  dialogPaper: {
    padding: spacing(2, 3),
    maxHeight: '95%',
    maxWidth: 1440
  },
  dialogTitle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: spacing(3)
  },
  tabs: {
    marginBottom: spacing(1)
  },
  actionBtnCtn: {
    paddingBottom: spacing(1)
  },
  doneBtn: {
    marginRight: 16,
    minWidth: 100
  },
  saveBtn: { minWidth: 100 }
}))
