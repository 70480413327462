import { ActionButton } from '@emerald-works-nova/components'
import { Box, CircularProgress, Dialog, DialogTitle, Divider, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStyles } from './style'
import PromotedContentContentList from './content-list'
import { PromotedContentSlice } from './../../../reducers'
import { useDispatch } from 'react-redux'
import PromotedContentSelectedContentList from './selected-content-list'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { BannerStatic } from '../..'

const PROMOTED_CONTENT_REQUIRED_LENGTH = 5

const STATUS_MAP = {
  empty: () => ({
    color: '#3D84CC',
    label: 'Select 5 pieces of content to be highlighted on the homepage'
  }),
  contentWasAdded: (content, isMain = false) => ({
    color: '#2B9D1D',
    label: `“${content.title}” was added to the promoted section ${isMain ? 'as a Main Promoted Card' : ''}`,
    changeAfterMs: 5000
  }),
  waitingSelection: (selectedLength) => {
    const missingLength = PROMOTED_CONTENT_REQUIRED_LENGTH - selectedLength
    const piecePluralized = missingLength > 1 ? 'pieces' : 'piece'

    return {
      color: '#3D84CC',
      label: `Please select ${missingLength} more ${piecePluralized} of content to be promoted on the homepage`
    }
  },
  ready: () => ({
    color: '#3D84CC',
    label: 'Uncheck a promoted piece of content to re-enable the content list'
  })

}

let CHANGE_STATUS_TIMEOUT = null

const StatusBar = ({ status }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item>
        <div className={classes.point} style={{ backgroundColor: status.color }} />
      </Grid>
      <Grid item>
        <div className={classes.status} style={{ color: status.color }} aria-live='polite' data-test='saved-changes'>
          {status.label}
        </div>
      </Grid>
    </Grid>
  )
}

const PromotedItemsModal = ({
  open,
  template,
  selected: selectedContent = [],
  onClose = () => { },
  onCloseOk = () => { }
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [status, setStatus] = useState(selectedContent.length === PROMOTED_CONTENT_REQUIRED_LENGTH ? STATUS_MAP.ready() : STATUS_MAP.empty())

  const [selected, setSelected] = useState(selectedContent)

  const [updatePromotedContent] = useEvent([{
    eventName: 'promotedContentUpdatePromotedContent',
    onSuccess: (payload) => {
      BannerStatic.show({ label: 'Promoted Content has been saved' })
      onCloseOk()
    },
    onError: (err) => {
      console.log('On error -> ', err)
      BannerStatic.show({ label: 'Error updating promoted content', isError: true })
    }
  }])

  useEffect(() => {
    if (open) {
      setSelected(selectedContent)
      setStatus(selectedContent.length === PROMOTED_CONTENT_REQUIRED_LENGTH ? STATUS_MAP.ready() : STATUS_MAP.empty())
    }
  }, [open, selectedContent])

  useEffect(() => {
    CHANGE_STATUS_TIMEOUT && clearTimeout(CHANGE_STATUS_TIMEOUT)
    if (status.changeAfterMs) {
      CHANGE_STATUS_TIMEOUT = setTimeout(() => {
        if (selected.length !== PROMOTED_CONTENT_REQUIRED_LENGTH) {
          setStatus(STATUS_MAP.waitingSelection(selected.length))
        } else {
          setStatus(STATUS_MAP.ready())
        }
      }, status.changeAfterMs)
    }
  }, [selected, status])

  const handleSelectedChange = (item) => {
    const isSelected = selected.find(i => i.sk === item.sk)
    const newList = isSelected ? selected.filter(i => i.sk !== item.sk) : [...selected, item]

    if (!isSelected) {
      setStatus(STATUS_MAP.contentWasAdded(item, !selected.length))
    } else {
      setStatus(STATUS_MAP.waitingSelection(newList.length))
    }

    setSelected(newList)
  }

  const handleClose = () => {
    dispatch(PromotedContentSlice.actions.reset())
    onClose()
  }

  const handleSave = () => {
    updatePromotedContent.trigger({
      template,
      promotedContent: selected
    })
  }

  return (
    <Dialog
      open={open}
      fullScreen
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby='manage-category-title'
    >
      <DialogTitle id='manage-category-title' className={classes.dialogTitle}>

        <Box display='flex' flexDirection='row'>
          <Box display='flex' flexDirection='row' flex={1} alignItems='center'>
            <Typography className={classes.modalTitle} variant='subtitle1'>Add Promoted Content</Typography>
            <StatusBar status={status} />
          </Box>
          <ActionButton
            variant='secundary'
            dataTest='create-tenant-done-button'
            className={classes.doneBtn}
            onClick={handleClose}
            disabled={updatePromotedContent.isWorking}
          > Cancel
          </ActionButton>
          <ActionButton
            variant='primary'
            dataTest='create-tenant-save-button'
            onClick={handleSave}
            className={classes.saveBtn}
            disabled={selected.length !== PROMOTED_CONTENT_REQUIRED_LENGTH || updatePromotedContent.isWorking}
          >
            {updatePromotedContent.isWorking
              ? <CircularProgress size={25} color='primary' />
              : 'Save'}
          </ActionButton>
        </Box>
      </DialogTitle>
      {!!selected.length && (
        <>
          <PromotedContentSelectedContentList
            onSelectChange={handleSelectedChange}
            selected={selected}
          />
          <Divider className={classes.dashedDivider} />
        </>
      )}
      <PromotedContentContentList
        onSelectChange={handleSelectedChange}
        selected={selected}
      />
    </Dialog>
  )
}

export default PromotedItemsModal
