import { Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import React from 'react'
import { useStyles } from './style'

const InfoCard = props => {
  const { title, tooltip, children } = props
  const classes = useStyles(props)

  return (
    <Paper
      elevation={2}
      component={Grid}
      container
      direction='column'
      className={classes.root}
    >
      <Grid container item className={classes.header}>
        <Typography variant='h6' className={classes.title}>
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoOutlined className={classes.tooltipIcon} />
          </Tooltip>
        )}
      </Grid>
      <Grid item className={classes.content}>
        {children}
      </Grid>
    </Paper>
  )
}

export default InfoCard
