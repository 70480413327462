import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  tenant: null
}

// Store
export const content = createSlice({
  name,
  initialState,
  reducers: {
    getTenant: (state, { payload }) => {
      state.tenant = payload
    },
    updateTenantFeatures: (state, { payload }) => {
      state.tenant.features = payload
    },
    updateTenantLoginProviders: (state, { payload }) => {
      state.tenant.loginProviders = payload
    },
    reset: () => initialState
  }
})

export const { getTenant, updateTenantFeatures, updateTenantLoginProviders, reset } = content.actions

export default content.reducer
