const options = {
  indexAxis: 'y',
  scales: {
    x: {
      title: {
        text: 'UNIQUE VIEWERS',
        align: 'end',
        display: true,
        font: { size: 10, weight: 500 }
      },
      ticks: {
        precision: 0
      },
      grid: { display: false }
    },
    y: {
      ticks: {
        font: {
          weight: 'bold'
        }
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: true,

      callbacks: {
        label: context => {
          const val = context.raw
          const label = context.label
          return [
            `${val} unique users viewed`,
            `'${label}'`,
            'related content.'
          ]
        }
      }
    }
  }
}

export default options
