import name from './name'

const selectSliceData = state => state[name]
const selectContentTab = state => selectSliceData(state).content
const selectUsageTab = state => selectSliceData(state).usage
const selectFilters = state => selectSliceData(state).filters

export {
  selectContentTab,
  selectUsageTab,
  selectFilters
}
