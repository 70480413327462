import DescriptionIcon from '@material-ui/icons/Description'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import TimelineIcon from '@material-ui/icons/Timeline'

export const contentFormats = [
  {
    name: 'article',
    label: 'Article',
    icon: <DescriptionIcon />,
    types: [
      {
        name: 'how_to_guides',
        label: 'How to Guides'
      },
      {
        name: 'exercise',
        label: 'Exercises'
      },
      {
        name: 'case_studies',
        label: 'Case Studies'
      },
      {
        name: 'top_tips',
        label: 'Top Tips'
      },
      {
        name: 'surveys',
        label: 'Surveys'
      },
      {
        name: 'workshops',
        label: 'Workshops'
      }
    ]
  },
  {
    name: 'audio',
    label: 'Audio',
    icon: <MusicNoteIcon />,
    types: [
      {
        name: 'book_insights',
        label: 'Book Insights'
      },
      {
        name: 'expert_interviews',
        label: 'Expert Interviews'
      }
    ]
  },
  {
    name: 'infographic',
    label: 'Infographic',
    icon: <TimelineIcon />,
    types: [

    ]
  },
  {
    name: 'video',
    label: 'Video',
    icon: <OndemandVideoIcon />,
    types: [
      {
        name: 'animated_video',
        label: 'Animated Video'
      }
    ]
  },
  {
    name: 'quiz',
    label: 'Quiz',
    icon: <LiveHelpIcon />,
    types: [
      {
        name: 'self-assessment',
        label: 'Self-Assessment'
      }
    ]
  },
  {
    name: 'skillbooks',
    label: 'Skillbooks',
    icon: <MenuBookIcon />,
    types: [

    ]
  }
]
