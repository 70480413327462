import React from 'react'
import { ActionButton } from '@emerald-works-nova/components'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Backdrop, CircularProgress, Grid, Tab, Tabs, useTheme } from '@material-ui/core'
import { Assignment } from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import { BannerStatic, Panel, SectionHeader, TabPanel } from '../../components'
import { FolderStructureIcon } from '../../components/icons'
import DetailTab from './detail-tab'
import Structure from './structure-tab'
import { useStyles } from './style'
import { TemplateFormContext } from './template-form-context'

const SectionNames = { details: 'details', structure: 'structure' }
const SectionMap = {
  [SectionNames.details]: {
    component: <DetailTab />,
    title: 'Details',
    icon: <Assignment />,
    next: SectionNames.structure
  },
  [SectionNames.structure]: {
    component: <Structure />,
    title: 'Structure',
    icon: <FolderStructureIcon />
  }
}

const TemplateEdit = () => {
  const classes = useStyles()
  const [template, setTemplate] = React.useState()
  const [templateHierarchy, setTemplateHierarchy] = React.useState()
  const [isLoading, setIsLoading] = React.useState(false)
  const [loadingMsg, setLoadingMsg] = React.useState()

  const [getMasterTemplate, getTemplateHierarchy, toggleTemplatePromotedContent] = useEvent([
    { eventName: 'templateGetMaster' },
    { eventName: 'catGetGroupCatByTemplate' },
    { eventName: 'templateUpdate' }
  ])

  useEventsOnViewLoad(() => {
    setIsLoading(true)
    setLoadingMsg('Fetching Master template...')
    getMasterTemplate.trigger()

    getMasterTemplate.registerAdhocOnSuccessListener(({ payload: { template } }) => {
      setTemplate(template)
      setIsLoading(false)
    })

    getTemplateHierarchy.registerAdhocOnSuccessListener(({ payload: { categories } }) => {
      setTemplateHierarchy(categories)
      setIsLoading(false)
    })

    getMasterTemplate.registerAdhocOnErrorListener((...args) => {
      console.log('getMasterTemplate.registerAdhocOnErrorListener', args)
      BannerStatic.show({ label: 'Error retriving master template', isError: true })
      setIsLoading(false)
    })

    getTemplateHierarchy.registerAdhocOnErrorListener((...args) => {
      console.log('getTemplateHierarchy.registerAdhocOnErrorListener', args)
      BannerStatic.show({ label: 'Error retriving master template', isError: true })
      setIsLoading(false)
    })

    toggleTemplatePromotedContent.registerAdhocOnSuccessListener(({ payload: { template } }) => {
      setTemplate(template)
      let message = 'Promoted content has been disabled'
      if (template.isPromotedContentEnabled) {
        message = 'Promoted content has been enabled'
      }

      BannerStatic.show({ label: message })
    })

    toggleTemplatePromotedContent.registerAdhocOnErrorListener((...args) => {
      console.log('getTemplateHierarchy.registerAdhocOnErrorListener', args)
      BannerStatic.show({ label: 'Error toggling template promoted content', isError: true })
    })
  }, [getMasterTemplate, getTemplateHierarchy, toggleTemplatePromotedContent, setIsLoading, setLoadingMsg])

  const { spacing } = useTheme()
  const form = useForm({ mode: 'onChange' })
  const { setValue } = form

  const [tabValue, setTabValue] = React.useState(Object.entries(SectionMap)[0][0])

  const handleTabChange = React.useCallback((_, newValue) => setTabValue(newValue), [setTabValue])

  React.useEffect(() => {
    if (!template) return
    Object.entries(template).forEach(([k, v]) => setValue(k, v))
  }, [template, setValue])

  const handleNext = React.useCallback(() => setTabValue(SectionMap[tabValue].next), [setTabValue, tabValue])

  return (
    <TemplateFormContext.Provider value={{ form, template, templateHierarchy, toggleTemplatePromotedContent }}>
      <Backdrop id='backdrop-loading' className={classes.backdrop} open={isLoading}>
        <Grid container direction='column' className={classes.loadingCtn} justifyContent='center' alignItems='center'>
          <Grid item>
            <CircularProgress size={60} />
          </Grid>
          <Grid item className={classes.loadingMsg}>
            {loadingMsg}
          </Grid>
        </Grid>
      </Backdrop>
      <Grid container style={{ marginBottom: spacing(3) }}>
        <Grid item xs={9} data-test='title'>
          <SectionHeader
            title={template?.name ?? ''}
            location='Template /'
          />
        </Grid>
      </Grid>
      {template && (
        <Grid container spacing={2}>
          {/* Template tabs */}
          <Grid item xs={12}>
            <Panel padding={0}>
              <Grid container>
                <Grid item xs={8}>
                  <Tabs
                    indicatorColor='primary'
                    textColor='primary'
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label='Tenant Tabs'
                    classes={{ root: classes.tabs }}
                  >
                    {Object.entries(SectionMap).map(([key, section]) => (
                      <Tab
                        id={`tab-${key}`}
                        aria-controls={`tabpanel-${key}`}
                        key={key}
                        value={key}
                        label={section.title}
                        icon={section.icon}
                        classes={{ wrapper: classes.tabWrapper }}
                      />
                    ))}
                  </Tabs>
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end' className={classes.actionBtnCtn}>
                  <ActionButton
                    variant='primary'
                    dataTest='template-edit-next-button'
                    onClick={handleNext}
                    className={classes.nextBtn}
                    disabled={!SectionMap[tabValue]?.next}
                  > Next
                  </ActionButton>
                </Grid>
              </Grid>
              {/* Tabs content */}
              {Object.entries(SectionMap).map(([key]) => (
                <TabPanel key={key} value={tabValue} index={key}>
                  {SectionMap[key].component}
                </TabPanel>
              ))}
            </Panel>
          </Grid>
        </Grid>
      )}
    </TemplateFormContext.Provider>
  )
}

export default TemplateEdit
