import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ palette }) => ({
  root: {},
  playlist: {},
  image: {
    width: 80,
    height: 58,
    objectFit: 'cover',
    backgroundColor: palette.grey[200],
    borderRadius: 10
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  viewers: {
    display: 'flex',
    alignItems: 'center',
    gap: 4
  }
}))
