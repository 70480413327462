import * as colors from '@material-ui/core/colors'
import { grey } from './custom-colors'

// This is the base palette that will be used to build the theme.
// `palette` param option will either override or add values to
// the palette property of theme object that is inject for the entire application.

// Add new properties as needed and all application components will have the
// new proprity available in the `theme.palette` object

// For more information about each property and what it means:
// https://material-ui.com/customization/palette/

export default {
  primary: {
    light: '#4AA5EF',
    main: '#0070BD',
    dark: '#265497'
  },
  secondary: {
    light: '',
    main: '#FB8512',
    dark: '#F66D1B'
  },
  error: {
    light: colors.red[300],
    main: colors.red[500],
    dark: colors.red[700]
  },
  warning: {
    light: colors.orange[300],
    main: colors.orange[500],
    dark: colors.orange[700]
  },
  info: {
    light: colors.blue[300],
    main: colors.blue[500],
    dark: colors.blue[700]
  },
  success: {
    light: colors.green[300],
    main: colors.green[500],
    dark: colors.green[700]
  },
  white: {
    main: '#FFF'
  },
  blue: {
    dark: colors.blue[800]
  },
  grey: {
    lighter: colors.grey[50],
    light: colors.grey[200],
    main: colors.grey[300],
    dark: colors.grey[500],
    shade: { ...grey }
  },
  type: 'light',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  // The colors used to style the text.
  text: {
    // The most important text.
    primary: 'rgba(0, 0, 0, 0.87)',
    // Secondary text.
    secondary: 'rgba(0, 0, 0, 0.6)',
    // Disabled text have even lower visual prominence.
    disabled: 'rgba(0, 0, 0, 0.38)',
    // Text hints.
    hint: 'rgba(0, 0, 0, 0.38)',
    // Custom color
    customColor: '#76ff03',
    gray: '#888888'
  },
  // The color used to divide different elements.
  divider: 'rgba(0, 0, 0, 0.12)',
  // The background colors used to style the surfaces.
  // Consistency between these values is important.
  background: {
    paper: colors.common.white,
    default: colors.grey[50],
    selected: '#F2F2F2'
  },
  backdrop: {
    main: 'rgba(255, 255, 255, 0.7)'
  },
  button: {
    primary: {
      background: '#0070BD',
      text: '#FFF',
      border: '#0070BD'
    },
    secondary: {
      background: 'transparent',
      text: '#0070BD',
      border: 'rgba(0, 0, 0, 0.12)'
    },
    disabled: {
      border: 'rgba(0, 0, 0, 0.12)',
      text: 'rgba(0, 0, 0, 0.38)',
      background: 'rgba(0, 0, 0, 0.12)'
    }
  },
  // The colors used to style the action elements.
  action: {
    // The color of an active action like an icon button.
    active: 'rgba(0, 0, 0, 0.54)',
    // The color of an hovered action.
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    // The color of a selected action.
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    // The color of a disabled action.
    disabled: 'rgba(0, 0, 0, 0.26)',
    // The background color of a disabled action.
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12
  },
  contentType: {
    article: '#4083F7',
    skillbooks: '#4083F7',
    audio: '#71A700',
    infographic: '#9331C1',
    video: '#D4672B',
    quiz: '#C13176',
    'self-assessment': '#C13176',
    exercise: '#0C969C'
  }
}
