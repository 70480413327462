import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ palette }) => ({
  icon: {
    paddingRight: 12,
    color: props => props.color
  },
  labelCtn: {
    paddingTop: 4,
    textTransform: 'none'
  },
  label: {
    color: props => props.color
  },
  subType: {
    color: '#9E9E9E'
  },
  description: {
    color: palette.text.secondary
  }
}))
