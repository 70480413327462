import { FormInputRow, Input } from '@emerald-works-nova/components'
import { Grid } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'
import { ContentTypeRadioButton, ContentTypeSelect } from '../'
import { useRHFInitialValues } from '../../hooks'

const ContentBasicInformationForm = ({
  control,
  errors = {},
  setValue,
  initialValues = {},
  smallFormTitles = false,
  blockTypeOptions = false,
  isReadOnly,
  isEdit
}) => {
  useRHFInitialValues(initialValues, setValue)
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormInputRow
          title='Title'
          smallFormTitles={smallFormTitles}
          description='This is the full name of the article or resource, as you want it to appear on screen. (Optimised for SEO)'
          InputComponent={
            <Input
              autoFocus
              control={control}
              rules={{ required: true }}
              label='Title'
              name='title'
              valid={errors.title === undefined}
              error='Title is required'
              recommendedLength={48}
              maxLength={80}
              showErrors
              showCounter
              showHelperTextForCounter
              disabled={isReadOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputRow
          title='Static Url'
          smallFormTitles={smallFormTitles}
          description='This information is automatically generated, but you can change it. You can edit the URL only upon the creation of the content, after saving it this field is blocked for edition. (Optimised for SEO)'
          InputComponent={
            <Input
              autoFocus
              control={control}
              rules={{ required: true }}
              label='Static Url'
              name='staticUrl'
              valid={errors.staticUrl === undefined}
              showHelperTextForCounter
              disabled={isReadOnly || isEdit}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputRow
          title='Subtitle'
          smallFormTitles={smallFormTitles}
          description='This is the subheading for articles and resources, optimised for SEO. Note: For Expert Interviews, Book Insights and video transcript pages, the Subtitle should the interviewee name; author(s) name and “Video Transcript” respectively. '
          InputComponent={
            <Input
              control={control}
              rules={{ required: true }}
              name='subtitle.html'
              valid={errors.subtitle === undefined}
              error='Subtitle is required'
              label='Add a Subtitle'
              multiline
              rows={4}
              recommendedLength={87}
              maxLength={105}
              showErrors
              showCounter
              showHelperTextForCounter
              disabled={isReadOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputRow
          title='Meta Description'
          smallFormTitles={smallFormTitles}
          description='Descriptive text optimised for SEO. Compelling, active description, directly relevant to the content. Use the 155 character limit. '
          InputComponent={
            <Input
              control={control}
              rules={{ required: true }}
              name='metaDescription'
              valid={errors.metaDescription === undefined}
              error='Meta Description is required'
              label='Add a Meta Description'
              multiline
              rows={4}
              recommendedLength={255}
              maxLength={300}
              showErrors
              showCounter
              showHelperTextForCounter
              disabled={isReadOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputRow
          title='Content Type'
          smallFormTitles={smallFormTitles}
          description='This is the type of the resource. E.g: Animated Video, How to Guides, Self-Assessments etc. '
          InputComponent={
            <Controller
              name='primaryType'
              control={control}
              rules={{ required: true }}
              defaultValue={initialValues.primaryType || ['article']}
              render={(
                { field: { onChange, value } }
              ) => (
                <ContentTypeSelect
                  onChange={onChange}
                  blockTypeOptions={blockTypeOptions}
                  value={value}
                />
              )}
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FormInputRow
          description='This is the format of the resource.'
          InputComponent={
            <Controller
              name='primaryType'
              control={control}
              rules={{ required: true }}
              defaultValue={(initialValues.primaryType && initialValues.primaryType[0]) || 'article'}
              render={({ field: { value } }) => <ContentTypeRadioButton blockTypeOptions value={value} />}
            />
          }
        />
      </Grid>

    </Grid>
  )
}

export default ContentBasicInformationForm
