import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    width: '100%'
  },
  informationMenu: {
    padding: spacing(2),
    '& .MuiBox-root:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      marginBottom: spacing(2)
    }
  },
  infoLastUpdated: {
    flexGrow: 1,
    textAlign: 'right',
    color: palette.text.secondary
  },
  stepDivider: {
    marginTop: spacing(1)
  },
  stepsContainer: {
    marginTop: spacing(2)
  }
}))
