import { ConfirmationAlert } from '@emerald-works-nova/components'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Button, CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch } from 'react-redux'
import { BannerStatic } from '../../../../components'
import { TenantEditSlice } from '../../../../reducers'
import { useTenantFormContext } from '../../tenant-form-context'
import { useStyles } from './style'

const TempUser = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { tenant } = useTenantFormContext()
  const { pk: tenantId, tempUser, cognitoSettings: { userPoolId, identityPoolId } } = tenant

  const queryMatchLg = useMediaQuery('(min-width:1280px)')
  const ctnDirection = React.useMemo(() => queryMatchLg ? 'row' : 'column', [queryMatchLg])

  const [isLoadingEnable, setIsLoadingEnable] = React.useState()
  const [isLoadingDisable, setIsLoadingDisable] = React.useState()
  const [showDisableAlert, setShowDisableAlert] = React.useState()
  const [disableTempUser, enableTempUser] = useEvent([{ eventName: 'removeTempManagerUser' }, { eventName: 'createTempManagerUser' }])

  useEventsOnViewLoad(() => {
    disableTempUser.registerAdhocOnSuccessListener(({ payload: tenant }) => {
      dispatch(TenantEditSlice.actions.getTenant(tenant))
      setIsLoadingDisable(false)
      BannerStatic.show({ label: 'Test user disabled' })
    })
    disableTempUser.registerAdhocOnErrorListener((...args) => {
      console.log('disableTempUser.registerAdhocOnErrorListener', args)
      setIsLoadingEnable(false)
      BannerStatic.show({ label: 'There was an error disabling the test user', isError: true })
    })

    enableTempUser.registerAdhocOnSuccessListener(({ payload: tenant }) => {
      dispatch(TenantEditSlice.actions.getTenant(tenant))
      setIsLoadingEnable(false)
      BannerStatic.show({ label: 'Test user enabled' })
    })
    enableTempUser.registerAdhocOnErrorListener((...args) => {
      console.log('enableTempUser.registerAdhocOnErrorListener', args)
      setIsLoadingEnable(false)
      BannerStatic.show({ label: 'There was an error enabling the test user', isError: true })
    })
  }, [disableTempUser, enableTempUser])

  const handleContentDisable = React.useCallback(() => {
    setShowDisableAlert(false)
    setIsLoadingDisable(true)
    disableTempUser.trigger({ tenantId })
  }, [disableTempUser, tenantId])

  const handleDisableClick = React.useCallback(() => {
    setShowDisableAlert(true)
  }, [setShowDisableAlert])

  const handleEnableClick = React.useCallback(() => {
    setIsLoadingEnable(true)
    enableTempUser.trigger({ tenantId, userPoolId, identityPoolId })
  }, [enableTempUser, tenantId, userPoolId, identityPoolId])

  const onCopy = React.useCallback((field) => {
    BannerStatic.show({
      label: `${field} copied`,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
    })
  }, [])

  const tempUserFields = React.useMemo(() => {
    if (!tempUser) return null
    const { email, password } = tempUser
    const passwordMasked = new Array(password.length).fill('*').join('')
    const text = isLoadingDisable ? (<>Disabling &nbsp; <CircularProgress size={16} /></>) : <>Disable</>
    return (
      <Grid container className={classes.fieldCtn} direction={ctnDirection}>
        <Grid container item className={classes.fieldWrapper}>
          <Typography gutterBottom variant='body2' className={classes.fieldTitle}><strong>Username</strong></Typography>
          <Grid container direction='row' className={classes.infoContainer}>
            <Typography color='textSecondary' variant='body1' className={classes.infoText}>{email}</Typography>
            <Divider orientation='vertical' style={{ height: 54, marginLeft: 16 }} />
            <CopyToClipboard text={email} onCopy={() => onCopy('Username')}>
              <Button
                classes={{ root: classes.btnCopy }}
                color='primary'
                data-test='copy-username-button'
                startIcon={<FileCopyIcon />}
              > Copy
              </Button>
            </CopyToClipboard>
          </Grid>
        </Grid>
        <Grid container item className={classes.fieldWrapper}>
          <Typography gutterBottom variant='body2' className={classes.fieldTitle}><strong>Password</strong></Typography>
          <Grid container direction='row' className={classes.infoContainer}>
            <Typography color='textSecondary' variant='body1' className={classes.infoText}>{passwordMasked}</Typography>
            <Divider orientation='vertical' style={{ height: 54, marginLeft: 16 }} />
            <CopyToClipboard text={password} onCopy={() => onCopy('Password')}>
              <Button
                classes={{ root: classes.btnCopy }}
                color='primary'
                data-test='copy-username-button'
                startIcon={<FileCopyIcon />}
              > Copy
              </Button>
            </CopyToClipboard>
          </Grid>
        </Grid>
        <Grid container item justifyContent='space-between' className={classes.fieldWrapper}>
          <Typography gutterBottom variant='body2' className={classes.disableTitle}><strong>Manage Credentials</strong></Typography>
          <Button
            classes={{ root: classes.btnDisable, disabled: classes.btnDisabling }}
            color='default'
            variant='contained'
            data-test='disable-tempuser-button'
            onClick={handleDisableClick}
            disabled={isLoadingDisable}
          > {text}
          </Button>
        </Grid>
      </Grid>
    )
  }, [tempUser, onCopy, classes, ctnDirection, handleDisableClick, isLoadingDisable])

  const enableTempUserComp = React.useMemo(() => {
    if (tempUser) return null
    const text = isLoadingEnable ? (<>Enabling &nbsp; <CircularProgress style={{ color: 'white ' }} size={16} /></>) : <>Enable</>
    return (
      <Grid container item alignItems='flex-end' className={classes.fieldWrapper}>
        <Typography gutterBottom variant='body2' className={classes.disableTitle}><strong>Manage Credentials</strong></Typography>
        <Button
          color='primary'
          variant='contained'
          classes={{ root: classes.btnEnable }}
          data-test='enable-tempuser-button'
          onClick={handleEnableClick}
          disabled={isLoadingEnable}
        > {text}
        </Button>
      </Grid>
    )
  }, [tempUser, classes, handleEnableClick, isLoadingEnable])

  return (
    <>
      <Grid container direction='row'>
        <Grid item className={classes.descriptionWrapper}>
          <Typography variant='body1' className={classes.title}>Test Credentials</Typography>
          <Typography variant='caption' color='textSecondary'>
          Give these credentials to the client for testing their toolkit. We only generate a single set of test credentials per client.
          </Typography>
        </Grid>
        {tempUserFields}
        {enableTempUserComp}
      </Grid>
      {/* Disable temp user modal */}
      <ConfirmationAlert
        title='Disable test user?'
        agreeText='Disable'
        showModal={showDisableAlert}
        onConfirmation={handleContentDisable}
        onCancel={() => setShowDisableAlert(false)}
        isLoading={disableTempUser.isWorking}
        disableBackdropClick
      />
    </>
  )
}

export default React.memo(TempUser)
