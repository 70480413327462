import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  quote: {
    position: 'relative',
    padding: spacing(5, 6.25),
    background: 'linear-gradient(180deg, rgba(21, 101, 216, 0.9) 33.54%, rgba(11, 75, 167, 0.9) 88.9%)'
  },
  shipImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    maxWidth: '100%',
    height: '100%',
    zIndex: -1
  },
  apostrofeImg: {
    marginBottom: spacing(2)
  },
  vector: {
    height: 33,
    width: 33.5,
    borderBottom: '12.5px solid white',
    borderRight: '12.5px solid white'
  },
  arkLogo: {
    width: 45,
    marginRight: spacing(1.5)
  },
  content: {
    background: '#F6F7FA',
    padding: spacing(0, 4.75)
  }
}))
