import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const content = createSlice({
  name,
  initialState: {
    user: undefined,
    list: [],
    lastKey: undefined,
    total: 0,
    totalPending: 0,
    userPoolId: undefined
  },
  reducers: {
    getUserList: (state, { payload }) => {
      state.list = payload.users
      state.lastKey = payload.lastKey
      state.total = payload.total
    },
    getUserPendingCount: (state, { payload }) => {
      state.totalPending = payload.total
    },
    addUserList: (state, { payload }) => {
      state.list = state.list.concat(payload.users)
      state.lastKey = payload.lastKey
      state.total = payload.total
    },
    setUserDetail: (state, { payload }) => {
      state.user = { ...state.user, ...payload }
    },
    updateUserEnabledInList: (state, { payload: { username, enabled } }) => {
      state.list.find(t => t.username === username).enabled = enabled
    },
    setUserPoolId: (state, { payload }) => {
      state.userPoolId = payload
    },
    setUserRole: (state, { payload }) => {
      state.user.roles[0] = payload
    },
    resetUserList: (state) => {
      state.list = []
      state.lastKey = undefined
      state.total = 0
    },
    resetUserDetails: (state) => {
      state.user = undefined
    }
  }
})

export const {
  getUserList,
  getUserPendingCount,
  addUserList,
  updateUserEnabledInList,
  setUserDetail,
  setUserPoolId,
  setUserRole,
  resetUserList,
  resetUserDetails
} = content.actions

export default content.reducer
