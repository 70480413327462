import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  catalogList: [],
  catalogListTotal: undefined,
  categoryContent: undefined,
  categoryContentOriginal: undefined
}

// Store
export const manageCategory = createSlice({
  name,
  initialState,
  reducers: {
    getCatalogContent: (state, { payload: { content, total } }) => {
      state.catalogList = content
      state.catalogListTotal = total
    },
    fetchCatalogContent: (state, { payload: { content, total } }) => {
      state.catalogList = state.catalogList.concat(content)
      state.catalogListTotal = total
    },
    getCategoryContent: (state, { payload: { content } }) => {
      state.categoryContent = content
      state.categoryContentOriginal = content
    },
    addCategoryContent: (state, { payload }) => {
      state.categoryContent = state.categoryContent.concat(payload)
    },
    rmCategoryContent: (state, { payload: { sk } }) => {
      const indexRm = state.categoryContent.findIndex(c => c.sk === sk)
      state.categoryContent.splice(indexRm, 1)
    },
    reset: () => initialState
  }
})

export const {
  getCatalogContent,
  fetchCatalogContent,
  getCategoryContent,
  addCategoryContent,
  rmCategoryContent,
  reset
} = manageCategory.actions

export default manageCategory.reducer
