import { ConfirmationAlert, FormInputRow, ImageEdit, ImageForm, ImageUploader, ImageUtil } from '@emerald-works-nova/components'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { isEqual } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { BannerStatic } from '../../../../components'
import { ContentSlice } from '../../../../reducers'
import { useContentForm } from '../../content-form-context'
import { useStyles } from './style'

const Thumbnail = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { spacing } = useTheme()
  const { form, setContentSaveError, isReadOnly, saveContent } = useContentForm()
  const content = useSelector(ContentSlice.selectors.selectContent)
  const formImage = form.getValues().image

  const { formState: { isDirty }, setValue, getValues, watch, ...formThumbnail } = useForm({
    defaultValues: {
      ...formImage
    }
  })

  const [thumbEditOpen, setThumbEditOpen] = React.useState(false)
  const [showRemoveAlert, setShowRemoveAlert] = React.useState(false)
  const imgInfo = watch()

  const [contentAddImage, contentSave, contentRemoveImage] = useEvent([
    { eventName: 'catalogAddImage' },
    ContentSlice.eventBus.saveContent,
    { eventName: 'catalogRemoveImage' }
  ])

  useEventsOnViewLoad(() => {
    contentAddImage.registerAdhocOnSuccessListener(({ payload: { content } }) => {
      dispatch(ContentSlice.actions.setContent({ content }))
      dispatch(ContentSlice.actions.updateContentInList(content))
      setContentSaveError(false)
    })

    contentAddImage.registerAdhocOnErrorListener(() => {
      setContentSaveError(true)
    })
  }, [contentAddImage])

  useEventsOnViewLoad(() => {
    contentSave.registerAdhocOnSuccessListener(({ payload }) => {
      setContentSaveError(false)
      dispatch(ContentSlice.actions.updateContentInList(payload.content))
    })

    contentSave.registerAdhocOnErrorListener(() => {
      setContentSaveError(true)
    })
  }, [contentSave])

  useEventsOnViewLoad(() => {
    contentRemoveImage.registerAdhocOnSuccessListener(({ payload: { content } }) => {
      dispatch(ContentSlice.actions.setContent({ content }))
      dispatch(ContentSlice.actions.updateContentInList(content))
      formThumbnail.reset({})
      form.resetField('image')
      setShowRemoveAlert(false)
      setContentSaveError(false)
      BannerStatic.show({ label: 'Image deleted successfully' })
    })

    contentRemoveImage.registerAdhocOnErrorListener(() => {
      setShowRemoveAlert(false)
      setContentSaveError(true)
      BannerStatic.show({ label: 'There was an error deleting the image', isError: true })
    })
  }, [contentRemoveImage, formThumbnail])

  const parseImgixUrl = React.useCallback((s3Url, imgixParams = {}) => {
    return ImageUtil.ImgixGenerateImageUrl(s3Url, { ...imgixParams, w: 425, h: 285 })
  }, [])

  const [imgPreviewUrl, setImgPreviewUrl] = React.useState(parseImgixUrl(formImage?.s3Url, formImage?.imgixCropParams))

  // Update form state with content
  React.useEffect(() => {
    // Only set the form state once
    // If content is present and form has no change
    const { image } = form.getValues() || {}
    if (image?.name && !isDirty) {
      Object.entries(image).forEach(([name, value]) => setValue(name, value))
    }
  }, [form, isDirty, setValue])

  const handleOnDropAccepted = React.useCallback(imgInfo => {
    Object.entries(imgInfo).forEach(([name, value]) => setValue(name, value))
    setImgPreviewUrl(parseImgixUrl(imgInfo.s3Url))
    saveContent.trigger(form.getValues())
  }, [setValue, parseImgixUrl, form, saveContent])

  const handleCropDone = React.useCallback(cropInfo => {
    Object.entries(cropInfo).forEach(([name, value]) => setValue(name, value))
    const newImgPreviewUrl = parseImgixUrl(imgInfo.s3Url, cropInfo.imgixCropParams)
    setImgPreviewUrl(newImgPreviewUrl)
  }, [setValue, imgInfo.s3Url, parseImgixUrl])

  const handleCloseCropModal = React.useCallback(() => setThumbEditOpen(false), [])

  // watch form changes to trigger auto save
  // is form has changes and data is present
  // trigger auto save
  watch(values => {
    const hasChanges = !isEqual(form.getValues('image'), values)
    if (hasChanges) form.setValue('image', values)
  })

  const handleRemoveImage = React.useCallback(async () => {
    contentRemoveImage.trigger({ locationId: content.locationId })
  }, [contentRemoveImage, content.locationId])

  return (
    <>
      <ImageEdit open={thumbEditOpen} image={imgInfo} onClose={handleCloseCropModal} onCropDone={handleCropDone} isReadOnly={isReadOnly} />
      <ConfirmationAlert
        title='Deleting thumbnail?'
        description={"Deleting the thumbnail will also remove all the information added, and it won't be possible to restore it later."}
        agreeText='Delete'
        isLoading={contentRemoveImage.isWorking}
        showModal={showRemoveAlert}
        onConfirmation={handleRemoveImage}
        onCancel={() => setShowRemoveAlert(false)}
        disableBackdropClick
      />
      <Grid container direction='column' className={classes.root}>
        <Typography variant='h6' tabIndex={0}>Image details</Typography>
        <Divider style={{ marginTop: spacing(3) }} />
        {!imgInfo?.imgUrl ? (
          // Image Upload Page
          <FormInputRow
            hideTitle
            description={`The thumbnail will represent the content visually to the end-user, 
            and is a requirement for this content to be ready for publication.`}
            InputComponent={
              <ImageUploader
                onDropAccepted={handleOnDropAccepted}
                imageNamePrefix={content.title}
              />
            }
          />
        ) : (
          // Image editing page
          <ImageForm
            form={formThumbnail}
            imgInfo={imgInfo}
            onEditImage={setThumbEditOpen}
            onRemoveImage={setShowRemoveAlert}
            imgUrl={imgPreviewUrl}
            isReadOnly={isReadOnly}
          />
        )}
      </Grid>
    </>
  )
}

export default Thumbnail
