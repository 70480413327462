import { ActionButton } from '@emerald-works-nova/components'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { Box, Grid } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import React from 'react'
import { useSelector } from 'react-redux'
import { OnboardingRoles } from '../../../../helpers/roles/department-data'
import { UserProfileSlice } from '../../../../reducers'
import { DepartmentRadio } from '../department-checkbox'
import { useStyles } from './style'

export const OnboardingScreen = React.memo(() => {
  const classes = useStyles()
  const [selectedValue, setSelectedValue] = React.useState()
  const userProfile = useSelector(UserProfileSlice.selectors.selectUser)

  React.useEffect(() => { setSelectedValue(userProfile?.preferredRole) }, [userProfile])

  const [updateProfile] = useEvent([UserProfileSlice.eventBus.updateUserProfile])
  const handleChooseRole = () => updateProfile.trigger({ partial: { preferredRole: selectedValue } })
  const handleChange = ({ target: { value } }) => setSelectedValue(value)

  return (
    <>
      <Box display='flex' flexDirection='column' alignItems='center' mt={11.25} mb={8}>
        <Box fontSize={34} fontWeight={700} mb={1}>Welcome to Ark!</Box>
        <Box fontSize={18} mb={1}>To begin this journey, tell us what type of role you'd be involved.</Box>
        <Box fontSize={14} mb={1}>To continue, please select only one option below.</Box>
      </Box>
      <Grid container className={classes.deptRadioCtn}>
        {OnboardingRoles.map((side, idx) => (
          <Grid item container key={idx} justifyContent='center' alignContent='flex-start' xs={6}>
            {side.map((dept, idx) => <DepartmentRadio {...dept} key={idx} selectedValue={selectedValue} onChange={handleChange} />)}
          </Grid>
        ))}
      </Grid>
      <Box display='flex' justifyContent='center' mt={8}>
        <ActionButton
          variant='primary'
          dataTest='onboard-continue'
          onClick={handleChooseRole}
          startIcon={<ArrowForwardIcon />}
          isWorking={updateProfile.isWorking}
          disabled={!selectedValue}
        >
          Continue
        </ActionButton>
      </Box>
    </>
  )
})
