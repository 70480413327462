import { createSlice } from '@reduxjs/toolkit'
import { remove, findIndex, uniqBy } from 'lodash'
import name from './name'

const emptyEditor = {
  html: '',
  data: {}
}

const emptySubtitle = {
  html: ''
}
const emptyContent = {
  keywords: [],
  status: { type: 'draft' },
  body: emptyEditor,
  subtitle: emptySubtitle,
  metadata: [],
  updatedAt: '2020-02-28',
  exclusive: false,
  primaryType: [],
  image: {
    altText: '',
    author: '',
    caption: '',
    imgUrl: '',
    name: '',
    stockPhotos: ''
  }
}

// Store
export const content = createSlice({
  name,
  initialState: {
    content: emptyContent,
    list: [],
    total: 0
  },
  reducers: {
    setContent: (state, { payload }) => {
      state.content = {
        ...emptyContent,
        ...payload.content
      }
    },
    setContentImage: (state, { payload }) => {
      state.content.image = payload
    },
    setContentList: (state, { payload }) => {
      state.list = payload.content
      state.total = payload.total
    },
    setEmptyContent: (state) => {
      state.content = emptyContent
    },
    addContentList: (state, { payload }) => {
      state.list = state.list.concat(payload.content)
      state.total = payload.total
    },
    addContentToList: (state, { payload }) => {
      state.list = uniqBy(state.list.concat(payload), 'sk')
      state.total = state.total + 1
    },
    removeContentFromList: (state, { payload }) => {
      state.list = remove(state.list, (item) => item.sk !== payload.sk)
      state.total = state.total - 1
    },
    updateContentInList: (state, { payload }) => {
      const index = findIndex(state.list, { payload })
      state.list.splice(index, 1, payload)
    }

  }
})

export const {
  setContent,
  setContentList,
  setEmptyContent,
  addContentList,
  setContentImage,
  removeContentFromList,
  addContentToList,
  updateContentInList
} = content.actions

export default content.reducer
