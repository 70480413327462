import { useTenant } from '@emerald-works-nova/auth'
import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { routes } from '../../../../routes'
import { useArkUserListPending } from './useArkUserListPending'
import ArkUserGenericList from '../ark-user-generic-list'

const ArkUserListPending = ({ filter }) => {
  const navigate = useNavigate()
  const { userPoolId, pk: tenantId } = useTenant()

  const handleClick = user => { navigate(generatePath(routes.arkUserEdit.path, { id: user.username })) }
  const useList = useArkUserListPending({ tenantId, userPoolId, role: filter })

  return <ArkUserGenericList {...useList} onClick={handleClick} />
}

export default React.memo(ArkUserListPending)
