const buildToolkitUrl = (tenantKey, stage, toolkitAppName, tld, excludeProtocol = false) => {
  let toolkitUrl = `${tenantKey}.`

  if (stage !== 'live') toolkitUrl += `${stage}.`

  if (!tld.includes('mindtools') && toolkitAppName) toolkitUrl += `${toolkitAppName}.`

  toolkitUrl += `${tld}`

  return excludeProtocol ? toolkitUrl : 'https://' + toolkitUrl
}

export {
  buildToolkitUrl
}
