const options = {
  borderWidth: 1,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        precision: 0
      }
    },
    x: {
      grid: {
        display: true,
        drawOnChartArea: false
      },
      ticks: {
        callback: function (value, index, ticks) {
          return index < ticks.length - 1
            ? this.getLabelForValue(value)
              .toLocaleString('default', {
                day: 'numeric',
                month: 'short',
                year: index === 0 ? 'numeric' : undefined
              })
              .toLocaleUpperCase()
              .split('')
              .join('\u202F')
            : ''
        },
        align: 'start',
        padding: 10
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: true,
      callbacks: {
        title: context => {
          const date = new Date(context[0].label)
          return date.toLocaleString('en-GB', {
            day: 'numeric',
            month: 'short'
          }) + `, ${date.getFullYear()} `
        },
        label: context => {
          const val = context.raw
          return `${val} active user${val === 1 ? '' : 's'}`
        }
      }
    }
  }
}

export default options
