import { PrivateRoute, useSession } from '@emerald-works/react-auth'
import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { AllRolesStr } from './helpers/roles/department-data'
import { userHasAnyOfGroups } from './helpers/roles/user'
import PrimaryTemplate from './layouts/primary-layout'
import { ArkUserEdit, ArkUserList, ConsumerEdit, ConsumerList, Content, ContentEdit, ContentNew, HomePage, LoginPage, Onboarding, Reporting, TemplateEdit, TenantEdit, TenantList } from './pages'

const NoLayout = 'noLayout'

export const routes = {
  home: { path: '/', element: <HomePage /> },
  login: { path: '/login', element: <LoginPage />, public: true, layout: NoLayout },
  arkUsers: { path: '/ark-users/', element: <ArkUserList /> },
  arkUserEdit: { path: '/ark-users/:id', element: <ArkUserEdit /> },
  consumerList: { path: '/consumers/', element: <ConsumerList /> },
  consumerEdit: { path: '/consumers/:id', element: <ConsumerEdit /> },
  contentList: { path: '/contents', element: <Content /> },
  contentNew: { path: '/contents/new', element: <ContentNew /> },
  contentEdit: { path: '/contents/:locationId', element: <ContentEdit /> },
  onboarding: { path: '/onboarding', element: <Onboarding />, allowedUserGroups: /.*/, layout: NoLayout },
  templateList: { path: '/templates', element: <TemplateEdit /> },
  tenantList: { path: '/tenants', element: <TenantList /> },
  tenantNew: { path: '/tenants/new', element: <TenantEdit /> },
  tenantDetails: { path: '/tenants/:id', element: <TenantEdit /> },
  reporting: { path: '/reporting/', element: <Reporting />, allowedUserGroups: /manager|admin/ }
}

const ApplicationRoutes = () => {
  const { session, isAuthenticated } = useSession()
  const navigate = useNavigate()

  React.useEffect(() => {
    const onboarded = userHasAnyOfGroups(session, AllRolesStr)
    console.debug('isAuthenticated | Onboarded', isAuthenticated, onboarded, session)
    if (isAuthenticated && !onboarded) navigate(routes.onboarding.path)
  }, [session, isAuthenticated, navigate])

  const { normalizedRoutesNoLayout, normalizedRoutesPrimaryLayout } = Object.entries(routes)
    .reduce((curr, [_, definition], idx) => {
      const layoutKey = definition.layout === NoLayout ? 'normalizedRoutesNoLayout' : 'normalizedRoutesPrimaryLayout'
      const route = definition.public
        ? <Route {...definition} key={idx} />
        : <PrivateRoute allowedUserGroups={/clientManager|admin|editor|cx|marketing/} {...definition} key={idx} />

      curr[layoutKey].push(route)
      return { ...curr }
    }, { normalizedRoutesNoLayout: [], normalizedRoutesPrimaryLayout: [] })

  return (
    <Routes>
      <Route element={<PrimaryTemplate />}>{normalizedRoutesPrimaryLayout}</Route>
      {normalizedRoutesNoLayout}
    </Routes>
  )
}

export default ApplicationRoutes
