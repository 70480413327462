import { Divider, Grid } from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import { enGB } from 'date-fns/locale'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContentTypeIcon, Panel } from '../../../../components'
import { ContentEditorSlice, ContentSlice } from '../../../../reducers'
import { SectionMap } from '../../../../reducers/content-edit/slice'
import FillingInformation from '../filling-information'
import { get as GetValue, merge as MergeObject, isObject, isEmpty } from 'lodash'
import { useStyles } from './style'

const FillingInformationPanel = React.memo(({ selectedTab }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const content = useSelector(ContentSlice.selectors.selectContent)
  const sections = useSelector(ContentEditorSlice.selectors.selectSections)

  React.useEffect(() => {
    const sectionsCopy = MergeObject({}, SectionMap)
    Object.values(sectionsCopy).forEach(section => {
      Object.values(section.steps).forEach(step => {
        const value = GetValue(content, step.name)
        if (
          !value || (Array.isArray(value) && !value.length) ||
          (isObject(value) && isEmpty(value))
        ) step.valid = false
        else step.valid = true
      })
    })
    dispatch(ContentEditorSlice.actions.setSections(sectionsCopy))
  }, [content, dispatch])

  const formattedDate = React.useMemo(() => format(parseISO(content.updatedAt), 'P', { locale: enGB }), [content.updatedAt])

  return (
    <Panel
      padding={16}
      aria-label='filling information panel'
      role='complementary'
      tabIndex={0}
    >
      <Grid container spacing={1} alignItems='center'>
        <Grid item>
          <ContentTypeIcon type={content.primaryType[0]} />
        </Grid>
        <Grid
          item
          tabIndex={0}
          aria-label={`last update, ${formattedDate}`}
          className={classes.infoLastUpdated}
        >
          {formattedDate}
        </Grid>
      </Grid>
      <Divider />
      {Object.entries(sections).map(([key, section], index, entries) => (
        <div key={key} className={classes.stepsContainer}>
          <FillingInformation value={key} section={section} isSelected={selectedTab === key} />
          {!(entries.length === index + 1) && <Divider className={classes.stepDivider} />}
        </div>
      ))}
    </Panel>
  )
})

export default FillingInformationPanel
