/* eslint-disable indent */
import { IconButton, Snackbar } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import MuiAlert from '@material-ui/lab/Alert'
import React from 'react'

const defaultAnchor = {
  vertical: 'top',
  horizontal: 'right'
}

const Toast = ({
  open,
  onClose,
  label,
  isError,
  isWarning,
  autoHideDelay = 5000,
  anchorOrigin = defaultAnchor
}) => {
  const severity = React.useMemo(() => {
    if (isError) return 'error'
    if (isWarning) return 'warning'
    return 'success'
  }, [isError, isWarning])

  return (
    <Snackbar
      key={label}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDelay}
      anchorOrigin={anchorOrigin}
      action={
        <IconButton
          size='small'
          aria-label='close'
          onClick={onClose}
          color='inherit'
          data-test='toast-close'
        >
          <Close fontSize='small' />
        </IconButton>
      }
    >
      <MuiAlert elevation={6} variant='filled' severity={severity}>{label}</MuiAlert>
    </Snackbar>
  )
}

export default Toast
