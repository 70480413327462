import { FormInputRow, Input } from '@emerald-works-nova/components'
import { Box, Grid, InputAdornment, Switch, Typography } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTenantFormContext } from '../../tenant-form-context'

const METERING_FORM_FIELD = 'meteringEnabled'

const Metering = () => {
  const {
    form
  } = useTenantFormContext()
  const { watch } = form

  const meteringEnabled = watch(METERING_FORM_FIELD)

  return (
    <>
      <Grid item xs={12}>
        <FormInputRow
          title={
            <Box display='flex' flexDirection='row' alignItems='center' component='span'>
              <Typography component='span'>Metering</Typography>
              <Controller
                name={METERING_FORM_FIELD}
                control={form.control}
                render={(props) => (
                  <Switch
                    onChange={(e) => props.field.onChange(e.target.checked)}
                    color='primary'
                    checked={props.field.value}
                  />
                )}
              />
            </Box>
          }
          description='A metering limit sets the amount of content users can access without a paid subscription.'
          smallFormTitles
        />
      </Grid>
      {meteringEnabled && (
        <>
          <Grid item xs={12}>
            <FormInputRow
              title='Set Limit'
              description='Number of articles a user can access for free in a given time period before being asked to pay.'
              smallFormTitles
              InputComponent={
                <Box style={{ width: '260px' }}>
                  <Input
                    placeholder='3'
                    name='meteringLimit'
                    type='number'
                    control={form.control}
                  />
                </Box>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputRow
              title='Set Reset Cycle'
              description='The reset cycle is the number of days that content is not available once non-subscribed users reach their metering limit. The cycle starts when they access their first article.'
              smallFormTitles
              InputComponent={
                <Box style={{ width: '260px' }}>
                  <Input
                    placeholder='Days'
                    name='meteringResetCycle'
                    type='number'
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>Days</InputAdornment>
                    }}
                    control={form.control}
                  />
                </Box>
              }
            />
          </Grid>
        </>
      )}
    </>
  )
}

export default Metering
