import * as actions from './slice'

const getContent = {
  eventName: 'catalogGet',
  onSuccess: {
    action: actions.setContent,
    redux: true
  }
}

const getContentList = callback => ({
  eventName: 'catalogSearch',
  onSuccess: [
    {
      action: actions.setContentList,
      redux: true
    },
    callback
  ]
})

const fetchMoreContent = {
  eventName: 'catalogSearch',
  onSuccess: {
    action: actions.addContentList,
    redux: true
  }
}

const saveContent = {
  eventName: 'catalogUpsert',
  onSuccess: [
    {
      action: actions.setContent,
      redux: true
    }
  ]
}

const removeContent = {
  eventName: 'catalogRemove',
  onSuccess: [
    {
      action: actions.setEmptyContent,
      redux: true
    }
  ]
}

const searchContent = {
  eventName: 'catalogSearch',
  onSuccess: [
    {
      action: actions.setEmptyContent,
      redux: true
    }
  ]
}

export {
  getContent,
  searchContent,
  getContentList,
  fetchMoreContent,
  saveContent,
  removeContent
}
