import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  gridRow: {
    borderBottom: '1px solid #E0E0E0',
    width: ' 100%',
    margin: 'unset',
    padding: spacing(1.5, 0),
    '&:hover': {
      backgroundColor: palette.background.selected,
      cursor: 'pointer'
    },
    '&:focus': {
      backgroundColor: palette.background.selected
    }
  },
  gridRowLast: {
    border: 'none'
  },
  dateUpdatedColumn: {
    textAlign: 'center'
  },
  typeColumn: {
    color: `var(--type-color, ${palette.contentType.article})`,
    textTransform: 'capitalize',
    '& svg': {
      marginRight: spacing(1.5),
      marginTop: spacing(-0.5),
      float: 'left'
    }
  },
  textSummary: {
    color: palette.text.secondary,
    maxWidth: '42rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cardImage: {
    width: 40,
    height: 40,
    borderRadius: 4,
    marginRight: spacing(2),
    float: 'left'
  },
  noImageIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '40px',
    marginRight: '16px',
    padding: spacing(1.2, 1.5),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    color: palette.text.disabled,
    '& svg': {
      marginTop: 3
    }
  },
  checkBoxLblRoot: {
    width: ' 100%',
    margin: 'unset',
    '&:hover': {
      backgroundColor: palette.background.selected,
      cursor: 'pointer'
    },
    '&:focus': {
      backgroundColor: palette.background.selected
    }
  },
  checkBoxLbl: {
    width: ' 100%',
    marginLeft: spacing(2)
  }
}))
