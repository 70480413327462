import { CircularProgress, Grid, Switch, Typography } from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import { useStyles } from './style'
import React from 'react'
import { TenantListSlice } from '../../../reducers'
import { useDispatch } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { BannerStatic } from '../../../components'

const TenantItem = React.memo(({ tenant, index, onClick, onKeyPress }) => {
  const { isActive, pk } = tenant

  const dispatch = useDispatch()
  const status = React.useMemo(() => isActive ? 'Active' : 'Inactive', [isActive])

  const [tenantToggleActive] = useEvent([{
    eventName: 'tenantToggleActive',
    onSuccess: (result) => {
      dispatch(TenantListSlice.actions.updateTenantIsActiveInList({ pk, isActive: result.isActive }))
      BannerStatic.show({ label: 'Toolkit status changed successfully' })
    },
    onError: (e) => {
      console.log('error toggling tenant status', e)
      BannerStatic.show({ label: 'Error while attempting to change the Tenant status', isError: true })
    }
  }])

  const classes = useStyles({ isActive, isLoading: tenantToggleActive.isWorking })
  const handleClick = React.useCallback(ev => {
    ev.stopPropagation()
    tenantToggleActive.trigger({ pk, isActive: ev.target.checked })
  }, [tenantToggleActive, pk])

  return (
    <Grid
      role='row button'
      data-test={`tenant-list-item-${index}`}
      container
      tabIndex={0}
      direction='row'
      alignItems='center'
      classes={{ root: classes.root }}
      onClick={() => onClick(tenant)}
      onKeyPress={(e) => onKeyPress(e, tenant)}
    >
      <Grid item md={4} className={classes.tenantColumn}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item>
            <Typography variant='body2' data-test={`tenant-name-${index}`}>
              {tenant.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} data-test='client-name'>
        <Typography variant='body2' data-test={`client-name-${index}`}>
          {tenant.clientName}
        </Typography>
      </Grid>
      <Grid item md={2} data-test='creation-date'>
        <Typography className={classes.creationDate} variant='body2' data-test={`creation-date-${index}`} title={format(parseISO(tenant.createdAt), 'PPpppp')}>
          {parseISO(tenant.createdAt).toLocaleDateString()}
        </Typography>
      </Grid>
      <Grid item md={2} container alignItems='center' data-test='status'>
        <Grid item md={4}>
          <Typography className={classes.status} variant='body2' data-test={`status-${index}`}>
            {status}
          </Typography>
        </Grid>
        {tenant.canDeactivate && (
          <Grid item md container alignItems='center'>
            <Switch
              checked={isActive || false}
              onClick={(ev) => handleClick(ev)}
              color='primary'
              inputProps={{ 'aria-label': 'tenant active switch' }}
              disabled={tenantToggleActive.isWorking}
            />
            {tenantToggleActive.isWorking && <CircularProgress size={20} color='primary' />}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
})

export default TenantItem
