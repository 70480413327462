import {
  lighten,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'

const UserTable = ({ data }) => {
  const { palette } = useTheme()
  return (
    <TableContainer component='div'>
      <Table>
        <TableBody>
          {(data ?? new Array(10).fill({})).map(({ sk, name, count }, idx) => (
            <TableRow key={sk ?? idx}>
              <TableCell scope='row'>
                {name ?? <Skeleton width={120} />}
              </TableCell>
              <TableCell align='right'>
                {count ? (
                  `${count} resource${count > 1 ? 's' : ''}`
                ) : (
                  <Skeleton />
                )}
              </TableCell>
              <TableCell width='35%'>
                {count ? (
                  <div
                    style={{
                      backgroundColor: lighten(
                        palette.primary.main,
                        1 - count / data[0].count
                      ),
                      width: `${(count / data[0].count) * 100}%`,
                      height: 24
                    }}
                  />
                ) : (
                  <Skeleton
                    variant='rect'
                    height={24}
                    width={` ${(1 / (idx + 1)) * 100}%`}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UserTable
