import { FormInputRow, Input } from '@emerald-works-nova/components'
import { Grid } from '@material-ui/core'
import React from 'react'
import { useTemplateFormContext } from '../template-form-context'
import { useStyles } from './style'

const DetailTab = () => {
  const classes = useStyles()
  const { form } = useTemplateFormContext()
  const { control, formState: { errors } } = form

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <FormInputRow
            title='Template Name'
            description='This is the template name. '
            InputComponent={
              <Input
                autoFocus
                rules={{ required: true }}
                label='Name'
                name='name'
                control={control}
                valid={errors.name === undefined}
                error='Template Name is required'
                showErrors
                disabled
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputRow
            title='Template Base'
            description='Select one of the predefined templates from the list on the side as a basis for creating the New Template.'
            InputComponent={
              <Input
                label='Template Base'
                name='base'
                control={control}
                InputProps={{ readOnly: true }}
                disabled
              />
            }
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default DetailTab
