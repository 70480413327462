import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing }) => ({
  appBarContainer: {
    position: 'fixed',
    zIndex: 2,
    background: '#FFF',
    top: 0,
    left: 0,
    padding: 0
  },
  container: {
    // Our toolbar is fixed and it is 64px tall
    minHeight: 'calc(100vh - 64px)',
    marginTop: '64px'
  },
  mainContainer: {
    flex: 1,
    paddingTop: spacing(3)
  },
  footerContainer: {
    marginTop: spacing(2)
  }
}))
