import { useStyles } from './style'

const Time = ({ time }) => {
  const classes = useStyles()
  const hours = Math.round(time / 60)
  let mins = time % 60
  mins = hours ? Math.round(mins) : mins.toFixed(1)

  return (
    <>
      {hours > 0 && (
        <>
          {hours}
          <span className={classes.small}>{hours === 1 ? 'hr' : 'hrs'}</span>
        </>
      )}
      <span> </span>
      {mins}
      <span className={classes.small}>{mins === 1 ? 'min' : 'mins'}</span>
    </>
  )
}

export default Time
