
import { createTheme, responsiveFontSizes } from '@material-ui/core'
import { createPalette } from './palette'
import { createTypography } from './typography'

const createAppTheme = (options = {}) => {
  const {
    palette: paletteInput = {},
    typography: typographyInput = {},
    ...others
  } = options

  const palette = createPalette(paletteInput)
  const typography = createTypography(typographyInput)

  const theme = createTheme({
    overrides: {
      MuiTypography: {
        gutterBottom: {
          marginBottom: 16
        }
      }
    },
    palette,
    typography,
    ...others
  })

  // make font-size of Typography elements responsive:
  // https://material-ui.com/customization/typography/#responsive-font-sizes
  return responsiveFontSizes(theme)
}

export { createAppTheme as createTheme }
