import { ActionButton } from '@emerald-works-nova/components'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Backdrop, CircularProgress, Grid, Tab, Tabs, useTheme } from '@material-ui/core'
import { Assignment, SupervisorAccount, VerticalSplit } from '@material-ui/icons'
import classNames from 'classnames'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { BannerStatic, Panel, SectionHeader, TabPanel } from '../../components'
import { FolderStructureIcon } from '../../components/icons'
import { AppSlice, TenantEditSlice } from '../../reducers'
import { routes } from '../../routes'
import Branding from './branding-tab'
import DetailTab from './detail-tab'
import Features from './features-tab'
import SamlTab from './saml-tab'
import { useStyles } from './style'
import TemplateTab from './template-tab'
import { TenantFormContext } from './tenant-form-context'
import UsersTab from './users-tab'

const SectionNames = {
  details: 'details',
  template: 'template',
  branding: 'branding',
  features: 'features',
  saml: 'saml',
  users: 'users'
}
const SectionMap = {
  [SectionNames.details]: {
    component: <DetailTab />,
    title: 'Details',
    icon: <Assignment />,
    next: SectionNames.branding,
    isVisible: () => true,
    isDisabled: () => false
  },
  [SectionNames.branding]: {
    component: <Branding />,
    title: 'Branding',
    icon: <FolderStructureIcon />,
    previous: SectionNames.details,
    next: SectionNames.template,
    isVisible: () => true,
    isDisabled: () => false
  },
  [SectionNames.template]: {
    component: <TemplateTab />,
    title: 'Website',
    icon: <FolderStructureIcon />,
    previous: SectionNames.branding,
    next: SectionNames.features,
    isVisible: () => true,
    isDisabled: () => false
  },
  [SectionNames.features]: {
    component: <Features />,
    title: 'Features',
    icon: <VerticalSplit />,
    previous: SectionNames.template,
    next: SectionNames.saml,
    isVisible: () => true,
    isDisabled: ({ isEdit }) => !isEdit
  },
  [SectionNames.saml]: {
    component: <SamlTab />,
    title: 'Single Sign On',
    icon: <FolderStructureIcon />,
    previous: SectionNames.features,
    isVisible: () => true,
    isDisabled: ({ isEdit }) => !isEdit
  },
  [SectionNames.users]: {
    component: <UsersTab />,
    title: 'Users',
    icon: <SupervisorAccount />,
    previous: SectionNames.saml,
    isVisible: ({ tenantKey }) => tenantKey !== 'admin' && tenantKey !== 'www',
    isDisabled: ({ isEdit }) => !isEdit
  }
}

const TenantEdit = () => {
  const classes = useStyles()
  const { spacing } = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const form = useForm({ mode: 'onChange' })
  const { getValues, watch, setValue, reset } = form
  const { id } = useParams()
  const isEdit = React.useMemo(() => !!id, [id])
  const envInfo = useSelector(AppSlice.selectors.selectEnvInfo)
  const tenant = useSelector(TenantEditSlice.selectors.tenant)

  const [tabValue, setTabValue] = React.useState(Object.entries(SectionMap)[0][0])
  const handleTabChange = React.useCallback((_, newValue) => setTabValue(newValue), [setTabValue])

  const [isLoading, setIsLoading] = React.useState(false)
  const [loadingMsg, setLoadingMsg] = React.useState()

  const [tenantInsert, tenantUpdate, createUser, getTenant] = useEvent([
    { eventName: 'tenantInsert', shouldTimeout: false },
    { eventName: 'tenantUpdate' },
    { eventName: 'createTempManagerUser' },
    TenantEditSlice.eventBus.getTenant
  ])

  useEventsOnViewLoad(() => {
    if (!id) return
    setLoadingMsg('Loading toolkit data...')
    setIsLoading(true)
    getTenant.trigger({ pk: id })
  }, [getTenant])

  useEventsOnViewLoad(() => {
    tenantInsert.registerAdhocOnSuccessListener(({ payload: { tenant } }) => {
      const {
        pk,
        userPoolId,
        cognitoSettings: { identityPoolId }
      } = tenant
      createUser.trigger({ tenantId: pk, userPoolId, identityPoolId })

      BannerStatic.show({ label: 'Toolkit saved successfully' })
      navigate(routes.tenantList.path)
      setIsLoading(false)
    })
    tenantInsert.registerAdhocOnErrorListener((...args) => {
      console.log('tenantInsert.registerAdhocOnErrorListener', args)
      BannerStatic.show({ label: 'There was an error creating the Client Toolkit', isError: true })
    })

    tenantUpdate.registerAdhocOnSuccessListener(() => {
      BannerStatic.show({ label: 'Toolkit updated successfully' })
      setIsLoading(false)
    })
  }, [tenantInsert, tenantUpdate, createUser])

  React.useEffect(() => {
    if (!tenant) return
    reset(tenant)
    setValue('tenantId', tenant.pk)
    setIsLoading(false)
  }, [tenant, reset, setValue])

  // reset redux store on leave
  React.useEffect(() => {
    return () => dispatch(TenantEditSlice.actions.reset())
  }, [dispatch])

  const handleCreateTenant = React.useCallback(() => {
    if (tabValue === SectionNames.template && !isEdit) {
      const tenantData = {
        ...getValues(),
        tenantUrl: undefined,
        createdAt: undefined,
        updatedAt: undefined
      }
      tenantInsert.trigger(tenantData)
      setLoadingMsg('Saving toolkit...')
      setIsLoading(true)
      return
    }
    setTabValue(SectionMap[tabValue].next)
  }, [tenantInsert, isEdit, getValues, tabValue, setTabValue])

  const handleNextClick = React.useCallback(
    () => setTabValue(SectionMap[tabValue].next),
    [tabValue]
  )
  const handlePreviousClick = React.useCallback(
    () => setTabValue(SectionMap[tabValue].previous),
    [tabValue]
  )

  const onUpdateTenant = React.useCallback(() => {
    const tenantData = {
      ...getValues(),
      tenantUrl: undefined,
      createdAt: undefined,
      updatedAt: undefined
    }

    if (tenantData.meteringLimit) {
      tenantData.meteringLimit = Number(tenantData.meteringLimit)
    }

    if (tenantData.meteringResetCycle) {
      tenantData.meteringResetCycle = Number(tenantData.meteringResetCycle)
    }

    tenantUpdate.trigger(tenantData)
    setLoadingMsg('Updating toolkit...')
    setIsLoading(true)
  }, [tenantUpdate, getValues])

  const handleCancel = React.useCallback(() => navigate(routes.tenantList.path), [navigate])

  const tenantName = watch('name')

  if ((id && !tenant) || isLoading) {
    return (
      <Backdrop
        id='backdrop-loading'
        className={classes.backdrop}
        open={isLoading}
      >
        <Grid
          container
          direction='column'
          className={classes.loadingCtn}
          justifyContent='center'
          alignItems='center'
        >
          <Grid item>
            <CircularProgress size={100} />
          </Grid>
          <Grid item className={classes.loadingMsg}>
            <strong>{loadingMsg}</strong>
          </Grid>
        </Grid>
      </Backdrop>
    )
  }

  return (
    <TenantFormContext.Provider
      value={{
        form,
        tenant,
        isEdit,
        mainClasses: classes,
        setIsLoading,
        setLoadingMsg,
        onUpdateTenant,
        envInfo,
        handleNextClick,
        handlePreviousClick,
        handleCreateTenant
      }}
    >
      <Grid container style={{ marginBottom: spacing(3) }}>
        <Grid item xs={9} data-test='title'>
          <SectionHeader
            title={tenantName || 'New Toolkit'}
            location='Client Toolkit /'
          />
        </Grid>
        <Grid item xs={3} className={classes.controlBtnContainer}>
          <ActionButton
            variant='secondary'
            dataTest='backtolist-button'
            onClick={handleCancel}
          >
            {' '}
            Back to list
          </ActionButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* Tenant tabs */}
        <Grid item xs={12}>
          <Panel padding={0}>
            <Grid container>
              <Grid item xs={12}>
                <Tabs
                  indicatorColor='primary'
                  textColor='primary'
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label='Tenant Tabs'
                  classes={{
                    root: classes.tabs
                  }}
                >
                  {Object.entries(SectionMap)
                    // SSO tab is only visible when editing <- is it though?
                    .filter(([key]) => SectionMap[key].isVisible({ tenantKey: tenant?.tenantKey, isEdit }))
                    .map(([key, section]) => (
                      <Tab
                        id={`tab-${key}`}
                        aria-controls={`tabpanel-${key}`}
                        key={key}
                        value={key}
                        label={section.title}
                        icon={section.icon}
                        classes={{
                          wrapper: classes.tabWrapper,
                          root: classNames(
                            {
                              [classes.tabDisabled]: SectionMap[key].isDisabled(
                                { isEdit }
                              )
                            },
                            { [classes.clickDisabled]: !isEdit }
                          )
                        }}
                      />
                    ))}
                </Tabs>
              </Grid>
            </Grid>
            {/* Tabs content */}
            {Object.entries(SectionMap).map(([key]) => (
              <TabPanel key={key} value={tabValue} index={key}>
                {SectionMap[key].component}
              </TabPanel>
            ))}
          </Panel>
        </Grid>
      </Grid>
    </TenantFormContext.Provider>
  )
}

export default TenantEdit
