import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Storage } from '@aws-amplify/storage'

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'

// Theme
import 'tinymce/themes/silver'
// Toolbar icons
import 'tinymce/icons/default'
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css'

import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/code'
import 'tinymce/plugins/autoresize'

export default function TinyEditorComponent (props) {
  const { setValue, value, name } = props
  const { REACT_APP_S3_BUCKET, REACT_APP_S3_REGION, REACT_APP_S3_DOC_FOLDER } = process.env

  return (
    <Editor
      onEditorChange={(ev) => {
        console.log(ev)
        setValue(name, ev)
      }}
      value={value}
      init={{
        plugins: 'image link code preview autoresize',
        height: 'auto',
        toolbar: 'image code link',
        skin: 'material-classic',
        image_uploadtab: true,
        automatic_uploads: true,
        imagetools_cors_hosts: ['amazonaws.com'],
        imagetools_credentials_hosts: ['amazonaws.com'],
        images_upload_handler: async function (blobInfo, success, failure, progress) {
          try {
            const file = blobInfo.blob()
            const fileName = blobInfo.filename()
            // const fileType = blobInfo.blob().type
            // const fileSize = blobInfo.blob().size
            const fileExt = fileName.split('.').pop()
            const fileKey = `${fileName}-${Date.now()}`
            const filePath = `${fileKey}.${fileExt}`
            const fileUrl = `https://${REACT_APP_S3_BUCKET}.s3.${REACT_APP_S3_REGION}.amazonaws.com/${REACT_APP_S3_DOC_FOLDER}/${filePath}`
            await Storage.put(filePath, file, {
              progressCallback: _progress => progress(_progress.loaded / _progress.total * 100)
            })
            success(fileUrl)
          } catch (err) {
            failure(err)
          }
        },
        a11y_advanced_options: true
      }}
    />
  )
}
