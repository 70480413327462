import { ActionButton } from '@emerald-works-nova/components'
import { Button, CircularProgress, Grid, Paper, Typography, Box } from '@material-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { TenantEditSlice } from '../../../reducers'
import { useStyles } from './style'
import UserItem from './user-item/user-item'
import UserDetail from './user-detail'
import { useTenantFormContext } from '../tenant-form-context'
import { useUserList } from './hooks'

const UserTab = () => {
  const classes = useStyles()
  const tenant = useSelector(TenantEditSlice.selectors.tenant)
  const { userPoolId, tenantKey } = tenant
  const [userDetail, setUserDetail] = useState()

  const {
    mainClasses
  } = useTenantFormContext()

  const { userList, isLoading, onFetchMoreUsers, lastKey } = useUserList({
    userPoolId
  })

  const handleUserClick = user => {
    setUserDetail(user)
  }

  const handleUserKeyPress = (e, user) => e.which === 13 && handleUserClick(user)

  const backToListClick = () => {
    setUserDetail(undefined)
  }

  return (
    <Box className={classes.container}>
      <Grid container alignItems='center' className={classes.ctnTitle}>
        <Grid item xs={8}>
          <Typography variant='h6'>Users</Typography>
        </Grid>
        <Grid container item justifyContent='flex-end' xs={4}>
          <ActionButton
            variant='primary'
            dataTest='button-save-tenant-sso'
            disabled
            className={mainClasses.navBtn}
          >Save Changes
          </ActionButton>
        </Grid>
      </Grid>
      <Paper
        id='user-list'
        classes={{ root: classes.tablePaper }}
        tabIndex={0}
        aria-label='Content List'
        elevation={0}
        style={{ display: userDetail ? 'none' : 'inherit' }}
      >
        <Grid
          id='user-list-headers'
          container
          tabIndex={0}
          direction='row'
          alignItems='center'
          className={classes.gridHeader}
          classes={{ root: classes.tableHead }}
          aria-label='List headers, Title, Type, Last update'
        >
          <Grid item md={10}>Full Name</Grid>
          <Grid item md={2}>Status</Grid>

        </Grid>
        {!isLoading && userList.length > 0 && userList.map((row, key) => (
          <UserItem
            key={key}
            index={key}
            user={row}
            classes={classes}
            userPoolId={userPoolId}
            lastOne={key === userList.length - 1}
            onClick={() => handleUserClick(row)}
            onKeyPress={(e) => handleUserKeyPress(e, row)}
          />
        ))}
        {isLoading && (
          <Grid
            id='user-list-loading'
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            classes={{ root: classes.loadingAnimation }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
        {!isLoading && !userList.length && (
          <Grid
            id='user-list-empty'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.emptyList }}
          >
            <Typography variant='h5'> No tenant found! </Typography>
          </Grid>
        )}
      </Paper>
      {!isLoading && lastKey && (
        <Grid
          id='users-list-load-more'
          container
          direction='row'
          justifyContent='center'
          align='center'
          classes={{ root: classes.loadMoreBtn }}
          style={{ display: userDetail ? 'none' : 'inherit' }}
        >
          <Grid item xs={12}>
            <Button variant='contained' color='primary' data-test='load-more-button' onClick={onFetchMoreUsers}>
              Load More
            </Button>
          </Grid>
        </Grid>
      )}
      {userDetail && (
        <UserDetail
          userPoolId={userPoolId}
          user={userDetail}
          onBackToListClick={() => backToListClick()}
          tenantKey={tenantKey}
        />
      )}
    </Box>
  )
}

export default UserTab
