import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  root: {
    width: '100%',
    padding: `0 ${spacing(3)}px`
  },
  rootCollapsed: {
    padding: `0 ${spacing(3)}px ${spacing(3)}px`
  },
  accordion: {
    '&:before': {
      display: 'none'
    }
  },
  accordionExapanded: {
    margin: '0 !important'
  },
  heading: {
    fontSize: 20,
    color: palette.text.secondary
  },
  headingActive: {
    color: palette.text.primary
  },
  summary: {
    padding: 0,
    height: 72,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  summaryNoBorder: {
    borderBottom: 'none'
  },
  detail: {
    padding: 0
  },
  exclusiveDescription: {
    marginBottom: spacing(3)
  },
  exclusiveLabel: {
    ...typography.caption
  }

}))
