import { FormInputRow } from '@emerald-works-nova/components'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Switch, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTenantFormContext } from '../../tenant-form-context'
import { Controller } from 'react-hook-form'

const STUBBED_CONTENT_ENABLED_FORM_FIELD = 'stubbedContentEnabled'

const STATUS_MESSAGE = {
  ON: 'It means exclusive content are availble only for users with paid subscriptions.',
  OFF: 'It means exclusive content are availble for all users.'
}

const StubbedContent = () => {
  const {
    form
  } = useTenantFormContext()

  const [helpModalOpen, setHelpModalOpen] = useState(false)

  const handleOpen = () => setHelpModalOpen(true)
  const handleClose = () => setHelpModalOpen(false)

  const stubbedContentEnabled = form.watch(STUBBED_CONTENT_ENABLED_FORM_FIELD)
  const status = stubbedContentEnabled ? 'on' : 'off'
  const statusMessage = stubbedContentEnabled ? STATUS_MESSAGE.ON : STATUS_MESSAGE.OFF

  return (
    <Grid item xs={12}>
      <Dialog open={helpModalOpen} aria-labelledby='help-dialog-title' maxWidth='lg'>
        <DialogTitle id='help-dialog-title'>How to create exclusive content</DialogTitle>
        <DialogContent>
          <img src={`${process.env.PUBLIC_URL}/images/mark-content-exclusive-tutorial.gif`} width='100%' />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <FormInputRow
        title={
          <Box display='flex' flexDirection='row' alignItems='center' component='span'>
            <Typography component='span'>Stubbed Content</Typography>
            <Controller
              name={STUBBED_CONTENT_ENABLED_FORM_FIELD}
              control={form.control}
              render={(props) => (
                <Switch
                  onChange={(e) => props.field.onChange(e.target.checked)}
                  color='primary'
                  checked={props.field.value}
                />
              )}
            />
          </Box>
        }
        description='Stubbing content limits non-subscribed users access to just the beginning of a piece of exclusive content'
        smallFormTitles
        InputComponent={
          <>
            <Typography variant='caption' paragraph color='textSecondary'>
              Stubbed Content is <b>{status}</b>. {statusMessage}
            </Typography>
            <Typography variant='caption' paragraph color='textSecondary'>
              Click <Link component='button' onClick={handleOpen}>here</Link> to learn how to create exclusive content
            </Typography>
          </>
        }
      />
    </Grid>
  )
}

export default StubbedContent
