import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing }) => ({
  root: {
    border: '1px solid',
    borderColor: ({ checked }) => checked ? '#1565D8' : '#DDDBE0',
    height: 108,
    width: '100%',
    display: 'flex',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: 6,
    marginBottom: spacing(3.5),
    marginLeft: spacing(1.75),
    padding: spacing(3.5, 3.75),
    '& .MuiFormControlLabel-root, & .MuiFormControlLabel-label': {
      flexGrow: 1
    },
    '&:hover': {
      boxShadow: '0px 2px 14px 1px rgba(0, 0, 0, 0.1)'
    }
  }
}))
