import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    backgroundColor: ({ type }) => type === 'group' ? palette.grey.light : palette.grey.lighter,
    borderBottom: `1px solid ${palette.grey.main}`,
    padding: spacing(1.5),
    maxHeight: spacing(7),
    width: '100%',
    '&:hover': {
      opacity: 0.8
    }
  },
  name: {
    minHeight: 38,
    cursor: ({ hasChildren }) => hasChildren ? 'pointer' : 'cursor',
    paddingLeft: ({ hasChildren, type }) => (!hasChildren && type === 'category') ? spacing(9) : 0
  },
  collapseIcon: {
    marginRight: ({ type }) => type === 'group' ? spacing(2.5) : spacing(6)
  },
  collapse: { width: '100%' },
  dotIcon: {
    fontSize: spacing(1.5),
    marginRight: spacing(1),
    marginLeft: ({ type }) => type === 'subCategory' && spacing(13)
  },
  addCategoryCtn: {
    backgroundColor: palette.grey.lighter,
    borderBottom: `1px solid ${palette.grey.main}`,
    padding: spacing(1.5),
    maxHeight: spacing(7),
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  addIcon: {
    marginRight: spacing(1),
    marginLeft: ({ childrenType }) => childrenType === 'category' ? spacing(8) : spacing(12)
  }
}))
