import {
  useEvent,
  useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'

import React from 'react'
import { useSelector } from 'react-redux'
import { UserListSlice } from '../../../reducers'
import useConsumerTenant from '../../consumer-list/useConsumerTenant'
import { Select } from '../components'

const AVAILABLE_ROLES = [
  'manager',
  'standard',
  'premium',
  'reader',
  'enterprise',
  'admin'
]

const filterRoles = rolesList =>
  rolesList
    .filter(x => AVAILABLE_ROLES.includes(x.GroupName))
    .map(x => x.GroupName)

const RoleInput = ({ control }) => {
  const userPoolId = useConsumerTenant()
  const { username } = useSelector(UserListSlice.selectors.selectUser)
  const [options, setOptions] = React.useState(AVAILABLE_ROLES)

  const [getUserRoles] = useEvent([
    {
      eventName: 'adminGetUserRoles',
      onSuccess: payload => {
        setOptions(filterRoles(payload.roles.Groups))
      }
    }
  ])

  useEventsOnViewLoad(() => {
    getUserRoles.trigger({ userPoolId, username })
  }, [getUserRoles, userPoolId, username])

  return (
    <Select
      name='role'
      control={control}
      loading={getUserRoles.isWorking}
      options={options}
    />
  )
}

export default RoleInput
