import { BannerStatic } from '../../components'
import * as actions from './slice'

const getUser = {
  eventName: 'adminGetUserCognito',
  onSuccess: [{ action: actions.setUser, redux: true }]
}

const adminUpdateUserProfile = {
  eventName: 'adminUpdateProfile',
  onSuccess: { action: actions.setUserProfile, redux: true },
  onError: (e) => {
    console.error('adminUpdateProfile', e)
    BannerStatic.show({ label: 'Error trying to save user profile', isError: true })
  }
}

const adminUpdateUserRole = ({ onSuccess }) => ({
  eventName: 'adminUpdateRoleUser',
  onSuccess,
  onError: (e) => {
    console.error('error updating user role', e)
    BannerStatic.show({ label: 'Error while attempting to change the user role', isError: true })
  }
})

export {
  getUser,
  adminUpdateUserProfile,
  adminUpdateUserRole
}
