import { FormControl, InputLabel, MenuItem, Select, useTheme } from '@material-ui/core'
import { HourglassEmptyRounded, TextsmsRounded, LinkRounded, CheckBox } from '@material-ui/icons'
import React from 'react'
import { useStyles } from './style'

const ReviewFilter = ({ value, onChange }) => {
  const classes = useStyles()
  const { spacing } = useTheme()

  const getMenuItems = React.useCallback(() => ([
    { icon: () => <HourglassEmptyRounded />, label: 'Awaiting Review', value: 'awaiting_review' },
    { icon: () => <TextsmsRounded />, label: 'In Progress', value: 'in_progress' },
    { icon: () => <LinkRounded />, label: 'Check Internal Links', value: 'check_internal_links' },
    { icon: () => <CheckBox />, label: 'Complete', value: 'complete' }
  ]), [])

  return (
    <FormControl variant='outlined' className={classes.filterTypeControl}>
      <InputLabel
        id='catalog-filter-type'
        aria-label={value !== '' ? `Review Status, ${value} selected` : 'Review Status'}
        classes={{ outlined: classes.filterTypelabelOutlined }}
      >
          Review Status
      </InputLabel>
      <Select
        id='catalog-filter-migration-select'
        labelId='catalog-filter-migration'
        value={value}
        label='Review Status'
        onChange={ev => onChange(ev.target.value)}
        classes={{ root: classes.filterTypeSelect }}
      >
        <MenuItem value='' aria-label='None'>&nbsp;</MenuItem>
        {getMenuItems().map((item, key) => (
          <MenuItem key={key} value={item.value}>
            <item.icon />
            <span style={{ marginLeft: spacing(1.5) }}>{item.label}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ReviewFilter
