import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const content = createSlice({
  name,
  initialState: {
    list: [],
    templates: [],
    total: 0
  },
  reducers: {
    getTenantList: (state, { payload }) => {
      state.list = payload.tenants
      state.total = payload.total
    },
    addTenantList: (state, { payload }) => {
      state.list = state.list.concat(payload.tenants)
      state.total = payload.total
    },
    updateTenantIsActiveInList: (state, { payload: { pk, isActive } }) => {
      state.list.find(t => t.pk === pk).isActive = isActive
    },
    resetTenantList: (state) => {
      state.list = []
      state.total = 0
    }
  }
})

export const { getTenantList, addTenantList, updateTenantIsActiveInList, resetTenantList } = content.actions

export default content.reducer
