import {
  Grid,
  Box,
  Typography,
  Switch,
  Menu,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
  Radio,
  List,
  Divider,
  useTheme
} from '@material-ui/core'
import React, { useState } from 'react'
import { useStyles } from './style'
import { KeyboardArrowRight } from '@material-ui/icons'

const MenuSwitchInput = ({
  itemList,
  onClick,
  disabled,
  defaultValue,
  isChecked
}) => {
  const classes = useStyles()
  const { spacing } = useTheme()

  const [checked, setChecked] = useState(isChecked)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(defaultValue)

  const handleSwitch = e => {
    setChecked(!checked)
    !checked ? onClick(itemList[selectedIndex]) : onClick('')
  }
  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClick = (event, index) => {
    setSelectedIndex(index)
  }

  const handleClose = () => {
    setAnchorEl(null)
    onClick(itemList[selectedIndex])
  }

  const renderItemList = () =>
    itemList.map((item, i) => {
      return (
        <MenuItem
          key={`${item}-${i}`}
          onClick={e => handleClick(e, i)}
          selected={i === selectedIndex}
        >
          <ListItemIcon>
            <Radio checked={selectedIndex === i} color='primary' />
          </ListItemIcon>
          <ListItemText>{item}</ListItemText>
        </MenuItem>
      )
    })

  return (
    <Grid container className={classes.weeklyEmail}>
      <Grid item xs={12}>
        <Box
          border={1}
          borderRadius={spacing(1)}
          borderColor='#E0E0E0'
          display='flex'
          style={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: spacing(1, 4)
          }}
        >
          <Typography variant='body2' style={{ marginTop: spacing(3.5) }}>
            Weekly Email
          </Typography>
          <Box
            display='flex'
            style={{
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              padding: spacing(1)
            }}
          >
            <Box visibility={checked ? 'visible' : 'hidden'}>
              <List component='nav' aria-label='Device settings'>
                <ListItem
                  button
                  aria-haspopup='true'
                  aria-controls='lock-menu'
                  aria-label='when device is locked'
                  onClick={handleClickListItem}
                >
                  <ListItemText disableTypography>
                    <Typography variant='body2'>Send day</Typography>
                    <Box display='flex' justifyContent='flex-end'>
                      <Typography variant='body2'>
                        {itemList[selectedIndex]}&nbsp;
                      </Typography>
                      <Box>
                        <KeyboardArrowRight />
                      </Box>
                    </Box>
                  </ListItemText>
                </ListItem>
              </List>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant='menu'
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                classes={{ paper: classes.popmenu }}
                disabled={disabled}
              >
                {renderItemList()}
              </Menu>
            </Box>
            {checked && (
              <Divider
                orientation='vertical'
                flexItem
                style={{
                  margin: spacing(3, 0),
                  borderColor: 'rgba(0, 0, 0, 0.38)'
                }}
              />
            )}
            <Box style={{ padding: spacing(2, 0.5) }}>
              <Switch
                color='primary'
                checked={checked}
                onChange={handleSwitch}
                disabled={disabled}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default MenuSwitchInput
