import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const category = createSlice({
  name,
  initialState: {
    groupCategories: []
  },
  reducers: {
    getGroupCategoriesByTemplate: (state, { payload }) => {
      state.groupCategories = payload.categories
    }
  }
})

export const {
  getGroupCategoriesByTemplate
} = category.actions

export default category.reducer
