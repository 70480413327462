import { useSession } from '@emerald-works/react-auth'
import { AppBar, Badge, Grid, IconButton, Link, Toolbar, Typography } from '@material-ui/core'
import { AccountCircle, Notifications } from '@material-ui/icons'
import { kebabCase } from 'lodash'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useGroup from '../../hooks/useGroup'
import { routes } from '../../routes'
import {
  ALLOW_ARK_USERS_TAB,
  ALLOW_CLIENT_TOOLKITS_TAB,
  ALLOW_CONSUMER_USERS_TAB,
  ALLOW_CONTENT_TAB,
  ALLOW_DASHBOARD_TAB,
  ALLOW_REPORTING,
  ALLOW_TEMPLATES_TAB
} from '../../util/permissions'
import { useStyles } from './style'

const AppToolbar = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useSession()
  const { isInGroup } = useGroup()

  const menu = [
    { name: 'Dashboard', route: routes.home.path, visible: isInGroup(ALLOW_DASHBOARD_TAB) },
    { name: 'Content', route: routes.contentList.path, visible: isInGroup(ALLOW_CONTENT_TAB) },
    { name: 'Client Toolkits', route: routes.tenantList.path, visible: isInGroup(ALLOW_CLIENT_TOOLKITS_TAB) },
    { name: 'Templates', route: routes.templateList.path, visible: isInGroup(ALLOW_TEMPLATES_TAB) },
    { name: 'Ark Users', route: routes.arkUsers.path, visible: isInGroup(ALLOW_ARK_USERS_TAB) },
    { name: 'Consumers', route: routes.consumerList.path, visible: isInGroup(ALLOW_CONSUMER_USERS_TAB) },
    { name: 'Reporting', route: routes.reporting.path, visible: isInGroup(ALLOW_REPORTING) }
  ]

  const avatarTitle = React.useMemo(() => {
    return user?.attributes ? `${user.attributes.family_name}, ${user.attributes.given_name}` : 'User profile'
  }, [user])

  return (
    <AppBar id='app-bar' classes={{ root: classes.appBar }}>
      <Toolbar id='app-toolbar' classes={{ root: classes.toolbar, gutters: classes.toolbarGutters }}>
        <Grid container alignItems='stretch'>
          <Grid item tabIndex={0} className={classes.toolbarItem} classes={{ root: classes.toolbarTitle }}>
            <Typography variant='h6'> The Ark </Typography>
          </Grid>
          <Grid id='app-toolbar-menu' item className={classes.toolbarItem} style={{ flexGrow: 1 }}>
            {menu.map((link, key) => (
              link?.visible && (
                <Link
                  data-test={`app-toolbar-menu-item-${kebabCase(link.name)}`}
                  key={key}
                  tabIndex={0}
                  underline='none'
                  label={link.name}
                  component='button'
                  variant='subtitle2'
                  navigate={link.route}
                  classes={{ root: classes.link }}
                  onClick={() => navigate(link.route)}
                  className={`${location.pathname === link.route ? classes.activeLink : ''}`}
                >
                  {link.name}
                </Link>)
            ))}
          </Grid>
          <Grid item className={classes.toolbarItem}>
            <IconButton
              id='app-toolbar-notification'
              tabIndex={0}
              aria-label='notifications'
              classes={{ label: classes.toolbarIcons }}
            >
              <Badge id='app-toolbar-notification-badge' badgeContent={0} color='secondary'>
                <Notifications />
              </Badge>
            </IconButton>
            <IconButton
              id='app-toolbar-profile'
              edge='end'
              tabIndex={0}
              title={avatarTitle}
              aria-label={avatarTitle}
              // aria-controls={menuId}
              aria-haspopup='true'
              // onClick={handleProfileMenuOpen}
              classes={{ label: classes.toolbarIcons }}
            >
              <AccountCircle fontSize='large' />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default AppToolbar
