import { useTenant } from '@emerald-works-nova/auth'
import { ActionButton } from '@emerald-works-nova/components'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import React from 'react'
import ArkUserItem from '../ark-user-item'
import { useStyles } from './style'

const ArkUserGenericList = ({
  onClick = () => { },
  userList,
  isLoading,
  isFetching,
  onFetchMoreUsers,
  lastKey
}) => {
  const classes = useStyles({})
  const { userPoolId } = useTenant()

  const handleKeyPress = (e, user) => e.which === 13 && onClick(user)

  return (
    <>
      <Grid
        id='user-list'
        classes={{ root: classes.tablePaper }}
        tabIndex={0}
        aria-label='Users List'
        elevation={0}
      >
        <Grid
          id='user-list-headers'
          container
          tabIndex={0}
          direction='row'
          alignItems='center'
          className={classes.gridHeader}
          classes={{ root: classes.tableHead }}
          aria-label='List headers, Title, Type, Last update'
        >
          <Grid item md style={{ paddingLeft: 16 }}>Full Name</Grid>
          <Grid item md={2}>Last Access</Grid>
          <Grid item md={2}>Role</Grid>
          <Grid item md={2}>Status</Grid>
        </Grid>
        {!isLoading && userList.length > 0 && userList.map((row, key) => (
          <ArkUserItem
            key={key}
            index={key}
            user={row}
            classes={classes}
            userPoolId={userPoolId}
            lastOne={key === userList.length - 1}
            onClick={onClick}
            onKeyPress={handleKeyPress}
          />
        ))}
        {isLoading && userPoolId && (
          <Grid
            id='user-list-loading'
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            classes={{ root: classes.loadingAnimation }}
          >
            <CircularProgress size={60} color='primary' />
          </Grid>
        )}
        {!isLoading && !userList.length && (
          <Grid
            id='user-list-empty'
            container
            direction='row'
            justifyContent='center'
            alignContent='center'
            classes={{ root: classes.emptyList }}
          >
            <Typography variant='h5'> No users found! </Typography>
          </Grid>
        )}
      </Grid>

      {!isLoading && lastKey && (
        <Grid
          id='users-list-load-more'
          container
          direction='row'
          justifyContent='center'
          align='center'
          classes={{ root: classes.loadMoreBtn }}
          disabled={isFetching}
        >
          <Grid item xs={12}>
            <ActionButton
              variant='primary'
              dataTest='load-more-button'
              onClick={onFetchMoreUsers}
              isWorking={isFetching}
            >
              Load More
            </ActionButton>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default React.memo(ArkUserGenericList)
