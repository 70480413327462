import { Box, Grid, Typography } from '@material-ui/core'
import { kebabCase } from 'lodash'
import React from 'react'

const Info = ({ title, children, xs = 4 }) => {
  return (
    <Grid item xs={xs} data-test={kebabCase(title)}>
      <Typography variant='body2'>{title}</Typography>
      <Box sx={{ mt: 2 }}>
        <Typography
          variant='caption'
          color='textSecondary'
          style={{ fontSize: 13 }}
        >
          {children}
        </Typography>
      </Box>
    </Grid>
  )
}

export default Info
