import React from 'react'
import { Banner } from '../'
import Toast from '../toast'

export default class StaticBanner extends React.Component {
  static hide () {
    Banner.Instance.setState({
      open: false
    })
  }

  static show ({
    label,
    dismissLabel = 'Dismiss',
    icon,
    isError,
    isWarning,
    isToast = true,
    ...options
  }) {
    if (Banner.Instance) {
      Banner.Instance.setState({
        open: true,
        options: {
          label,
          dismissLabel,
          icon,
          isError,
          isWarning,
          isToast,
          ...options
        }
      })
    } else {
      console.warn('No banner found')
      console.warn(label)
    }
  }

  constructor (props) {
    super(props)

    this.state = {
      open: false,
      options: {
        label: '',
        dismissLabel: 'Dismiss',
        icon: null
      }
    }

    this.handleClose = () => {
      this.setState({ open: false })
    }

    Banner.Instance = this
  }

  render () {
    const { open, options } = this.state

    if (options.isToast) return <Toast open={open} onClose={this.handleClose} {...options} />

    return <Banner open={open} onClose={this.handleClose} {...options} />
  }
}
