import { Collapse, Divider, Grid, IconButton, Switch, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'
import RemoveIcon from '@material-ui/icons/Remove'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import SettingsIcon from '@material-ui/icons/Settings'
import React from 'react'
import { useStyles } from './style'

const CategoryItem = ({
  category,
  type,
  viewOnly,
  openAll,
  onAddCategory,
  template,
  onManageCategory,
  onSwitchVisibility
}) => {
  const hasChildren = React.useMemo(() => !!category?.children?.length, [category.children])
  const childrenType = React.useMemo(() => type === 'group' ? 'category' : 'subCategory', [type])
  const classes = useStyles({ hasChildren, type, childrenType })
  const [isChecked, setIsChecked] = React.useState(category.isVisible)
  const [collapsed, setCollapsed] = React.useState(false)

  const handleClick = React.useCallback(() => {
    if (!hasChildren) return
    setCollapsed(collapsed => !collapsed)
  }, [setCollapsed, hasChildren])

  React.useEffect(() => { setCollapsed(openAll) }, [openAll])

  const CollapseIcon = () => collapsed
    ? <RemoveIcon className={classes.collapseIcon} />
    : <AddIcon className={classes.collapseIcon} />

  const newItemTxt = React.useMemo(() => childrenType === 'category' ? 'New Category' : 'New Sub Category', [childrenType])

  const handleOpenManageCategory = React.useCallback(() => onManageCategory(category), [onManageCategory, category])

  return (
    <>
      <Grid container className={classes.root} direction='column' alignContent='center'>
        <Grid container className={classes.name} item xs={10} direction='row' alignItems='center' onClick={handleClick}>
          {hasChildren && <CollapseIcon />}
          {type === 'category' && <FiberManualRecordIcon className={classes.dotIcon} />}
          {type === 'subCategory' && <FiberManualRecordOutlinedIcon className={classes.dotIcon} />}
          <Typography variant='body2'>{category.name}</Typography>
          <Typography variant='body2'>&nbsp;{category.children ? `(${category.children.length})` : ''}</Typography>
        </Grid>
        <Grid container item xs={2} justifyContent='flex-end' alignItems='center'>
          {!viewOnly && !hasChildren && onManageCategory && (
            <>
              {/* <MoreVertIcon /> */}
              <IconButton aria-label='manage category' size='small' onClick={handleOpenManageCategory}>
                <SettingsIcon />
              </IconButton>
              <Divider orientation='vertical' />
            </>
          )}
          <Switch
            color='primary'
            checked={isChecked}
            onChange={(_, v) => setIsChecked(v)} disabled={viewOnly || !onSwitchVisibility}
          />
        </Grid>
      </Grid>
      {hasChildren && (
        <Collapse in={collapsed} className={classes.collapse}>
          {category.children.map(child => (
            <CategoryItem
              key={child.sk}
              category={child}
              type={childrenType}
              viewOnly={viewOnly}
              openAll={openAll}
              template={template}
              onManageCategory={onManageCategory}
            />
          ))}
          {(onAddCategory && !viewOnly) && (
            <Grid
              item
              container
              className={classes.addCategoryCtn}
              direction='row'
              alignItems='center'
              onClick={onAddCategory}
            >
              <AddCircleOutlineIcon className={classes.addIcon} />
              <Typography variant='body2'>{newItemTxt}</Typography>
            </Grid>
          )}
        </Collapse>
      )}
    </>
  )
}

export default CategoryItem
