import { useTenant } from '@emerald-works-nova/auth'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Box, Typography, Badge, CircularProgress } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { AllRoles } from '../../../../helpers/roles/department-data'
import { UserListSlice } from '../../../../reducers'
import { useStyles } from './style'

const DepartmentMenu = ({ onChange = () => { }, value }) => {
  const classes = useStyles()
  const { pk: tenantId } = useTenant()
  const rolesList = [{ label: 'All Users', value: 'all' }, ...AllRoles]

  const total = useSelector(UserListSlice.selectors.selectTotalPending)

  const [countUsersPendingRole] = useEvent([UserListSlice.eventBus.countUsersPendingRole])

  useEventsOnViewLoad(() => {
    if (total === undefined) return
    countUsersPendingRole.trigger({ tenantId })
  }, [total, countUsersPendingRole, tenantId])

  return (
    <Box minWidth='250px'>
      <Box
        height={60}
        display='flex'
        flexDirection='row'
        alignItems='flex-end'
        borderBottom='1px solid #9E9E9E'
      >
        <Typography variant='h6'>List Users By</Typography>
      </Box>
      <Box className={classes.departmentList} my={3} borderBottom='1px dashed #9E9E9E'>
        {rolesList.map((role, idx) => (
          <Typography
            key={idx}
            variant='subtitle2'
            onClick={() => onChange(role.value)}
            className={role.value === value ? classes.checked : ''}
          >
            {role.label}
          </Typography>
        ))}
      </Box>
      <Box className={classes.departmentList}>
        <Typography
          variant='subtitle2'
          onClick={() => onChange('pending')}
          className={value === 'pending' ? classes.checked : ''}
        >
          Pending  Roles - New Users &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {(countUsersPendingRole.isWorking || !countUsersPendingRole.hasBeenTriggered) ? <CircularProgress size={20} /> : <Badge badgeContent={total} color='error' />}
        </Typography>
      </Box>
    </Box>
  )
}

export default React.memo(DepartmentMenu)
