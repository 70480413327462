import { createSlice } from '@reduxjs/toolkit'
import name from './name'

// Store
export const template = createSlice({
  name,
  initialState: {
    templates: []
  },
  reducers: {
    setTemplateList: (state, { payload }) => {
      state.templates = payload.templates
    }
  }
})

export const {
  setTemplateList
} = template.actions

export default template.reducer
