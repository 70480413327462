import React from 'react'
import ToggleUserBase from '../../../components/toggle-user'
import { useDispatch } from 'react-redux'
import { UserListSlice } from '../../../reducers'

const ToggleUser = props => {
  const dispatch = useDispatch()
  const handleToggle = value => {
    dispatch(
      UserListSlice.actions.updateUserEnabledInList({
        username: props.user.username,
        enabled: value
      })
    )
  }
  return <ToggleUserBase {...props} onToggle={handleToggle} />
}

export default ToggleUser
