import React from 'react'
import { Grid, Divider, Box } from '@material-ui/core'
import { Info } from '../components'
import { ActionButton } from '@emerald-works-nova/components'
import { useStyles } from '../style'
import Status from '../components/status'
import { format } from 'date-fns'

const subscription = {
  state: 'canceled',
  code: 'monthly',
  name: 'Monthly',
  activatedAt: '2022-06-28T12:38:33Z',
  canceledAt: undefined,
  pausedAt: undefined,
  resumeAt: undefined,
  currentPeriodStartedAt: '2022-06-28T12:38:33Z',
  currentPeriodEndsAt: '2023-06-28T12:38:33Z'
}

const SubscriptionTab = () => {
  const classes = useStyles()

  const { state, name, currentPeriodEndsAt } = subscription

  return (
    <Grid container direction='column' spacing={3} className={classes.tabPanel}>
      <Grid container item>
        <Info title='Subscription Name'>{name}</Info>
        <Info title='Suspension Date'>{format(new Date(currentPeriodEndsAt), 'MMM d, y')}</Info>
        <Info title='Subscription Status'>
          <Status status={state} />
        </Info>
      </Grid>
      <Grid item>
        <ActionButton variant='secondary'>
          Edit subscription
        </ActionButton>
      </Grid>
      <Divider style={{ marginTop: 'auto', marginBottom: 8 }} />
      <Grid item xs={12}>
        <ActionButton variant='secondary'>
          <Box component='span' color='error.dark'>
            Suspend subscription
          </Box>
        </ActionButton>
      </Grid>
    </Grid>
  )
}

export default SubscriptionTab
