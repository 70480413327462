export const parseDate = dateKey => {
  const [type, key] = dateKey.substring(1, dateKey.length).split('#')
  const [year, sub] = key.split('-')

  switch (type) {
    case 'M': return new Date(year, parseInt(sub) - 1)
    case 'W': return new Date(year, 0, 7 * parseInt(sub))
    case 'Y': return new Date(year)
    default: return ''
  }
}
