import DateFnsUtils from '@date-io/date-fns'
import { MenuItem, MenuList } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import { useStyles } from './style'

export default ({ value, onChange }) => {
  const classes = useStyles()

  // This is a work in progress because Material-UI doens't have a good Date Range Picker available ATM
  const Toolbar = () => (
    <MenuList variant='selectedMenu'>
      <MenuItem>Custom</MenuItem>
      <MenuItem>Today</MenuItem>
      <MenuItem>Yesterday</MenuItem>
      <MenuItem>Last 7 days</MenuItem>
      <MenuItem>Last 15 days</MenuItem>
      <MenuItem>Last 30 days</MenuItem>
      <MenuItem>Last 90 days</MenuItem>
    </MenuList>
  )

  const handleChange = React.useCallback(date => {
    if (!date || isNaN(date.valueOf())) return
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    onChange(date.toISOString())
  }, [onChange])

  // format the label string using the browsers default format to match the list date column
  const handleLabel = React.useCallback(date => date ? date.toLocaleDateString() : '', [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id='last-updated-picker'
        autoOk
        disableFuture
        disableToolbar
        variant='inline'
        orientation='landscape'
        inputVariant='outlined'
        format='dd/MM/yyyy'
        margin='none'
        label='Updated since'
        labelFunc={handleLabel}
        value={value}
        onChange={handleChange}
        ToolbarComponent={Toolbar}
        classes={{ root: classes.dateFilterPicker }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
          classes: { root: classes.keyboardAdornment }
        }}
        InputProps={{
          classes: { adornedEnd: classes.dateFilterAdornedEnd }
        }}
        InputLabelProps={{
          classes: { root: classes.dateFilterLabel }
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
