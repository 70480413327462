import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '40px auto 10px auto',
    textAlign: 'center',
    color: '#D1D1D1',
    fontSize: 12,
    paddingTop: 16,
    borderTop: '1px solid #D1D1D1'
  }
}))
